import React, { useEffect, useState } from "react";
import { Ibox } from "../components/common/ibox";
import { makeid } from "../helpers/helper_methods";
import { Row, Col } from "react-bootstrap";
import api from "../service/api";
import { ErrorLog, DebugLog, Log } from "../helpers/logs";

import UserTable from "../components/admin_components/user_table";
import $ from "jquery";
// Add Modals
import AddUserModal from "../components/admin_components/add_user_modal";
import UpdateUserModal from "../components/admin_components/update_user_modal";

export default function Admin(props) {
  const [users, setUsers] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [organizations, setOrganizations] = useState([]);
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);

  function showUpdateUserWrapper(user) {
    if (user == undefined) {
      setShowUpdateModal(false);
      setSelectedUser({});
    } else {
      setShowUpdateModal(true);
      setSelectedUser(user);
    }
  }

  useEffect(() => {
    Log("Selected User Changed: ", selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (!showUserModal) {
      getUsers();
    } else {
      window.history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    }
  }, [showUserModal]);

  function getUsers() {
    $(`#loader`).show();

    api.Users.getAll()
      .then((response) => {
        Log("Get Users Response", response);
        $(`#loader`).hide();

        setUsers(response.users);
      })
      .catch((er) => {
        ErrorLog("Er: ", er);
        $(`#loader`).hide();
      });
  }

  return (
    <Row>
      {showUserModal ? (
        <AddUserModal
          showUserModal={showUserModal}
          setShowUserModal={setShowUserModal}
          getUsers={getUsers}
        />
      ) : null}
      {showUpdateModal ? (
        <UpdateUserModal
          showUserModal={showUpdateModal}
          setShowUserModal={setShowUpdateModal}
          getUsers={getUsers}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      ) : null}
      <Col lg={12}>
        <Ibox
          id={`Admin IBox` + makeid(10)}
          enableTitleBar
          title={`Users`}
          titleButton={{
            color: "primary",
            title: `Add User`,
            func: () => setShowUserModal(() => !showUserModal),
          }}
        >
          <UserTable
            users={users}
            setUsers={setUsers}
            setShowUpdateModal={showUpdateUserWrapper}
            getUsers={getUsers}
          />
        </Ibox>
      </Col>
    </Row>
  );
}
