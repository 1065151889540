import ReactDOM from "react-dom";
import React from "react";
import * as serviceWorker from "./serviceWorker";
import Auth0App from "./App";
// Auth0 Provider and config
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./config/auth0_config";

import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "./../node_modules/animate.css/animate.compat.css";
import "./../node_modules/ladda/dist/ladda-themeless.min.css";

// import "./vendors/slimScroll/slimScroll.js";
import "./vendors/inspinia/theme.js";

import "./styles/style.scss";

// import './../node_modules/semantic-ui-css/semantic.min.css';
import "./polyfills";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

var useAuth0 = process.env.REACT_APP_USE_AUTH0;

// if(process.env.NODE_ENV == "production" ){
//   window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function () {}
// }
//ReactDOM.render(Routes, document.getElementById("root"));
// if (useAuth0 == "true") {
ReactDOM.render(
  <Auth0Provider {...config.providerConfig}>
    <Auth0App />
  </Auth0Provider>,
  document.getElementById("root")
);
// } else {
//   ReactDOM.render(<App />, document.getElementById("root"));
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
