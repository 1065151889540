import React, { useEffect, useState } from "react";
import { correctHeight, detectBody } from "./helpers";
import $ from "jquery";
import "animate.css";

import { UserProvider } from "../../context/user_context";

import { Wrapper } from "../layouts/wrapper";

import { Breadcrumb } from "../common/breadcrumb";
import { Navigation } from "../common/navigation";
import { Footer } from "../common/footer";
import { Progress } from "../common/progress";
import { TopHeader } from "../common/top_header";
import { Ibox } from "../common/ibox";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularPage } from "../common/circular_spinner";

//
import { history } from "../../config";
import { useOktaAuth } from "@okta/okta-react";
import { propTypes } from "react-bootstrap/esm/Image";

export function Main({
  children,
  currentUser,
  history,
  timeLeft,
  enableBreadcrumb,
  loading,
  InitializeUser,
}) {
  // const { authState, oktaAuth } = useOktaAuth();
  const [showMiniCart, setshowMiniCart] = useState(false);

  useEffect(() => {
    componentDidMount();
  });

  const componentDidMount = () => {
    // Run correctHeight function on load and resize window event
    $(window).bind("load resize", function () {
      correctHeight();
      detectBody();
    });

    // Correct height of wrapper after metisMenu animation.
    $(".metismenu a").click(() => {
      setTimeout(() => {
        correctHeight();
      }, 300);
    });
  };

  const checkIfUserHasPermssion = (roles, currentUserRoles) => {
    var hasPermission = false;
    roles.map((role) => {
      if (currentUserRoles.includes(role)) {
        hasPermission = true;
      }
    });
    return hasPermission;
  };

  const getCurrentPath = () => {
    return history.location.pathname;
  };

  const wrapperClass = "gray-bg " + history.location.pathname;
  const pageTitle = history.location.pathname.replace("/", "").capitalize();
  document.title = `${pageTitle} - ` + process.env.REACT_APP_APP_NAME;
  const { isLoading, error } = useAuth0();
  // Spinner
  if (process.env.REACT_APP_USE_AUTH0 == "true") {
    if (isLoading || loading) {
      return <CircularPage />;
    }
    if (error) {
      return <div>Oops... {error.message}</div>;
    }
  }

  function toggleMiniCart(val) {
    setshowMiniCart(val);
  }

  function onClickTasks() {
    setshowMiniCart(false);
  }

  return (
    <div id="wrapper">
      <Progress />
      <Navigation
        location={history.location}
        currentUrl={history.location.pathname}
      />

      <div id="page-wrapper" className={wrapperClass}>
        <TopHeader
          showMiniCart={showMiniCart}
          toggleMiniCartFunc={toggleMiniCart}
          InitializeUser={InitializeUser}
          currentUser={currentUser}
        />
        {enableBreadcrumb && (
          <Breadcrumb pageTitle={pageTitle} location={history.location} />
        )}
        <Wrapper>{children}</Wrapper>

        <Footer timeLeft={timeLeft} />
      </div>
    </div>
  );
}
