import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { ErrorLog, Log } from "../helpers/logs";
import Swal from "sweetalert2";
import { Tooltip } from "@mui/material";
import { InputGroup, Form, Button } from "react-bootstrap";
import Chip from "@mui/material/Chip";
import api from "../service/api";
import { useState } from "react";
import { makeid, handleMakeCartObject } from "../helpers/helper_methods";

import ICDRefTable from "../components/cart_components/icd_ref_table";

import { IsAuthorize } from "../helpers/helper_methods";

import UserContext from "../context/user_context";

import $ from "jquery";

var moment = require("moment");

export default function CartView(props) {
  const navigate = useNavigate();

  const [cart, setCart] = useState([]);

  useEffect(() => {
    getCart();
  }, []);

  function getCart() {
    api.Cart.getCart()
      .then((response) => {
        setCart(response);
      })
      .catch((er) => {
        ErrorLog("Er: ", er);
      });
  }

  function setCartWrapper(e, nav) {
    api.Cart.setCart(handleMakeCartObject(e))
      .then((response) => {
        Log("Cart response: ", response);

        setCart(response.user_cart);

        if (nav != undefined && nav) {
          navigate(-1, { replace: true });
        }
      })
      .catch((er) => {
        ErrorLog("Er: ", er);
      });
  }

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <div className="ibox">
            <div className="ibox-title">
              <span className="pull-right">
                (<strong>{cart.length}</strong>) items
              </span>
              <h5>Tests in your order</h5>
            </div>
            <div className="ibox-content">
              <div className="table-responsive">
                <table className="table shoping-cart-table table-striped ">
                  <tbody>
                    {cart.length > 0 ? (
                      cart.map((item, index) => {
                        return (
                          <CartItemTr
                            key={`CartItemTr-${index}`}
                            item={item}
                            cart={cart}
                            setCart={setCartWrapper}
                          />
                        );
                      })
                    ) : (
                      <span>No tests to order</span>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="ibox-content">
              <OrderButton cart={cart} />
              <button
                className="btn btn-white"
                onClick={() => navigate(-1, { replace: true })}
              >
                <i className="fa fa-arrow-left"></i> Back
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <CartSummary cart={cart} totalItems={cart.length} />
          <ICDRefTable />
          <CartSupport />
        </div>
      </div>
    </>
  );
}

export function CartPill(props) {
  const { name, value, col, badgeStatus } = props;
  return (
    <Col sm={col || 2}>
      <span className={`mt-2 badge badge-pill badge-${badgeStatus || "light"}`}>
        {name}
        {value != undefined ? `:` : ``}
      </span>
      <span className="cart-item-span">{value}</span>
    </Col>
  );
}

export function CartItemTr(props) {
  const { cart, setCart } = props;
  const { patient, orderable_test } = props.item;
  const age = moment(patient.dob).fromNow().split(" ")[0];

  const [icdCodes, setICDCodes] = useState([]);

  function removeItem() {
    var { item } = props;

    var tempCart = Object.assign([], cart);

    tempCart.splice(cart.indexOf(item), 1);

    setCart(tempCart);
  }

  return (
    <tr>
      <td className="desc">
        <span className="small">
          <Row style={{ marginBottom: "5px", marginTop: "5px" }}>
            <Col md={2}>
              <h4>
                <span className="text-navy">
                  {patient.last_name}, {patient.first_name}{" "}
                  {patient.middle_name}
                </span>
              </h4>
            </Col>
            <CartPill
              col={3}
              name="DOB"
              badgeStatus="primary"
              value={patient.dob}
            />
            {age != "Invalid" && (
              <CartPill col={2} name="Age" badgeStatus="primary" value={age} />
            )}
            <CartPill
              col={2}
              name="Sex"
              badgeStatus="primary"
              value={patient.sex.toString().charAt(0).toUpperCase()}
            />
            {patient.phone && (
              <CartPill
                col={3}
                name="Phone"
                badgeStatus="primary"
                value={patient.phone}
              />
            )}
          </Row>
        </span>
        <dl className="small m-b-none">
          <Row>
            <Col sm={2}>
              {" "}
              <dt>Test Characteristics:</dt>
            </Col>
            <CartPill
              col={5}
              name="Description"
              value={orderable_test.test.description}
            />
            <CartPill col={4} name="Code" value={orderable_test.test.code} />
          </Row>
        </dl>
        <ICDSection
          icdCodes={icdCodes}
          setICDCodes={setICDCodes}
          item={props.item}
          cart={cart}
          setCart={setCart}
        />
      </td>

      <td>
        <div className="m-t-sm">
          <a href="#" className="text-muted" onClick={removeItem}>
            <i className="fa fa-trash"></i> Remove
          </a>
        </div>
      </td>
    </tr>
  );
}

function ICDSection(props) {
  const { icdCodes, setICDCodes, item, cart, setCart } = props;
  const [codes, setCodes] = useState("");

  function handleLookup() {
    api.ICDLookup.checkCodes(codes)
      .then((response) => {
        var newCodes = [];
        var changed = false;

        response.results.map((ri) => {
          if (
            ri.response.success &&
            !icdCodes.filter((x) => x.code == ri.code).length > 0
          ) {
            newCodes.push({
              code: ri.code,
              description: ri.response.description,
            });
            changed = true;
          }
        });

        var tempCart = cart;
        var codes = [];
        if (changed) {
          tempCart.map((it) => {
            if (
              it.patient_id == item.patient.id &&
              it.orderable_test.test.code == item.orderable_test.test.code
            ) {
              if (it.icd_codes == null) {
                it.icd_codes = [];
              }

              it.icd_codes = it.icd_codes.concat(newCodes);
              codes = it.icd_codes.concat(newCodes);
            }
          });
        }

        setCart(tempCart);

        setICDCodes(codes);
        setCodes("");
      })
      .catch((er) => {
        Log("Er: ", er);
      });
  }

  function removeIcdCode(code) {
    var tempCart = Object.assign([], cart);
    var newCodes = [];

    tempCart.map((it) => {
      if (
        it.patient.id == item.patient.id &&
        item.orderable_test.id == it.orderable_test.id
      ) {
        if (it.icd_codes.length == 1) {
          it.icd_codes = [];
          newCodes = [];
        } else {
          it.icd_codes.splice(it.icd_codes.indexOf(code), 1);
          newCodes = it.icd_codes;
        }
      }
    });

    setCart(tempCart);
    setICDCodes(() => newCodes);
  }

  return (
    <dl className="small m-b-none" style={{ marginTop: `1em` }}>
      <Row>
        <Col sm={2}>
          {" "}
          <dt>ICD Codes</dt>
        </Col>
        <Col lg={9} />
        <Col lg={3}>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="ICD Lookup"
              aria-label="ICD Lookup"
              aria-describedby="Seach for ICD Codes"
              value={codes}
              onChange={(e) => setCodes(e.target.value)}
              onKeyDown={(e) => (e.key == "Enter" ? handleLookup() : null)}
            />
            <Button variant="primary" id="button-addon2" onClick={handleLookup}>
              Search
            </Button>
          </InputGroup>
        </Col>
        {item.icd_codes != null && item.icd_codes.length > 0
          ? item.icd_codes.map((code, index) => {
              if (code.description.length > 15) {
                return (
                  <Col lg={2} key={makeid(15)}>
                    <Tooltip title={code.description}>
                      <span>
                        <ChipWrapper
                          removeIcdCode={removeIcdCode}
                          code={code}
                        />
                      </span>
                    </Tooltip>
                  </Col>
                );
              } else {
                return (
                  <Col lg={2} key={makeid(15)}>
                    <ChipWrapper removeIcdCode={removeIcdCode} code={code} />
                  </Col>
                );
              }
            })
          : null}
      </Row>
    </dl>
  );
}

function ChipWrapper(props) {
  const { code, removeIcdCode } = props;

  return (
    <Chip
      color="primary"
      onDelete={() => removeIcdCode(code)}
      style={{
        padding: "5px",
        backgroundColor: `#11316e`,
        borderRadius: `5px`,
      }}
      label={
        <span>
          {code.code} <br />{" "}
          {code.description.length > 15 ? (
            <span>{`${code.description.substring(0, 15)}...`}</span>
          ) : (
            code.description
          )}
        </span>
      }
    />
  );
}

export function CartSupport(props) {
  return (
    <div className="ibox">
      <div className="ibox-title">
        <h5>Support</h5>
      </div>
      <div className="ibox-content text-center">
        <h3>
          <i className="fa fa-phone"></i> 877.27.SONIC (76642)
        </h3>
        <span className="small">
          Please contact with us if you have any questions
        </span>
      </div>
    </div>
  );
}

function OrderButton(props) {
  const { cart } = props;
  const navigate = useNavigate();

  const currentUser = useContext(UserContext);

  function buildOrder(user) {
    var order = [];

    return {
      authorized_user_id: user.id,
      order_tests: cart.map((item) => {
        if (item.id != undefined) {
          return {
            registry_id: item.registry.id,
            patient_id: item.patient.id,
            orderable_test_id: item.orderable_test.id,
            client_location_id: item.client_location_id,
            icd_codes: item.icd_codes.length > 0 ? item.icd_codes : null,
            user_cart_item_id: item.id,
          };
        } else {
          return {
            registry_id: item.registry.id,
            patient_id: item.patient.id,
            orderable_test_id: item.orderable_test.id,
            client_location_id: item.location.id,
            icd_codes: item.icd_codes.length > 0 ? item.icd_codes : null,
            user_cart_item_id: item.id,
          };
        }
      }),
    };
  }

  function orderTests() {
    api.IConnectApi.GetCurrentUser()
      .then((response) => {
        var user = response.user;

        api.IConnectApi.createOrder(buildOrder(user))
          .then((response) => {
            Swal.fire(
              "Tests Ordered",
              "Your tests have been ordered",
              "success"
            ).then((result) => {
              navigate(-1, { replace: true });
            });
          })
          .catch((er) => {
            Log("Er: ", er);
          });
      })
      .catch((er) => {
        Log("Er: ", er);
      });
  }

  var test = () => {
    var isValid = true;

    cart.map((ci) => {
      if (
        ci.icd_codes == undefined ||
        ci.icd_codes == null ||
        ci.icd_codes.length == 0
      ) {
        isValid = false;
      }
    });

    if (isValid) {
      Swal.fire({
        title: `There are ${cart.length} tests in your order`,
        text: "Confirm your order",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        input: IsAuthorize(currentUser) ? "checkbox" : "",
        inputPlaceholder:
          "I acknowledge and agree that each lab test ordered will be documented in the applicable patient medical record",
        inputAttributes: { cursor: "pointer" },
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value) {
            orderTests();
          } else {
            Swal.fire({
              title: `Failed to check acknowledgment`,
              icon: "warning",
              timer: "50000",
            });
          }
        }
      });
    } else {
      Swal.fire({
        title: `All tests must have at least one ICD Code`,
        icon: "warning",
      });
    }
  };

  return (
    <button className="btn btn-primary pull-right" onClick={test}>
      <i className="fa fa fa-shopping-cart"></i> Order Tests
    </button>
  );
}

export function CartSummary(props) {
  const { cart } = props;
  const navigate = useNavigate();

  return (
    <div className="ibox">
      <div className="ibox-title">
        <h5>Order Summary</h5>
      </div>
      <div className="ibox-content">
        <span>Total Tests being ordered</span>
        <h2 className="font-bold">{props.totalItems}</h2>

        <hr />
        <div className="m-t-sm">
          <div className="btn-group">
            <OrderButton cart={cart} />
            <Button style={{ marginLeft: `1em` }} variant={'outline-secondary'} onClick={() => navigate(-1, { replace: true })}>
              {" "}
              Back
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
