import { history } from "./history";

const DOMAIN = process.env.REACT_APP_DOMAIN;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const AUDIENCE = process.env.REACT_APP_AUDIENCE;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo
      ? appState.returnTo
      : history.location.pathname
  );
};

const LOGOUT_URL = "https://".concat(
  DOMAIN,
  "/v2/logout?returnTo=",
  REDIRECT_URI,
  "&client_id=",
  CLIENT_ID
);

export default {
  providerConfig: {
    domain: DOMAIN,
    clientId: CLIENT_ID,
    ...(AUDIENCE ? { audience: AUDIENCE } : null),
    redirectUri: window.location.origin + "/dashboard",
    onRedirectCallback,
    scope:
      "openid profile email read:current_user update:current_user_metadata",
  },
  apiOrigin: API_ORIGIN,
  audience: AUDIENCE,
  redirectLogoutURL: LOGOUT_URL,
};
