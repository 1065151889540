import React from "react";
import { ValidatorComponent } from "react-form-validator-core";

export class InputValidator extends ValidatorComponent {

  renderValidatorComponent() {
    const { isValid } = this.state;
    const {
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      ...rest
    } = this.props;

    return (
      <div className="form-group">
        <input
          className={`form-control${!isValid ? " error" : ""}`}
          {...rest}
          ref={r => {
            this.input = r;
          }}
        />
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <div>
        <label
          className="error"
          style={{ padding: "2px 0", float: "left", fontWeight: "200" }}
        >
          {this.getErrorMessage()}
        </label>
         <div className="clear" style={{clear: "both"}}></div>
      </div>
    );
  }
}
