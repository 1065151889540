import React from "react";
import Pace from "react-pace-progress";

export class Progress extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  load() {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  }

  render() {
    return <div>{this.state.isLoading ? <Pace color="#27ae60" /> : null}</div>;
  }
}
