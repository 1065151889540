import React from "react";
import GifLoader from "react-gif-loader";
import img from "../../../img/shusa_logo.gif";

export class Spinner extends React.Component {
  constructor(props) {
    super(props);
  }
  //other logic
  render() {
    const { loading } = this.props;
    return (
      <div
        id={`loader`}
        style={{
          display: "none",
          zIndex: 999999999999999999,
          height: "100%",
          width: "100%",
          textAlign: "center",
          position: "fixed",
          backgroundColor: "rgba(0,0,0,0.5)",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
      >
        <GifLoader
          className={`gif_style`}
          loading={loading}
          imageSrc={img}
          imageStyle={{ marginTop: `20%` }}
          overlayBackground="rgba(0,0,0,0.5)"
        />
      </div>
    );
  }
}
