import React from "react";
import $ from "jquery";

export class NotFound extends React.Component {
  componentDidMount() {
    // $("body").addClass("gray-bg");
  }

  componentWillUnmount() {
    $("body").removeClass("gray-bg");
  }

  render() {
    return (
      <div className="not-found middle-box text-center animated fadeInDown">
        <h1>404</h1>
        <h3 className="font-bold">Page Not Found</h3>

        <div className="error-desc">
          Sorry, but the page you are looking for has not been found. Try
          checking the URL for error, then hit the refresh button on your
          browser or try found something else in our app.
        </div>
      </div>
    );
  }
}

export class NotFoundShort extends React.Component {
  render() {
    return (
      <div className="not-found middle-box text-center animated fadeInDown">
        <h1>404</h1>
        <h3 className="font-bold">Page Not Found</h3>

        <div className="error-desc">
          Sorry, but the page you are looking for has not been found. Try
          checking the URL for error, then hit the refresh button on your
          browser or try found something else in our app.
        </div>
      </div>
    );
  }
}
