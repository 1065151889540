import React from "react";
import PropTypes from "prop-types";

export class ModelWidget extends React.Component {
  handleClick() {
    this.props.handleButtonClickFunc();
  }

  handleCloseClick() {
    const { handleCloseClickFunc } = this.props;
    if (handleCloseClickFunc) {
      handleCloseClickFunc();
    }
  }

  render() {
    const {
      id,
      icon,
      iconColor,
      body,
      children,
      buttonLabel,
      subTitle,
      title,
      handleButtonClickFunc,
    } = this.props;

    return (
      <div
        className="modal inmodal"
        id={id}
        tabIndex="-1"
        role="dialog"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
              <i
                className={`fa ${icon} modal-icon`}
                style={{ color: iconColor }}
              />
              <h4 className="modal-title">{title}</h4>
              <small>{subTitle}</small>
            </div>
            <div className="modal-body">
              {body}
              {children}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-white"
                data-dismiss="modal"
                onClick={this.handleCloseClick.bind(this)}
              >
                Close
              </button>
              {handleButtonClickFunc && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleClick.bind(this)}
                >
                  {buttonLabel}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const ModalWidgetError = (props) => {
  let handleCloseClick = () => {
    document.getElementById("mainModalAlert").innerHTML = "";
  };

  return (
    <ModelWidget
      id="main-modal-alert"
      icon="fa-exclamation-circle"
      iconColor="#cc0000"
      handleCloseClickFunc={() => handleCloseClick()}
    >
      <h2 className="text-danger text-center">{props.errorMessage}</h2>
    </ModelWidget>
  );
};

// DO NOT DELETE OR MODIFY WITHOUT PERMISSION
ModelWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  icon: PropTypes.string,
  configOptionsList: PropTypes.array,
  handleButtonClickFunc: PropTypes.func,
  buttonLabel: PropTypes.string,
  iconColor: PropTypes.string,
};

ModelWidget.defaultProps = {
  buttonLabel: "Save",
};
