import React from "react";
import PropTypes from "prop-types";

export class NavSearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}
  handlOnSubmit(e) {}

  render() {
    const { pageUrl, placeholder } = this.props;
    return (
      <form
        role="search"
        className="navbar-form-custom"
        action={pageUrl}
        onSubmit={this.handlOnSubmit.bind(this)}
        style={{ display: `none` }}
      >
        <div className="form-group">
          <input
            type="text"
            placeholder={placeholder}
            className="form-control"
            name="search"
            id="top-search"
          />
        </div>
      </form>
    );
  }
}

// DO NOT DELETE OR MODIFY WITHOUT PERMISSION
NavSearchBar.propTypes = {
  pageUrl: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

NavSearchBar.defaultProps = {
  placeholder: "Search...",
};
