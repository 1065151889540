import React, { useEffect } from "react";

export const ServerStatusIcon = ({online, count} ) => {

  const circleCheck = <span>Online <img src={require(`../../img/check-circle-fill.svg`).default} style={{ color: "green" }} width="15" height="15" /></span>
  const circleFill = <span>Offline <img src={require(`../../img/circle-fill.svg`).default} width="15" height="15" /></span>
  const circleHalf = <span>Offline <img src={require(`../../img/circle-half.svg`).default} width="15" height="15" /></span>
  const circleCircle = <span>Offline <img src={require(`../../img/circle.svg`).default} width="15" height="15" /></span>

  return (
    <>
      Status:&nbsp;
      {online && circleCheck}
      {(!online && count == 1) && circleHalf}
      {(!online && count >= 2) && circleFill}
    </>

  )
}
