import CircularProgress from "@mui/material/CircularProgress";

const Circular = () => (
  // we need to add some padding to circular progress to keep it from activating our scrollbar
  <div style={{ padding: "24px", textAlign: "center" }}>
    <h1>Please wait...loading....</h1>
    <CircularProgress size={75} />
  </div>
);

const CircularPage = () => (
  // we need to add some padding to circular progress to keep it from activating our scrollbar
  <div className="wrapper wrapper-content">
    <div className="middle-box animated fadeInTop">
      <div style={{ padding: "24px", textAlign: "center", color: "white" }}>
        <h2>Please wait...loading....</h2>
        <CircularProgress size={75} />
      </div>
    </div>
  </div>
);

export { Circular, CircularPage };
