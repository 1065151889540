import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// Generic Routing Components

// Main Wrapper

import { Spinner } from "./components/common/gif_loader/spinner";

// CSS Imports
import "./App.scss";

//import "./dna-logo.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Helpers
//import { Spinner } from "./components/helpers/spinner";
import Api from "./service/api";
import { Log, DebugLog, ErrorLog } from "./helpers/logs";
import { redirectToLogin, setAuth0TokenObject } from "./service/_api_helper";
import Cookies from "js-cookie";
import { Role } from "./config/role";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { history } from "./config/history";

//import Routes from "./config/Routes";
import Login from "./components/layouts/login";
import LoginButton from "./components/layouts/login";
import { Main } from "./components/layouts/main";
import { NotFound } from "./components/layouts/not_found";

import Orders from "./views/orders";
import PendingOrders from "./views/pending_orders";
import HighCriticality from "./views/high_criticality";
import Registries from "./views/registries";
import Patients from "./views/patients";
import Admin from "./views/admin";

import Dashboard from "./views/dashboard";

import UserContext from "./context/user_context";
import CartView from "./views/cart_view";

const FIVE_MINUTES_IN_EPOCH = 5 * 60 * 1000;

function Auth0App() {
  const {
    isAuthenticated,
    isLoading,
    error,
    getAccessTokenSilently,
    user,
    getIdTokenClaims,
    logout,
  } = useAuth0();
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    var session = Cookies.get("_session");
    var sessionExp = Cookies.get("_session_exp");
    var sessionCheck = true;
    const sessionExpFiveMinBefore = sessionExp * 1000 - FIVE_MINUTES_IN_EPOCH;

    if (session == undefined || sessionExp == undefined) {
      sessionCheck = false;
    }

    if (isLoading) {
      Log("App is loading");
      return;
    }

    if (!isAuthenticated) {
      Log("not authenticated. redirecting to login");
      if (notLoginPage()) {
        redirectToLogin(logout);
      }
      return;
    }
    DebugLog("SessionExp", sessionExp);

    if (sessionExpFiveMinBefore < Date.now() || !sessionExp) {
      getToken();
    } else {
      InitializeUser();
    }
  }, [isLoading, isAuthenticated]);

  const getToken = (options) => {
    var res = {};

    setLoading(() => true);

    getIdTokenClaims().then((idToken) => {
      DebugLog("Retrieved ID Token!", idToken);
      res.expiresAt = idToken.exp;

      setAuth0TokenObject(idToken);

      getAccessTokenSilently(options)
        .then((token) => {
          DebugLog("Retrieved Token!", token);

          res.refreshToken = token; // FIX
          res.accessToken = token;

          Api.IConnectApi.StoreToken(res);

          Api.FrontendLogs.loginLog();

          InitializeUser();
        })
        .catch((e) => {
          ErrorLog("failed to get token!", e);
          setLoading(() => false);
        });
    });
  };

  const InitializeUser = () => {
    // Grab roles from user object from Auth0
    const userRoles = user["https://imorpheus.com/roles"];

    var userRolesList = [];
    userRoles.map((x, i) => {
      userRolesList.push({ id: i + 1, name: x });
    });
    // End Auth0 role grab

    Log("Roles: ", userRolesList);
    Api.IConnectApi.GetCurrentUser()
      .then((response) => {
        Log("Initialize response: ", response);
        if (response !== undefined) {
          if (response && response.id !== 0) {
            user.id = response.id;
          }
          user.roles = userRolesList;
          user.user_preference = response.user.user_preference;
          user.client_locations = response.user.client_locations;

          DebugLog("UserRolesList", userRolesList);

          setIsAdmin(user && user.roles === Role.Admin);

          Cookies.set(
            "https://imorpheus.com/correlation_id",
            user[`https://imorpheus.com/correlation_id`]
          );

          setCurrentUser(user);

          setLoading(() => false);
        }
      })
      .catch((er) => {
        ErrorLog(`Er: `, er);
        setLoading(() => false);
      });
  };

  //#region session helper functions
  function _getSessionKey() {
    return Cookies.get("_session");
  }

  //#region route checks
  function currentPage() {
    return window.location.pathname;
  }

  function notLoginPage() {
    var val = currentPage();
    return val.split("/")[val.split("/").length - 1] !== "login";
  }
  //#endregion

  // FIX - determine user roles here
  const ProtectedRoute = ({ component, path, ...args }) => {
    const Component = withAuthenticationRequired(component, args);

    if (path == "admin" && user != undefined && user.roles != undefined) {
      if (user.roles.find((x) => x.name == "Admin") == undefined) {
        return <Navigate to={`/dashboard`} />;
      }
    }

    if (
      currentUser != undefined &&
      currentUser != null &&
      (currentUser.client_locations == undefined ||
        currentUser.client_locations == null ||
        currentUser.client_locations.length == 0)
    ) {
      if (user.roles.find((x) => x.name == "Admin") == undefined) {
        return <Navigate to={`/login`} />;
      } else if (path != "admin") {
        return <Navigate to={`/admin`} />;
      }
    }

    return (
      <Main
        InitializeUser={InitializeUser}
        currentUser={user}
        history={history}
        loading={loading}
      >
        <Component currentUser={currentUser} />
      </Main>
    );
  };

  return (
    <UserContext.Provider value={currentUser}>
      {/* {isLoading && <CircularPage />} */}
      <Router basename={"/"}>
        <Spinner loading />

        <Routes>
          {/* ONLY protected routes go here */}
          <Route
            path="/dashboard"
            exact
            element={<ProtectedRoute component={Dashboard} />}
          />
          <Route
            path="/orders"
            exact
            element={<ProtectedRoute component={Orders} />}
          />
          <Route
            path="/pending"
            exact
            element={<ProtectedRoute component={PendingOrders} />}
          />
          <Route
            path="/criticality"
            exact
            element={<ProtectedRoute component={HighCriticality} />}
          />
          <Route
            path="/registries"
            exact
            element={<ProtectedRoute component={Registries} />}
          />
          <Route
            path="/patients"
            exact
            element={<ProtectedRoute component={Patients} />}
          />
          <Route
            path="/admin"
            exact
            element={<ProtectedRoute component={Admin} path="admin" />}
          />
          <Route
            path="/cart"
            exact
            element={<ProtectedRoute component={CartView} />}
          />

          {/* Not protected routes go here */}
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/logintest" element={<LoginButton />} />
          <Route exact path="/logout" element={<Navigate to={`/login`} />} />
          <Route path="/" element={<Navigate to={`/dashboard`} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default Auth0App;
