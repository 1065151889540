import React, { Component } from "react";
import PropTypes from "prop-types";

const LastActivity = props => {
  const { time, description, first } = props.activity;
  const isFirst = first ? "fist-item" : "";

  return (
    <li className={`list-group-item ${isFirst}`}>
      <span className="float-right"> {time} </span> {description}
    </li>
  );
};

const TimelineActivity = props => {
  const { description, time, icon } = props.activity;

  return (
    <div className="vertical-timeline-block">
      <div className="vertical-timeline-icon gray-bg">
        <i className={`fa fa-${icon}`} />
      </div>
      <div className="vertical-timeline-content">
        <p>{description}</p>
        <span className="vertical-date small text-muted">{time}</span>
      </div>
    </div>
  );
};

export class TabTableDetails extends Component {
  render() {
    const {
      description,
      name,
      notes,
      lastActivity,
      timelineActivity
    } = this.props.details;

    return (
      <div id="table-row-1" className="tab-pane">
        <div className="m-b-lg">
          <h2>{name}</h2>
          <p>{description}</p>
        </div>
        <div className="client-detail">
          <div className="full-height-scroll">
            <strong>Last activity</strong>

            <ul className="list-group clear-list">
              {lastActivity &&
                lastActivity.map((activity, idx) => {
                  return (
                    <LastActivity
                      key={`last-activity-${idx}`}
                      activity={activity}
                    />
                  );
                })}
            </ul>
            <strong>Notes</strong>
            <p>{notes}</p>
            <hr />
            <strong>Timeline activity</strong>
            <div
              id="vertical-timeline"
              className="vertical-container dark-timeline"
            >
              {timelineActivity &&
                timelineActivity.map((activity, idx) => {
                  return (
                    <TimelineActivity
                      key={`timeline-activity-${activity.id}`}
                      activity={activity}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// DO NOT DELETE OR MODIFY WITHOUT PERMISSION
TabTableDetails.propTypes = {
  active: PropTypes.bool,
  id: PropTypes.string.isRequired
};

TabTableDetails.defaultProps = {
  id: PropTypes.string.isRequired,
  rowData: []
};
