import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";

export class Breadcrumb extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { pageTitle } = this.props;
    return (
      <div
        id="primary-breadcrumb"
        className="row wrapper border-bottom white-bg page-heading"
      >
        <div className="col-lg-9">
          <h2> {pageTitle}</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Home</a>
            </li>
            <li className="breadcrumb-item active">
              <strong>{pageTitle}</strong>
            </li>
          </ol>
        </div>
      </div>
    );
  }
}

// DO NOT DELETE OR MODIFY WITHOUT PERMISSION
Breadcrumb.propTypes = {
  pageTitle: PropTypes.string.isRequired
};
