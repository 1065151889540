import React from "react";
import $ from "jquery";
import PropTypes from "prop-types";

export class IboxTools extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  collapsePanel(e) {
    e.preventDefault();
    var element = $(e.target);
    var ibox = element.closest("div.ibox");
    var button = element.closest("i");
    var content = ibox.find("div.ibox-content");
    content.slideToggle(200);
    button.toggleClass("fa-chevron-up").toggleClass("fa-chevron-down");
    ibox.toggleClass("").toggleClass("border-bottom");
    setTimeout(function () {
      ibox.resize();
      ibox.find("[id^=map-]").resize();
    }, 50);
  }

  closePanel(e) {
    e.preventDefault();
    var element = $(e.target);
    var content = element.closest("div.ibox");
    content.remove();
  }

  render() {
    const {
      configOptionsList,
      showCollapseOption,
      showCloseOption,
      showConfigOption
    } = this.props;

    return (
      <div className="ibox-tools">
        {showCollapseOption && (
          <a className="collapse-link" onClick={this.collapsePanel}>
            <i className="fa fa-chevron-up" />
          </a>
        )}

        {showConfigOption && (
          <a className="dropdown-toggle" data-toggle="dropdown" href="#">
            <i className="fa fa-wrench" />
          </a>
        )}
        {showConfigOption && (
          <ul className="dropdown-menu dropdown-user">
            {configOptionsList.map((option, index) => {
              return (
                <li key={index}>
                  <a href={option.href}>{option.optionTitle}</a>
                </li>
              );
            })}
          </ul>
        )}
        {showCloseOption && (
          <a className="close-link" onClick={this.closePanel}>
            <i className="fa fa-times" />
          </a>
        )}
      </div>
    );
  }
}

IboxTools.propTypes = {
  showCollapseOption: PropTypes.bool,
  showCloseOption: PropTypes.bool,
  showConfigOption: PropTypes.bool,
  configOptionsList: PropTypes.array
};

IboxTools.defaultProps = {
  configOptionsList: []
};
