import React, { useState, Component, useEffect } from "react";
import { Ibox } from "../components/common/ibox";
import { Row, Col } from "react-bootstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Log, DebugLog, ErrorLog } from "../helpers/logs";
import { ApiHelperMethods, redirectToLogin } from "../service/_api_helper";

import PendingOrdersTable from "../components/dashboard_components/pending_orders";
import { PendingSampleData } from "../test/refactored_data/pending_sample_data";
import HighCriticality from "./high_criticality";
import api from "../service/api";

export default function Dashboard(props) {
  Log("Dashboard loaded");

  const [orders, setOrders] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState("stratification.rank");
  const [order, setOrder] = useState("desc");
  const [filterText, setFilterText] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);

  const [loading, setLoading] = useState(false);
  const [textLoading, setTextLoading] = useState(null);
  const [timer, setTimer] = useState(null);
  const [time, setTime] = useState(2000);

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    if (textLoading == true) {
      if (timer != null) {
        setTimer(() => clearTimeout(timer));

        setTimer(() => setTimeout(() => getOrders(), time));
      } else {
        setTimer(() => clearTimeout(timer));

        setTimer(() => setTimeout(() => getOrders(), time));
      }
    }
  }, [filterText]);

  function filterTextWrapper(e) {
    setTextLoading(() => true);
    setFilterText(() => e);
  }

  useEffect(() => {
    getOrders();
  }, [page, order, orderBy, rowsPerPage]);

  function getOrders() {
    var body = {};

    body.pagination = true;
    body.sort_header = orderBy;
    body.sort_order = order;
    body.page_number = page + 1;
    body.page_size = rowsPerPage;
    body.filter_text = filterText;

    body.pending = true;

    setTextLoading(() => false);
    setLoading(() => true);

    api.IConnectApi.getTests(body)
      .then((response) => {
        setLoading(() => false);
        setOrders(response.result.data);
        setTotalRecords(response.result.total_records);
      })
      .catch((er) => {
        setLoading(() => false);
        Log("Er: ", er);
      });
  }

  var pendingProps = {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    filterText,
    setFilterText: filterTextWrapper,
    totalRecords,
    data: PendingSampleData,
    orders,
    getOrders,
    pending: true,
    loading: loading || textLoading,
  };

  return (
    <>
      {/*
      <Row>
        <DashboardStats />
      </Row>
      */}
      <Row>
        <Col lg={12}>
          <HighCriticality {...props} dashbaord />
        </Col>
        <Col lg={12} style={{ minHeight: "500px !important" }}>
          {
            //
          }
          <Ibox
            enableTitleBar
            title={`Pending Orders`}
            id={`recently_ordered_tests`}
            iboxTools={false}
          >
            <PendingOrdersTable {...pendingProps} />
          </Ibox>
        </Col>
      </Row>
    </>
  );
}
