import React, { useState, useContext } from "react";
import { Row, Col, FormLabel } from "react-bootstrap";
import { DropdownComponent } from "../common/dropdown_component";

// Cart Context: This is what handles the cart variables
import CartContext from "../../context/cart_context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlaskVial } from "@fortawesome/free-solid-svg-icons";

/*
    RegistryDropdown component

    Handles the dropdowns at the top of the registry page

    Author: William Brown
    Updated By: 
*/
export default function RegistryDropdown(props) {
  var {
    registries,
    activeTab,
    setActiveTab,
    isTabView,
    locations,
    location,
    setLocation,
    physicians,
    physician,
    setPhysician,
    setDocument,
  } = props;

  const [selectedDocument, setSelectedDocument] = useState(null);

  var clientDocuments = [];

  if (location.client != undefined) {
    clientDocuments = clientDocuments.concat(
      Object.assign([], location.client.documents)
    );

    clientDocuments.map((cd) => {
      cd.value = cd.id;
      cd.label = cd.document.name;
    });
  }

  function handleRegistryDropdown(e) {
    setActiveTab(e.code);
  }

  return (
    <Row>
      <Col lg={4}>
        <FormLabel aria-label={`Locations`}>Locations</FormLabel>
        <DropdownComponent
          id="dropdown_component_id"
          propkey="dropdown_component_key"
          dropdownArray={locations}
          handleChangeFunc={setLocation}
          selectedOption={location}
          searchable={true}
          placeholder="Client Locations"
          isMulti={false}
          isDisabled={false}
          autoFocus={false}
        />
      </Col>
      <Col lg={4}>
        <FormLabel aria-label={`Providers`}>Providers</FormLabel>
        <DropdownComponent
          id="dropdown_component_id_Provider"
          propkey="dropdown_component_key_Provider"
          dropdownArray={physicians}
          handleChangeFunc={setPhysician}
          selectedOption={physician}
          searchable={true}
          placeholder="Provider"
          isMulti={false}
          isDisabled={false}
          autoFocus={false}
        />
      </Col>
      <Col lg={4}>
        <FormLabel aria-label={`Client Documents`}>Client Documents</FormLabel>
        <DropdownComponent
          id="documents_dropdown_component_id"
          propkey="documents_dropdown_component_id"
          dropdownArray={clientDocuments}
          handleChangeFunc={(e) => setDocument(e.document)}
          selectedOption={selectedDocument}
          searchable={true}
          placeholder="Select a Document"
          isMulti={false}
          isDisabled={false}
          autoFocus={false}
        />
      </Col>
      {!isTabView ? (
        <Row style={{ marginTop: "1em" }}>
          <Col lg={4}>
            <FormLabel aria-label={`Registries`}>Registries</FormLabel>
            <DropdownComponent
              id="dropdown_component_id_Registry"
              propkey="dropdown_component_key_Registry"
              dropdownArray={registries}
              handleChangeFunc={handleRegistryDropdown}
              selectedOption={registries.find((x) => x.code == activeTab)}
              searchable={true}
              placeholder="Registry"
              isMulti={false}
              isDisabled={false}
              autoFocus={false}
            />
          </Col>
        </Row>
      ) : null}
    </Row>
  );
}
