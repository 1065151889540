import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import TextField from "@mui/material/TextField";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import api from "../../service/api";

import Swal from "sweetalert2";
import moment from "moment";

export default function PatientDetailsModal(props) {
  const {
    showPatientModal,
    setShowPatientModal,
    selectedEpisode,
    location,
    registry,
  } = props;

  const [open, setOpen] = useState(true);
  const [optTime, setOptTime] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  if (selectedEpisode.patient == undefined) {
    return null;
  }

  function handleOptOut() {
    var optedOut = false;

    if (
      selectedEpisode.patient_opt_out != null &&
      selectedEpisode.patient_opt_out.opted_out
    ) {
      optedOut = true;
    }

    Swal.fire({
      title: `Confirm`,
      icon: `info`,
      text: `${
        optedOut
          ? `Opt patient in`
          : `Opt Out Patient ${`${selectedEpisode.patient.first_name} ${selectedEpisode.patient.last_name}`} for ${optTime} months`
      }`,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: `Confirm`,
    }).then((result) => {
      if (result.isConfirmed) {
        api.IConnectApi.optOutEpisode(selectedEpisode.patient.id, {
          client_location_id: location.id,
          opt_out: !optedOut,
          time_in_months: Number(optTime),
          registry: registry.id,
        }).then((response) => {
          Swal.fire("Success", "", "success").then(() => {
            showPatientModal(() => false);
          });
        });
      }
    });
  }

  function handleMarkAsDeceased() {
    Swal.fire({
      title: `Confirm`,
      icon: `warning`,
      text: `Mark ${`${selectedEpisode.patient.first_name} ${selectedEpisode.patient.last_name}`} as ${
        selectedEpisode.patient.deceased ? "alive" : "deceased"
      }`,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: `Confirm`,
    }).then((result) => {
      if (result.isConfirmed) {
        api.IConnectApi.togglePatientDeceased(
          selectedEpisode.patient.id,
          !selectedEpisode.patient.deceased
        )
          .then((response) => {
            Swal.fire("Success", "", "success").then(() => {
              setShowPatientModal(() => false);
              setOptTime("");
            });
          })
          .catch((er) => {
            Swal.fire("Update failed", "error");
          });
      }
    });
  }

  return (
    <div>
      <Dialog open={showPatientModal} onClose={() => setOptTime("")}>
        <DialogTitle>Patient Options</DialogTitle>
        <DialogContent>
          <Row>
            <Col xs={12}>
              <DialogContentText
                aria-label={`Name: ${`${selectedEpisode.patient.first_name} ${selectedEpisode.patient.last_name}`}`}
              >
                Patient Name:{" "}
                {`${selectedEpisode.patient.first_name} ${selectedEpisode.patient.last_name}`}
              </DialogContentText>
            </Col>
            {selectedEpisode.patient_opt_out != null &&
            selectedEpisode.patient_opt_out.opted_out ? (
              <span>
                Patient is opted out until:{" "}
                {moment(selectedEpisode.patient_opt_out.opted_out_till).format(
                  `MM-DD-yyyy`
                )}
              </span>
            ) : (
              <Col xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="id"
                  label="Opt out time (In Months)"
                  variant="standard"
                  fullWidth
                  aria-label="Opt out time in months"
                  type={`number`}
                  value={optTime}
                  onChange={(e) => {
                    let input = e.target.value;
                    if (Number(input) >= 0) {
                      setOptTime(e.target.value);
                    }
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Col>
            )}
            <Col lg={12} style={{ marginTop: `1em` }}>
              <Button
                disabled={
                  optTime == "" &&
                  !(
                    selectedEpisode.patient_opt_out != null &&
                    selectedEpisode.patient_opt_out.opted_out
                  )
                }
                aria-label={`Opt out patient`}
                onClick={handleOptOut}
              >
                {selectedEpisode.patient_opt_out != null &&
                selectedEpisode.patient_opt_out.opted_out
                  ? `Opt patient in`
                  : `Opt Out Patient`}
              </Button>
            </Col>

            <Col lg={12}></Col>
            <Col xs={12} style={{ marginTop: `2rem` }}>
              <Button onClick={handleMarkAsDeceased} variant={`danger`}>
                Mark patient as{" "}
                {selectedEpisode.patient.deceased ? `alive` : `deceased`}
              </Button>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button
            variant={`secondary`}
            onClick={() => {
              setShowPatientModal(false);
              setOptTime("");
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
