import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";

export class BannerErrorWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = { errorMessage: null };
  }

  componentDidMount() {
    if (this.props.autoHide) {
      $("#error-banner-dynamic, .error-state")
        .slideDown(500)
        .fadeTo(3000, 500)
        .slideUp(500);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !!(!this.props.error && nextProps.error);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error) {
      $(".error-state")
        .slideDown(500)
        .fadeTo(6000, 500)
        .slideUp(500);
    }
  }

  setError(message) {
    this.setState({ errorMessage: message });
  }

  render() {
    const { autoHide, error } = this.props;
    if (error) {
      return (
        <div
          className={`alert ${this.props.alertType ||
            "alert-danger"} error-state ${autoHide ? "autoHide" : ""}`}
          onClick={this.props.handleClick}
        >
          <i className={this.props.alertIcon} />
          <strong>{this.state.errorMessage || error}</strong>
        </div>
      );
    } else {
      return null;
    }
  }
}

// DO NOT DELETE OR MODIFY WITHOUT PERMISSION
BannerErrorWidget.propTypes = {
  error: PropTypes.string,
  handleClick: PropTypes.func,
  alertIcon: PropTypes.string
};
