import * as React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

import { PendingSampleData } from "../../../test/refactored_data/pending_sample_data";
import PendingOrdersTable from "../pending_orders";
import { DialogContent } from "@mui/material";

export default function OrderDetailModal(props) {
  const { onClose, open, order, getOrders } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={`lg`}
      onClose={handleClose}
      open={open}
      style={{ zIndex: "99999999" }}
    >
      <DialogTitle>Order Details</DialogTitle>
      <DialogContent>
        <PendingOrdersTable
          isModal
          data={PendingSampleData}
          pending
          orders={[order]}
          order={[order]}
          handleClose={handleClose}
          getOrders={getOrders}
        />
      </DialogContent>
    </Dialog>
  );
}

OrderDetailModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
};
