import React, { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import UserStepWizardComponent from "./user_step_wizard";

import Button from "@mui/material/Button";

import api from "../../service/api";

import { DebugLog, ErrorLog } from "../../helpers/logs";

import $ from "jquery";

export default function AddUserModal(props) {
  const { showUserModal, setShowUserModal } = props;
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(1);

  const [organizations, setOrganizations] = useState([]);
  const [apiRoles, setApiRoles] = useState([]);

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      window.history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
      setOpen(false);
      setShowUserModal(false);
    }
  };

  useEffect(() => {
    if (showUserModal) {
      getOrganizations();
    }
  }, [showUserModal]);

  function getOrganizations() {
    $(`#loader`).show();

    api.Organizations.getAll()
      .then((response) => {
        DebugLog("Org Response: ", response);

        setOrganizations(response);

        getRoles();
      })
      .catch((er) => {
        ErrorLog("Er: ", er);

        $(`#loader`).hide();
      });
  }

  function getRoles() {
    $(`#loader`).show();

    api.Roles.getAll()
      .then((response) => {
        DebugLog("Roles Response: ", response);

        setApiRoles(response.roles);

        $(`#loader`).hide();
      })
      .catch((er) => {
        ErrorLog("Er: ", er);

        $(`#loader`).hide();
      });
  }

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userPreferences, setUserPreferences] = useState({
    preferred_client_id: null,
    preferred_physician_id: null,
    preferred_registry_id: null,
    enable_tab_view: null,
  });
  const [roles, setRoles] = useState([]);
  const [npi, setNpi] = useState("");

  const userParams = {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    password,
    setPassword,
    userPreferences,
    setUserPreferences,
    roles,
    setRoles,
    npi,
    setNpi,
    email,
    setEmail,
    apiRoles,
  };

  return (
    <div>
      <Dialog
        lg={{
          "& .MuiDialog-paper": {
            maxWidth: "40%",
            height: 500,
            maxHeight: 500,
          },
        }}
        maxWidth="lg"
        open={showUserModal}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle>Add User</DialogTitle>
        <DialogContent style={{ height: `50rem` }}>
          <UserStepWizardComponent
            organizations={organizations}
            handleClose={handleClose}
            {...userParams}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
