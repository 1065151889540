import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export class NavListItemDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = { expanded: false }
    this.showDropdown = this.showDropdown.bind(this)
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  activeButton() {
    const {routes, location} = this.props
    var isActive = false;

    routes.map(val => {
      if (location.pathname.indexOf(val.path) > -1) {
        isActive = true;
      }
    });
    return isActive;
  }

  showDropdown(){
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { children, routeLink, title, icon } = this.props;
    let { expanded } = this.state;

    return (
      <li>
        <button onClick={this.showDropdown.bind(this)} className={`dropdown-btn ${this.activeButton() ? `btnactive` : ``}`}>
          <span className="dropdownitem">
            <i className={`fa ${icon}`} style={{ marginRight: "6px" }} />{" "}<span className="nav-label">{title}</span>
            {
              expanded ?
                <i className="fa fa-caret-up"></i>
                :
                <i className="fa fa-caret-down"></i>
            }
          </span>
        </button>
        <div id={routeLink} style={{ display: expanded ? `initial` : `none` }}>
          <ul className="nav metismenu">
            {
              this.props.routes.map((val, index) => {
                return (
                  <li style={{ marginTop: ".2em" }} id={val.title + index} key={val.title + index} className={this.activeRoute(val.path)}>
                    <Link to={val.path} style={{ paddingLeft: "35px" }} className={"dropdownitem"}>
                      <i className={`fa ${val.icon}`} /><span className="nav-label">{val.title}</span>
                    </Link>
                    {children}
                  </li>
                );
              })
            }
          </ul>
        </div>
      </li>
    );
  }
}

NavListItemDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  propkey: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  routes: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  routeLink: PropTypes.string.isRequired
}