import React, { useState, useEffect } from "react";
import StepWizard from "react-step-wizard";
import transitions from "./transitions.scss";

import { Checkbox, FormLabel, TextField } from "@mui/material";

import { Row, Col } from "react-bootstrap";

import TransferList from "./transfer_list";

import * as yup from "yup";
import api from "../../service/api";
import { ErrorLog, Log } from "../../helpers/logs";

import $ from "jquery";

import { grey, pink } from "@mui/material/colors";

export default function UserStepWizardComponent(props) {
  const [state, updateState] = useState({
    form: {},
    transitions: {
      enterRight: `${transitions.animated} ${transitions.enterRight}`,
      enterLeft: `${transitions.animated} ${transitions.enterLeft}`,
      exitRight: `${transitions.animated} ${transitions.exitRight}`,
      exitLeft: `${transitions.animated} ${transitions.exitLeft}`,
      intro: `${transitions.animated} ${transitions.intro}`,
    },
    demo: false,
  });

  const { organizations, apiRoles, isEdit, roles } = props;

  const [selectedOrg, setSelectedOrg] = useState([]);

  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);

  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  // Handles adding clients to array when an org is added to the list
  useEffect(() => {
    var tempClients = [];

    selectedOrg.map((x) => {
      x.clients.map((y) => {
        if (tempClients.findIndex((z) => z.id == y.id) == -1) {
          tempClients.push(y);
        }
      });
    });

    setClients(tempClients);
  }, [selectedOrg]);

  useEffect(() => {
    var tempLocations = [];
    selectedClients.map((x) => {
      tempLocations = tempLocations.concat(x.client.client_locations);
    });

    setLocations(tempLocations);
  }, [selectedClients]);

  useEffect(() => {}, [selectedLocations]);

  const onStepChange = (stats) => {
    Log("Stats: ", stats);
  };

  const setInstance = (SW) =>
    updateState({
      ...state,
      SW,
    });

  const { SW, demo } = state;

  const schema = yup.object({
    first_name: yup.string().required(),
  });

  function editUser(user) {
    const { handleClose, roles } = props;

    api.Users.editUser(user)
      .then((response) => {
        var tempLocations = [];
        selectedLocations.map((loc) => {
          if (loc.client_location != undefined) {
            tempLocations.push(loc.client_location.id);
          } else {
            tempLocations.push(loc.id);
          }
        });

        api.UserLocations.setUserLocations(user.id, tempLocations)
          .then((locResponse) => {
            api.Roles.updateRoles(user.id, roles)
              .then((resposne) => {
                window.location.hash = "";

                $(`#loader`).hide();

                handleClose();
              })
              .catch((er) => {
                $(`#loader`).hide();

                window.location.hash = "";
                handleClose();
              });
          })
          .catch((er) => {
            ErrorLog("Er: ", er);

            $(`#loader`).hide();

            window.location.hash = "";
            handleClose();
          });
      })
      .catch((er) => {
        ErrorLog("ER: ", er);

        $(`#loader`).hide();

        window.location.hash = "";
        handleClose();
      });
  }

  function createUser(user) {
    const { handleClose } = props;

    api.Users.createUser(user)
      .then((response) => {
        var tempLocations = [];
        selectedLocations.map((loc) => {
          tempLocations.push(loc.id);
        });

        api.UserLocations.setUserLocations(response.user.id, tempLocations)
          .then((locResponse) => {
            window.location.hash = "";

            $(`#loader`).hide();

            handleClose();
          })
          .catch((er) => {
            ErrorLog("Er: ", er);

            $(`#loader`).hide();

            window.location.hash = "";
            handleClose();
          });
      })
      .catch((er) => {
        ErrorLog("ER: ", er);

        $(`#loader`).hide();

        window.location.hash = "";
        handleClose();
      });
  }

  function finalize() {
    const {
      firstName,
      lastName,
      password,
      email,
      userPreferences,
      roles,
      npi,
      userId,
    } = props;

    $(`#loader`).show();

    if (isEdit) {
      Log("Editing User");
      editUser({
        FirstName: firstName,
        LastName: lastName,
        user_preference: userPreferences,
        npi,
        id: userId,
      });
    } else {
      createUser({
        FirstName: firstName,
        LastName: lastName,
        email,
        password,
        user_preference: userPreferences,
        RoleIds: roles,
        npi,
      });
    }
  }

  return (
    <StepWizard
      isHashEnabled
      onStepChange={onStepChange}
      instance={setInstance}
      style={{ height: `100% !important` }}
    >
      <UserInfo hashKey={"FirstStep"} page={1} schema={schema} {...props} />
      <OrganizationSelection
        hashKey={"SecondStep"}
        page={2}
        organizations={organizations}
        setSelectedOrg={setSelectedOrg}
        selectedClients={selectedClients}
        setSelectedClients={setSelectedClients}
        {...props}
      />
      <ClientSelection
        hashKey={"ThirdStep"}
        page={3}
        clients={clients}
        selectedClients={selectedClients}
        setSelectedClients={setSelectedClients}
        {...props}
      />
      <LocationSelection
        hashKey={"FourthStep"}
        page={4}
        locations={locations}
        setSelectedLocations={setSelectedLocations}
        selectedLocations={selectedLocations}
        selectedClients={selectedClients}
        finalize={finalize}
        {...props}
      />
    </StepWizard>
  );
}

function UserInfo(props) {
  function validate(e) {
    var returnValue = true;
    var errList = [];

    if (isEdit) {
      if (
        checked.findIndex((x) => x.name == "Authorize") > -1 &&
        (npi == null || npi == "")
      ) {
        returnValue = false;

        errList.push("npi");
      }
    } else {
      roles.map((x) => {
        var roleIndex = apiRoles.findIndex((y) => y.id == x);

        if (
          apiRoles[roleIndex].name == "Authorize" &&
          (npi == null || npi == "")
        ) {
          returnValue = false;

          errList.push("npi");
        }
      });

      if (
        !password.match(
          /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/gm
        )
      ) {
        returnValue = false;

        errList.push("password");
      }
    }
    if (!returnValue) {
      if (errList.includes("npi")) {
        setNpiError(true);
      }

      if (errList.includes("password")) {
        setPasswordError(true);
      }

      const element = document.getElementById(`user_block`);
      element.classList.add("animate__animated", "animate__shakeX");
      setTimeout(function () {
        element.classList.remove("animate__animated", "animate__shakeX");
      }, 3000);
    }

    return returnValue;
  }

  const [checked, setChecked] = useState([]);
  const [leftList, setLeftList] = useState([]);

  const [npiError, setNpiError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const {
    page,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    password,
    setPassword,
    email,
    setEmail,
    npi,
    setNpi,
    schema,
    apiRoles,
    setRoles,
    isEdit,
    roles,
    isActive,
    setIsActive,
  } = props;

  useEffect(() => {
    if (npiError) {
      setNpiError(false);
    }
  }, [npi]);

  useEffect(() => {
    if (passwordError) {
      setPasswordError(false);
    }
  }, [password]);

  useEffect(() => {
    handleEdit();
  }, [roles, apiRoles]);

  function handleEdit() {
    if (isEdit) {
      var temp = [];

      apiRoles.map((x) => {
        if (roles.includes(x.id)) {
          temp.push(x);
        }
      });

      setLeftList(
        Object.assign(
          [],
          apiRoles.filter((item) => !roles.includes(item.id))
        )
      );
      setChecked(
        Object.assign(
          [],
          apiRoles.filter((item) => roles.includes(item.id))
        )
      );
    }
  }

  function npiWrapper(e) {
    var temp = [];

    if (isEdit) {
      setChecked(e);
    }

    e.map((item) => {
      temp.push(item.id);
    });

    setRoles(temp);
  }

  return (
    <div style={{ height: "100% !important" }}>
      <h3 className="text-center">User Information</h3>
      <Row id={`user_block`}>
        <Col lg={6} sm={12}>
          <TextField
            id="standard-basic"
            label="First Name"
            variant="standard"
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Col>
        <Col lg={6} sm={12}>
          <TextField
            id="standard-basic"
            label="Last Name"
            variant="standard"
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Col>
        <Col
          lg={6}
          sm={12}
          style={isEdit != undefined && isEdit ? { display: "none" } : {}}
        >
          <TextField
            id="standard-basic"
            label="Password"
            variant="standard"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={passwordError}
            helperText={
              passwordError
                ? "Password must be at least 8 characters long, contain an uppercase letter, lower case letter, a special character (!@#$%^&*) and a number."
                : null
            }
          />
        </Col>
        <Col lg={6} sm={12}>
          <TextField
            id="standard-basic"
            label="Email"
            variant="standard"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Col>
        <Col lg={6} sm={12}>
          <TextField
            id="standard-basic"
            label="NPI"
            variant="standard"
            fullWidth
            type="number"
            value={npi}
            helperText={npiError ? "NPI requried for authorization user" : null}
            error={npiError}
            onChange={(e) => setNpi(e.target.value)}
          />
        </Col>
        {false ? (
          <Col lg={6}>
            <Checkbox
              title={`Active`}
              checked={isActive}
              onClick={() => setIsActive(() => !isActive)}
              sx={{
                "&.Mui-checked": {
                  color: pink[`A400`],
                },
                "&.MuiCheckbox-indeterminate": {
                  color: grey[700],
                },
              }}
            />
            <FormLabel style={{ fontWeight: "500 " }} aria-label="Active">
              Active
            </FormLabel>
          </Col>
        ) : null}
      </Row>
      <Col lg={12} style={{ marginTop: `2rem` }}>
        <h3 className="text-center">User Roles</h3>
        <TransferList
          dataList={isEdit ? leftList : apiRoles}
          isEdit={isEdit}
          checkedProp={checked}
          displayParam={"name"}
          rightList={(e) => npiWrapper(e)}
        />
      </Col>
      <Stats step={page} {...props} validate={validate} />
    </div>
  );
}

function OrganizationSelection(props) {
  const validate = () => {
    props.previousStep();
  };

  const {
    isEdit,
    editLocations,
    organizations,
    setSelectedOrg,
    setLocations,
    selectedClients,
    setSelectedClients,
  } = props;

  useEffect(() => {
    if (isEdit) {
      handleEdit();
    }
  }, [organizations]);

  const [checked, setChecked] = useState();
  const [leftList, setLeftList] = useState([]);

  function handleEdit(test) {
    var right = [];
    var left = test == undefined ? [] : Object.assign([], organizations);

    if (test == undefined) {
      organizations.map((x) =>
        x.clients.map((y) =>
          y.client.client_locations.map((z) =>
            editLocations.map((t) => {
              if (
                t.client_location.id == z.id &&
                right.findIndex((zy) => zy.id == x.id) < 0
              ) {
                right.push(x);
              }
            })
          )
        )
      );

      organizations.map((org) => {
        var add = true;

        right.map((ri) => {
          if (ri.id == org.id) {
            add = false;
          }
        });

        if (add) {
          left.push(org);
        }
      });
    } else {
      right = right.concat(test);

      right.map((t) => {
        left = left.filter((x) => x.id != t.id);
      });

      var tempClients = Object.assign([], selectedClients);
      var tempLocations = Object.assign([], editLocations);

      left.map((l) => {
        tempLocations = tempLocations.filter(
          (el) => el.client_location.client.organization.id != l.id
        );

        tempClients = tempClients.filter(
          (el) => l.clients.findIndex((li) => li.client.id == el.id) > -1
        );
      });

      if (tempLocations.length != editLocations.length) {
        setLocations(tempLocations);
      }

      setSelectedClients(tempClients);
    }

    setChecked(() => Object.assign([], right));
    setLeftList(() => Object.assign([], left));

    setSelectedOrg(() => Object.assign([], right));
  }

  function organizationWrapper(e) {
    if (isEdit) {
      handleEdit(e);
    } else {
      setSelectedOrg(() => e);
    }
  }

  return (
    <div>
      <h3 className="text-center">Organizations</h3>
      <TransferList
        dataList={isEdit ? leftList : organizations}
        displayParam={"name"}
        isEdit={isEdit}
        checkedProp={checked}
        rightList={organizationWrapper}
      />
      <Stats step={2} {...props} previousStep={validate} />
    </div>
  );
}

function ClientSelection(props) {
  const validate = () => {
    props.previousStep();
  };

  const [checked, setChecked] = useState();
  const [leftList, setLeftList] = useState([]);

  const {
    clients,
    setSelectedClients,
    isEdit,
    editLocations,
    selectedOrg,
    setLocations,
  } = props;

  useEffect(() => {
    // Default Handle Edit
    if (isEdit) {
      handleEdit();
    }
  }, [selectedOrg, clients, editLocations]);

  function handleEdit(test) {
    var right = [];
    var left = test == undefined ? [] : clients;

    if (test == undefined) {
      clients.map((client) => {
        client.client.client_locations.map((cli) => {
          editLocations.map((el) => {
            if (
              el.client_location.id == cli.id &&
              right.findIndex((xy) => xy.id == client.id) == -1
            ) {
              right.push(client);
            }
          });
        });
      });

      clients.map((cli) => {
        var add = true;

        right.map((ri) => {
          if (ri.id == cli.id) {
            add = false;
          }
        });

        if (add) {
          left.push(cli);
        }
      });
    } else {
      right = right.concat(test);

      right.map((t) => {
        left = left.filter((x) => x.id != t.id);
      });

      var tempLocations = Object.assign([], editLocations);

      left.map((l) => {
        tempLocations = editLocations.filter(
          (el) => el.client_location.client.id != l.client.id
        );
      });

      if (tempLocations.length != editLocations.length) {
        setLocations(tempLocations);
      }
    }

    setChecked(Object.assign([], right));
    setLeftList(Object.assign([], left));

    if (test == undefined) {
      setSelectedClients(right);
    }
  }

  function clientWrapper(e) {
    if (isEdit) {
      handleEdit(e);
    }

    setSelectedClients(e);
  }

  return (
    <div>
      <h3 className="text-center">Clients</h3>
      <TransferList
        dataList={isEdit ? leftList : clients}
        displayParam={"client.name"}
        isEdit={isEdit}
        checkedProp={checked}
        rightList={clientWrapper}
      />
      <Stats step={3} {...props} previousStep={validate} />
    </div>
  );
}

function LocationSelection(props) {
  const validate = () => {
    props.previousStep();
  };

  const [checked, setChecked] = useState();
  const [leftList, setLeftList] = useState([]);

  const {
    editLocations,
    setLocations,
    isEdit,
    locations,
    selectedClients,
    setSelectedLocations,
    finalize,
    selectedLocations,
  } = props;

  useEffect(() => {
    Log("Init Locations");
    if (isEdit) {
      handleEdit();
    }
  }, [selectedClients, locations, editLocations]);

  function handleEdit(test) {
    Log("Handle Edit Method");
    var right = [];
    var left = test == undefined ? [] : locations;

    if (test == undefined) {
      editLocations.map((el) => {
        var tempLoc = locations.find((x) => x.id == el.client_location.id);

        if (tempLoc != undefined) {
          right.push(tempLoc);
        }
      });
      locations.map((loc) => {
        var tempLoc = right.find((x) => x.id == loc.id);

        if (tempLoc == undefined) {
          left.push(loc);
        }
      });
    } else {
      right = right.concat(test);

      right.map((t) => {
        left = left.filter((x) => x.id != t.id);
      });

      var tempLocations = Object.assign([], editLocations);

      left.map((l) => {
        tempLocations = editLocations.filter(
          (el) => el.client_location.client.id != l.id
        );
      });

      //setLocations(tempLocations);
    }

    if (test == undefined) {
      setSelectedLocations(editLocations);
    }

    setChecked(right);

    setLeftList(left);
  }

  function locationWrapper(e) {
    if (isEdit) {
      handleEdit(e);
    }

    setSelectedLocations(e);
  }

  return (
    <div>
      <h3 className="text-center">Locations</h3>
      <TransferList
        dataList={isEdit ? leftList : locations}
        displayParam={"name"}
        isEdit={isEdit}
        checkedProp={checked}
        rightList={locationWrapper}
      />
      <span>
        <Stats
          step={4}
          {...props}
          previousStep={validate}
          nextStep={finalize}
        />
      </span>
    </div>
  );
}

const Stats = ({
  currentStep,
  firstStep,
  goToStep,
  lastStep,
  nextStep,
  previousStep,
  totalSteps,
  step,
  validate,
}) => (
  <div style={{ bottom: "0" }}>
    <hr />
    {step < totalSteps ? (
      <button
        style={{ float: "right", bottom: "0" }}
        className="btn btn-primary btn-block"
        onClick={() => {
          if (validate != undefined && validate()) {
            nextStep();
          } else if (validate == undefined) {
            nextStep();
          }
        }}
      >
        Continue
      </button>
    ) : (
      <button
        style={{ float: "right" }}
        className="btn btn-success btn-block"
        onClick={nextStep}
      >
        Finish
      </button>
    )}
    {step > 1 && (
      <button
        style={{ float: "right" }}
        className="btn btn-default btn-block"
        onClick={previousStep}
      >
        Go Back
      </button>
    )}
  </div>
);
