import { Input } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheck, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import api from "../../service/api";
import { ErrorLog } from "../../helpers/logs";
import $ from "jquery";

import InputMask from "react-input-mask";

import Tooltip from "@mui/material/Tooltip";
import UserContext from "../../context/user_context";

import { IsAuthorize, IsOrder, IsAdmin } from "../../helpers/helper_methods";

export default function ButtonCell(props) {
  const {
    label,
    me,
    handleSetCart,
    patient,
    selected,
    orderable,
    row,
    rc,
    location,
    orderableTest,
    registry,
    registryComparitor,
    patientView,
  } = props;

  const currentUser = useContext(UserContext);

  const [loading, setLoading] = useState(null);
  const [inputVar, setInputVar] = useState(me.input_value);
  const [timer, setTimer] = useState(null);
  const [time, setTime] = useState(5000);

  const [disableInput, setDisableInput] = useState(false)

  function handleInputChange(e) {
    if (rc.registry_test.editable_type.includes("Date")) {
      if (disableInput) {
        return;
      }

      var valDate = new Date(e.target.value);
      var currentDate = new Date();

      if (valDate < currentDate) {
        setDisableInput(true);
        setLoading(() => true);  
      }

      setInputVar(() => e.target.value);
    } else {
      setLoading(() => true);
      setInputVar(() => e.target.value);
    }
  }

  useEffect(() => {
    if (loading == true) {
      if (timer != null) {
        setTimer(() => clearTimeout(timer));

        setTimer(() => setTimeout(() => handleResultUpdate(), time));
      } else {
        setTimer(() => clearTimeout(timer));

        setTimer(() => setTimeout(() => handleResultUpdate(), time));
      }
    }
  }, [inputVar]);

  function handleResultUpdate() {
    // Handle Setting the variable;
    var testResultChangeBody = {
      result: inputVar,
      client_location_id: location.id,
      patient_id: patient.id,
    };

    api.IConnectApi.updateTestResultValue(me.id, testResultChangeBody)
      .then(() => {
        setLoading(() => false);
        setDisableInput(false);
        setTimer(() => clearTimeout(timer));
      })
      .catch((er) => {
        setLoading(() => false);
        setDisableInput(false);
        setTimer(() => clearTimeout(timer));

        ErrorLog("Er: ", er);
      });
  }

  function toggle() {
    var registryItem = JSON.parse(localStorage.getItem(registry.id));

    if (
      selected &&
      registryItem != undefined &&
      registryItem != null &&
      JSON.stringify(registryItem) == JSON.stringify(registryComparitor)
    ) {
      localStorage.removeItem(registry.id);
    }

    handleSetCart(row.physician, me, patient, orderableTest);
  }

  me.toggle = toggle;

  var buttonClass = "";
  var selectClass = "";
  var btnDisabled = false;
  var inputMask = "";

  switch (me.criticality) {
    case "10":
      buttonClass = "test-required";
      selectClass = "test-none";
      break;
    case "5":
      buttonClass = "test-optional";
      selectClass = "test-none";
      break;
    case "1":
      buttonClass = "test-none";
      selectClass = "test-none";
      break;
    case "0":
      buttonClass = "test-optional-zero";
      selectClass = "test-none";
      break;
    case "-1":
      buttonClass = "test-none";
      selectClass = "test-none";
      break;
    default:
      buttonClass = "test-none";
      selectClass = "test-none";
      break;
  }

  var inputType = "";
  
  if (
    rc.registry_test.editable_type &&
    rc.registry_test.editable_type.includes("Time")
  ) {
    inputMask = "99:99";
  } else if (
    rc.registry_test.editable_type &&
    rc.registry_test.editable_type.includes("Date")
  ) {
    inputMask = "99-99-9999";
  } else if (
    rc.registry_test.editable_type &&
    rc.registry_test.editable_type == "Numeric"
  ) {
    inputType = "number";
  }

  var editable = rc.registry_test.editable;

  if (editable == undefined || patientView || !IsOrder(currentUser)) {
    editable = false;
  } else {
    editable = rc.registry_test.editable && location.allow_to_edit;
  }

  if (orderable && !editable && IsOrder(currentUser)) {
    buttonClass = "test-none";
    buttonClass = "test-none test-border";
    selectClass = "test-picked";
  }

  if (editable) {
    return (
      <Tooltip title={label}>
        <button
          type="button"
          key={selected + me.id}
          className={buttonClass}
          style={{ borderRadius: `5px` }}
          onClick={(e) => {
            if (inputVar == null) {
              setInputVar(() => "");
            }
            $(`#${me.id}${rc.id}`).focus();
          }}
          disabled={btnDisabled}
          aria-label={label}
        >
          {inputVar != null ? (
            <Row>
              <Col lg={loading != null ? 8 : 12}>
                {inputMask != "" ? (
                  <InputMask
                    mask={inputMask}
                    value={inputVar}
                    onChange={handleInputChange}
                    style={buttonClass == "test-none" ? { color: "black" } : { color: `white` }}
                    disabled={disableInput}
                  >
                    {() => (
                      <Input
                        value={inputVar}
                        onChange={handleInputChange}
                        style={buttonClass == "test-none" ? { color: "black" } : { color: `white` }}
                        type={inputType}
                        
                      />
                    )}
                  </InputMask>
                ) : (
                  <Input
                    value={inputVar}
                    onChange={handleInputChange}
                    style={buttonClass == "test-none" ? { color: "black" } : { color: `white` }}
                    type={inputType}
                    disabled={disableInput}
                  />
                )}
              </Col>
              <Col style={loading != null ? {} : { display: "none" }} lg={4}>
                <FontAwesomeIcon
                  icon={loading ? faSpinner : faCheck}
                  spin={loading}
                />
              </Col>
            </Row>
          ) : label == null ? (
            <Row>
              <Col lg={8}>-</Col>
              <Col lg={4}>
                <FontAwesomeIcon icon={faEdit} spin={loading} />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg={8}>{label}</Col>
              <Col lg={4}>
                <FontAwesomeIcon icon={faEdit} spin={loading} />
              </Col>
            </Row>
          )}
        </button>
      </Tooltip>
    );
  } else {
    return (
      <button
        type="button"
        key={selected + me.id}
        className={
          orderable ? (selected ? selectClass : buttonClass) : buttonClass
        }
        onClick={(e) => {
          if (selectClass == "test-picked" && orderable) {
            toggle();
          }
        }}
        style={!orderable || !IsOrder(currentUser) ? { cursor: "default" } : {}}
        disabled={btnDisabled}
        aria-label={label}
      >
        {editable && inputVar != null ? (
          <Row>
            <Col lg={loading != null ? 8 : 12}>
              <Input
                onChange={handleInputChange}
                value={inputVar}
                style={buttonClass == "test-none" ? { color: "black" } : { color: `white` }}
              />
            </Col>
            <Col style={loading != null ? {} : { display: "none" }} lg={4}>
              <FontAwesomeIcon
                icon={loading ? faSpinner : faCheck}
                spin={loading}
              />
            </Col>
          </Row>
        ) : label == null ? (
          <Row>
            <Col lg={loading != null ? 8 : 12}>
              <span>-</span>
            </Col>
            <Col style={loading != null ? {} : { display: "none" }} lg={4}>
              <FontAwesomeIcon
                icon={loading ? faSpinner : faCheck}
                spin={loading}
              />
            </Col>
          </Row>
        ) : (
          label
        )}
      </button>
    );
  }
}
/*

*/
