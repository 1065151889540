import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/user_context";
import TabView from "./tab_view";
import { Row, Col, Form } from "react-bootstrap";
import { Ibox } from "../common/ibox";

// Renderable imports
import { PatientDetailSection } from "./patient_details_section";
import { RegistryTable } from "./registry_table";
import RegistryDropdown from "./registry_dropdowns";
import PatientDetailsModal from "./patient_details_modal";

import PropTypes from "prop-types";
import api from "../../service/api";
import { DebugLog, ErrorLog, Log } from "../../helpers/logs";
import { handleMakeCartObject } from "../../helpers/helper_methods";

import EpisodeDocumentModal from "./episode_document_modal";

/*
    RegistryViews Function

    Handles the logic between changing between the 
    render options

    Author: William Brown
    Updated By: 
*/
export default function RegistryViews(props) {
  // Variables used to denote wheter the table is being used for high criticality or not
  const {
    highCriticality,
    dashbaord,
    setSelectedEpisode,
    selectedEpisode,
    registry,
    location,
    patientView,
  } = props;

  // Views default being set by user preferences
  var currentUser = useContext(UserContext);
  const { user_preference } = currentUser;

  const { activeTab, patientData } = props;

  // Temp Variables to set to test the tables capabilities
  const [isTabView, setIsTabView] = useState(
    user_preference.enable_tab_view != null
      ? user_preference.enable_tab_view
      : true
  );
  const [fullTable, setFullTable] = useState(
    user_preference.enable_patient_table != undefined
      ? user_preference.enable_patient_table
      : false
  );
  const [showModal, setShowModal] = useState(false);
  const [cart, setLocalCart] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState({});
  const [reset, setReset] = useState(false);

  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [document, setDocument] = useState("");

  function setDocumentWrapper(e) {
    setDocument(e);

    setShowDocumentModal(() => !showDocumentModal);
  }

  useEffect(() => {
    if (!showModal) {
      props.getPatientsByRegistry(props.registry.id);
    }
  }, [showModal]);

  function handleSelectAllByRegistry() {
    api.Cart.addAllOrderableTests(
      registry.id,
      location.id,
      highCriticality != undefined
    )
      .then((response) => {
        Log("Select All Response: ", response);

        setLocalCart(response.user.user_cart);
      })
      .catch((er) => {
        ErrorLog("Er: ", er);
      });
  }

  function setCart(e) {
    api.Cart.setCart(handleMakeCartObject(e))
      .then((response) => {
        DebugLog("Cart Set: ", response);

        setLocalCart(response.user_cart);
      })
      .catch((er) => {
        ErrorLog("Er: ", er);
      });
  }

  function getCart() {
    api.Cart.getCart()
      .then((response) => {
        Log("Get Cart Response: ", response);

        setLocalCart(response);
      })
      .catch((er) => {
        ErrorLog("Er: ", er);
      });
  }

  function setShowModalWrapper(se) {
    if (!showModal) {
      setSelectedEpisode(se);
    } else {
      setSelectedEpisode({});
    }

    setShowModal(!showModal);
  }

  function handleSetCart(physician, testResult, patient, orderableTest, toAdd) {
    var varToSet = Object.assign([], cart);

    if (
      cart.find((x) => {
        return (
          (x.patientId == patient.id && x.testCode == orderableTest.id) ||
          (x.patient_id == patient.id &&
            x.orderable_test.test.code == orderableTest.test.code)
        );
      }) &&
      !(toAdd != undefined && toAdd)
    ) {
      varToSet.splice(
        [
          varToSet.findIndex(
            (x) =>
              (x.patientId == patient.id && x.testCode == orderableTest.id) ||
              (x.patient_id == patient.id &&
                x.orderable_test.test.code == orderableTest.test.code)
          ),
        ],
        1
      );
    } else if (
      !cart.find(
        (x) =>
          (x.patientId == patient.id && x.testCode == orderableTest.id) ||
          (x.patient_id == patient.id &&
            x.orderable_test.test.code == orderableTest.test.code)
      )
    ) {
      varToSet.push({
        registry: registry,
        testCode: orderableTest.id,
        patientId: patient.id,
        patient: patient,
        test: orderableTest,
        physician: physician,
        location: location,
        icd_codes: orderableTest.icd_code,
      });
    }

    setCart(varToSet);
  }

  function handleSetRow(e) {
    var varToSet = Object.assign([], cart);

    e.map((v) => {
      let { physician, patient, orderableTest, toAdd } = v;

      if (
        cart.find((x) => {
          return (
            (x.patientId == patient.id && x.testCode == orderableTest.id) ||
            (x.patient_id == patient.id &&
              x.orderable_test.test.code == orderableTest.test.code)
          );
        }) &&
        !(toAdd != undefined && toAdd)
      ) {
        varToSet.splice(
          [
            varToSet.findIndex(
              (x) =>
                (x.patientId == patient.id && x.testCode == orderableTest.id) ||
                (x.patient_id == patient.id &&
                  x.orderable_test.test.code == orderableTest.test.code)
            ),
          ],
          1
        );
      } else if (
        !cart.find(
          (x) =>
            (x.patientId == patient.id && x.testCode == orderableTest.id) ||
            (x.patient_id == patient.id &&
              x.orderable_test.test.code == orderableTest.test.code)
        )
      ) {
        varToSet.push({
          registry: registry,
          testCode: orderableTest.id,
          patientId: patient.id,
          patient: patient,
          test: orderableTest,
          physician: physician,
          location: location,
          icd_codes: [],
        });
      }
    });

    setCart(varToSet);
  }

  useEffect(() => {
    getCart();
  }, []);

  useEffect(() => {
    if (patientData.length > 0) {
      setSelectedPatient(patientData[0]);
    }
  }, [patientData]);

  var regsitryTableProps = {
    ...props,
    registry_columns: props.columns,
    fullTable: !fullTable,
    selectedPatient,
    setSelectedPatient,
    reset,
    setReset,
    showModal,
    setShowModal: setShowModalWrapper,
    cart,
    setCart,
    handleSetCart,
    handleSetRow,
    handleSelectAllByRegistry,
    setLocalCart,
    patientData: props.patientData,
    setDocument: setDocumentWrapper,
  };

  return (
    <Row>
      {patientView == true ? (
        <PatientDetailsModal
          showPatientModal={showModal}
          setShowPatientModal={setShowModalWrapper}
          selectedEpisode={selectedEpisode}
          location={location}
          registry={registry}
        />
      ) : null}
      {showDocumentModal ? (
        <EpisodeDocumentModal
          showDocumentModal={showDocumentModal}
          setShowDocumentModal={setDocumentWrapper}
          document={document}
          setDocument={setDocument}
        />
      ) : null}

      <Col
        style={
          highCriticality != undefined && dashbaord != undefined
            ? { display: `none` }
            : {}
        }
        sm={fullTable ? "0" : "12"}
      >
        <Ibox enableTitleBar title={`Registries Filter`} id="registries_filter">
          <RegistryDropdown
            isTabView={isTabView}
            {...props}
            setDocument={setDocumentWrapper}
          />
        </Ibox>
      </Col>
      <Col sm={fullTable ? "9" : "12"}>
        <Ibox enableTitleBar title={`Registries Table`} id="registries">
          <Row style={{ marginTop: "1em" }}>
            <Col lg={12}>
              {isTabView || (dashbaord != undefined && dashbaord) ? (
                <TabView {...regsitryTableProps} />
              ) : (
                <RegistryTable {...regsitryTableProps} />
              )}
            </Col>
          </Row>
        </Ibox>
      </Col>
      {!fullTable ? null : <PatientDetailSection {...regsitryTableProps} />}
    </Row>
  );
}

RegistryViews.propTypes = {
  highCriticality: PropTypes.bool,
  dashbaord: PropTypes.bool,
  patientData: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  selectedPatient: PropTypes.object,
  setSelectedPatient: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  registry: PropTypes.object.isRequired,
};

RegistryViews.defaultProps = {
  highCriticality: undefined,
  dashbaord: undefined,
};
