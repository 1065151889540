import React, { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";

import { DropdownComponent } from "../dropdown_component";

import { Row, Col, FormLabel } from "react-bootstrap";

import api from "../../../service/api";
import { ErrorLog, Log } from "../../../helpers/logs";

import $ from "jquery";

export default function PreferencesModal(props) {
  const { show, hide, currentUser, InitializeUser } = props;
  const { user_preference } = currentUser;

  const [registries, setRegistries] = useState([]);
  const [locations, setLocations] = useState([]);

  // Default the form values
  const [enableTabView, setEnableTabView] = useState(
    user_preference.enable_tab_view
  );
  const [enableTableType, setEnableTableType] = useState(
    user_preference.enable_patient_table == undefined
      ? false
      : user_preference.enable_patient_table
  );
  const [preferredLocation, setPreferredLocation] = useState({
    value: user_preference.preferred_client_id,
    label: "Select a Location",
  });
  const [preferredRegistry, setPreferredRegistry] = useState({
    value: user_preference.preferred_registry_id,
    label: "Select a Registry",
  });

  Log("Current User Pref Modal: ", currentUser);

  useEffect(() => {
    if (show) {
      getData();
    }
  }, [show]);

  async function getData() {
    Log("Retrieving preference data...");

    // Currently disabled because low data causes the screen to flash too quickly between
    // the loader an the modal
    //$(`#loader`).show();

    await getLocations();

    await getRegistries();

    //$(`#loader`).hide();
  }

  async function getLocations() {
    await api.IConnectApi.getLocations()
      .then((response) => {
        Log(`Locations Response: `, response);
        var cli = [];

        cli = response.client_locations.map((cl) => {
          cl.label = cl.name;
          cl.value = cl.id;

          return cl;
        });

        if (preferredLocation.value != null) {
          var tempLocation = Object.assign(
            {},
            cli.find((x) => x.id == preferredLocation.value)
          );
          tempLocation.value = tempLocation.id;
          tempLocation.label = tempLocation.name;

          setPreferredLocation(tempLocation);
        }

        setLocations(cli);
      })
      .catch((er) => {
        ErrorLog(`Er: `, er);
      });
  }

  async function getRegistries() {
    await api.IConnectApi.GetAllRegistries()
      .then((response) => {
        Log("registries retrieved: ", response);
        var reg = [];

        reg = response.registries.map((rg) => {
          rg.label = rg.name;
          rg.value = rg.id;

          return rg;
        });

        if (preferredRegistry.value != null) {
          var tempRegistry = Object.assign(
            {},
            reg.find((x) => x.id == preferredRegistry.value)
          );

          tempRegistry.value = tempRegistry.id;
          tempRegistry.label = tempRegistry.name;

          setPreferredRegistry(tempRegistry);
        }

        setRegistries(reg);
      })
      .catch((er) => {
        ErrorLog("Er: ", er);
      });
  }

  function handleSubmit() {
    var newPreferences = {
      enable_tab_view: enableTabView,
      preferred_client_id: preferredLocation.value,
      preferred_physician_id: null,
      preferred_registry_id: preferredRegistry.value,
      enable_patient_table: enableTableType,
    };

    api.UserPrefernces.updateUserPreferences(newPreferences)
      .then((response) => {
        Log("User Preferences Response: ", response);

        hide();

        InitializeUser();
      })
      .catch((er) => {
        ErrorLog("Er: ", er);
      });
  }

  function handleClose() {
    hide();
  }

  return (
    <div>
      <Dialog
        lg={{
          "& .MuiDialog-paper": {
            maxWidth: "40%",
            height: `50% !important`,
            maxHeight: `50% !important`,
          },
        }}
        maxWidth="lg"
        open={show}
        onClose={hide}
        fullWidth
      >
        <DialogTitle>User Preferences</DialogTitle>
        <DialogContent
          style={{
            height: `25rem`,
            maxHeight: `25rem`,
            overflowY: `inherit !important`,
          }}
        >
          <ModalBody
            registries={registries}
            locations={locations}
            enableTabView={enableTabView}
            setEnableTabView={setEnableTabView}
            preferredLocation={preferredLocation}
            setPreferredLocation={setPreferredLocation}
            preferredRegistry={preferredRegistry}
            setPreferredRegistry={setPreferredRegistry}
            enableTableType={enableTableType}
            setEnableTableType={setEnableTableType}
          />
        </DialogContent>
        <ModalFooter
          handleClose={handleClose}
          hide={hide}
          handleSubmit={handleSubmit}
        />
      </Dialog>
    </div>
  );
}

function ModalBody(props) {
  const {
    registries,
    locations,
    enableTabView,
    setEnableTabView,
    preferredLocation,
    setPreferredLocation,
    preferredRegistry,
    setPreferredRegistry,
    enableTableType,
    setEnableTableType,
  } = props;

  return (
    <Row>
      <Col lg={6} style={{ marginTop: `2rem` }}>
        <FormLabel>Preferred Location</FormLabel>
        <DropdownComponent
          id="dropdown_component_id_clients"
          propkey="dropdown_component_id_clients"
          dropdownArray={locations}
          handleChangeFunc={setPreferredLocation}
          selectedOption={preferredLocation}
          searchable={true}
          placeholder="Preferred Location"
          isMulti={false}
          isDisabled={false}
          autoFocus={false}
        />
      </Col>
      <Col lg={6} style={{ marginTop: `2rem` }}>
        <FormLabel>Preferred Registry</FormLabel>
        <DropdownComponent
          id="dropdown_component_id_registries"
          propkey="dropdown_component_id_registries"
          dropdownArray={registries}
          handleChangeFunc={setPreferredRegistry}
          selectedOption={preferredRegistry}
          searchable={true}
          placeholder="Preferred Registry"
          isMulti={false}
          isDisabled={false}
          autoFocus={false}
        />
      </Col>
      <Col lg={6} style={{ marginTop: `2rem` }}>
        <FormGroup style={{ zIndex: `999999999999 !important` }}>
          <FormControlLabel
            control={<Switch />}
            label="Registry Tab View"
            aria-label={`Registry Tab View`}
            checked={enableTabView}
            onChange={() => setEnableTabView(!enableTabView)}
          />
        </FormGroup>
      </Col>
      <Col lg={6} style={{ marginTop: `2rem` }}>
        <FormGroup style={{ zIndex: `999999999999 !important` }}>
          <FormControlLabel
            control={<Switch />}
            label="Registry Table Patient View"
            aria-label={`Registry Table Patient View`}
            checked={enableTableType}
            onChange={() => setEnableTableType(!enableTableType)}
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

function ModalFooter(props) {
  const { handleClose, hide, handleSubmit } = props;

  return (
    <DialogActions>
      <Button onClick={hide}>Cancel</Button>
      <Button onClick={handleSubmit}>Submit</Button>
    </DialogActions>
  );
}
