import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Ibox } from "../common/ibox";
import ButtonCell from "../common/button_cell";
import { TabTableDetails } from "../layouts/_clients/tab_table_details";
import { Log } from "../../helpers/logs";
import moment from "moment";

// Cart Context: This is what handles the cart variables
import CartContext from "../../context/cart_context";
import { makeid } from "../../helpers/helper_methods";

/*
    PatientDetailSection Component

    handles the rendering of the patient details section on the
    right side of the registry page when the table type is 
    set to !fullTable

    Author: William Brown
    Updated By: 
*/
export function PatientDetailSection(props) {
  const {
    fullTable,
    selectedPatient,
    columns,
    location,
    registry,
    handleSetCart,
    physician,
    highCriticality,
    setSelected,
    cart,
    patientView,
  } = props;

  const [reset, setReset] = useState(false);

  const clientDetails = {
    name: "Tellus Institute 2x",
    description:
      "Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC.This book is a treatise on.",
    notes:
      "Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non- characteristic words etc.",
    lastActivity: [
      {
        id: 1,
        description: "Signed off",
        time: "09:00 PM",
        first: true,
      },
      {
        id: 2,
        description: "Signed on",
        time: "08:03 PM",
      },
      {
        id: 3,
        description: "Signed off",
        time: "08:02 PM",
      },
      {
        id: 4,
        description: "Signed on",
        time: "08:01 PM",
      },
    ],
    timelineActivity: [
      {
        id: 1,
        description: "Conference on the sales results for the previous year.",
        time: "2:10 pm - 12.06.2014.",
        icon: "coffee",
      },
      {
        id: 2,
        description:
          "Many desktop publishing packages and web page editors now use Lorem.",
        time: "4:20 pm - 10.05.2014",
        icon: "circle",
      },
    ],
  };

  var patient = {};

  if (
    !(
      selectedPatient != undefined &&
      selectedPatient.episode != undefined &&
      selectedPatient.patient.id != undefined
    )
  ) {
    return null;
  } else {
    patient = selectedPatient.patient;
  }

  function TestButton(rc, rowData, patient) {
    var resultObj = rowData;
    var displayLabel = null;

    if (rowData.test_results.length > 0) {
      rowData.test_results.map((tr) => {
        if (tr.test.code == rc.registry_test.test.code) {
          resultObj = tr;

          if (rc.registry_test.orderable) {
            displayLabel = (
              <>
                <Row className="justify-content-md-center">
                  <Col>
                    <span>{tr.test.code}</span>
                  </Col>
                </Row>
              </>
            );
          } else if (!(tr.last_value == "" && tr.last_date == null)) {
            displayLabel = (
              <>
                <Row className="justify-content-md-center">
                  <Col>
                    <span>{tr.last_value}</span>
                  </Col>
                </Row>
                {tr.last_date != null ? (
                  <Row>
                    <Col>
                      <small>{moment(tr.last_date).format("L")}</small>
                    </Col>
                  </Row>
                ) : null}
              </>
            );
          }
        }
      });
    }

    var selected = false;

    rowData.orderable_tests.map((ot) => {
      cart.map((ci) => {
        if (
          ci.orderable_test_id == ot.id &&
          ot.test.code == rc.registry_test.test.code
        ) {
          selected = true;
        }
      });
    });

    var orderable = false;
    var orderableTest = null;

    rowData.orderable_tests.map((ot) => {
      if (
        ot.test.code == rc.registry_test.test.code &&
        rc.registry_test.orderable
      ) {
        orderable = ot.currently_orderable;
        orderableTest = ot;
        displayLabel = ot.test.code;
      }
    });

    return (
      <ButtonCell
        me={rc.registry_test.orderable ? rc.registry_test : resultObj}
        row={rowData}
        rc={rc}
        label={displayLabel}
        handleSetCart={handleSetCart}
        patient={patient}
        selected={selected}
        orderable={rc.registry_test.orderable && orderable}
        location={location}
        orderableTest={orderableTest}
        registry={registry}
        registryComparitor={{
          highCriticality: highCriticality != undefined,
          location: location.id,
          physician: physician.value,
        }}
        setSelected={setSelected}
      />
    );
  }

  return (
    <Col style={fullTable ? { display: "none" } : null} sm="3">
      <Ibox enableTitleBar title={`Test Details`} id="client-details">
        <div className="tab-content">
          <div id="contact-1" className="tab-pane active">
            <div className="row m-b-lg">
              <div className="col-lg-12 text-center">
                <h3>{patient.name}</h3>
              </div>
              <div className="col-lg-6 text-center">
                <h3>DOB: {patient.dob}</h3>
              </div>
              <div className="col-lg-6 text-center">
                <h3>Sex: {patient.sex}</h3>
              </div>
            </div>
            <div className="client-detail">
              <Row className={`scrollSection`}>
                {columns.map((column, index) => {
                  if (column.active && column.registry_test != null) {
                    if (
                      column.registry_test.orderable &&
                      patientView != undefined &&
                      patientView
                    ) {
                      return;
                    }

                    return (
                      <Col
                        key={
                          column.display_name +
                          "_patient_details_section" +
                          makeid(10)
                        }
                        md={6}
                        className={`colHeightAdjust`}
                      >
                        <h4 className={`h4HeightAdjust`}>{column.name}</h4>
                        {TestButton(
                          column,
                          selectedPatient.episode,
                          selectedPatient.patient
                        )}
                      </Col>
                    );
                  }
                })}
              </Row>
              <div
                style={{ marginTop: `1em`, display: "none" }}
                className="full-height-scroll"
              >
                <strong>Last activity</strong>

                <ul className="list-group clear-list">
                  <li className="list-group-item fist-item">
                    <Row>
                      <Col lg={4}>eGFR</Col>
                      <Col lg={4}>15ml</Col>
                      <Col lg={4}>10/08/2022</Col>
                    </Row>
                  </li>
                  <li className="list-group-item fist-item">
                    <Row>
                      <Col lg={4}>Microalbumin</Col>
                      <Col lg={4}>13ml</Col>
                      <Col lg={4}>10/08/2022</Col>
                    </Row>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <TabTableDetails id="1" details={clientDetails} />
        </div>
      </Ibox>
    </Col>
  );
}
