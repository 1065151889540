import React, { useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { Button, Tooltip } from "@mui/material";

import Swal from "sweetalert2";
import api from "../../service/api";
import { ErrorLog } from "../../helpers/logs";

export default function UserTable(props) {
  const { users, setShowUpdateModal, getUsers } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  if (users.length == 0) return null;

  function toggleConfirmation(row) {
    Swal.fire({
      title: `${row.active ? `Deactivate User?` : `Activate user?`}`,
      icon: `info`,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: `Confirm`,
    }).then((result) => {
      if (result.isConfirmed) {
        api.Users.toggleUser(row.id, !row.active)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "User has been updated",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              getUsers();
            });
          })
          .catch((er) => {
            ErrorLog("Er: ", er);
          });
      }
    });
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Activate/Deactivate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <Tooltip
                  key={row.id + `_user_table`}
                  title={`Double click to edit user`}
                >
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    style={{ cursor: "pointer" }}
                    hover
                    onDoubleClick={() => setShowUpdateModal(row)}
                  >
                    <TableCell component="th" scope="row">
                      {`${row.first_name} ${row.last_name}`}
                    </TableCell>
                    <TableCell>{row["e-mail"]}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color={row.active ? `primary` : `error`}
                        style={{ minWidth: `4rem` }}
                        onClick={() => toggleConfirmation(row)}
                      >
                        {row.active ? (
                          <span>Active</span>
                        ) : (
                          <span>Inactive</span>
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                </Tooltip>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        showFirstButton
        showLastButton
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
