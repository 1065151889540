import React, { useEffect, useState } from "react";

import Swal from "sweetalert2";
import Api from "../../service/api";
import Cookies from "js-cookie";
import { getAuth0TokenObject } from "../../service/_api_helper";
import { OktaAuth } from "@okta/okta-auth-js";
import { ServerStatusIcon } from "../common/server_status_icon";
import swal from "sweetalert";
import oktaConfig from "../../oktaConfig"; // refactor
import { Log, DebugLog, ErrorLog } from "../../helpers/logs";

const authClient = new OktaAuth(oktaConfig.oidc);

var moment = require("moment");

export const Footer = (props) => {
  const [apiServerStatus, setApiServerStatus] = useState({});
  const [online, setOnline] = useState(false);
  const [count, setCount] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [alertShown, setAlertShown] = useState(false);
  const refreshRate = process.env.REACT_APP_SESSION_REFRESH_RATE || 4;
  const FIVE_MINUTES_IN_EPOCH = 5 * 60 * 1000;
  const sessionExpFiveMinAfter = Date.now() + FIVE_MINUTES_IN_EPOCH;

  const [version, setVersion] = useState("");
  const [backendEnvironment, setBackendEnvironment] = useState("");

  useEffect(() => {
    setTimeLeft(Math.floor(compareDates(getTimeleft())));
    getVersion();

    // Alert when about to timeout
    const timer = setInterval(() => {
      DebugLog("RefreshAlert");
      setSessionRefreshAlert();
    }, 3600 * refreshRate);

    getApiVersion();

    return () => {
      DebugLog("RefreshAlert - Cleared");
      clearTimeout(timer);
    };
  }, []);

  const refreshAlert = () => {
    const refreshRate = process.env.REACT_APP_SESSION_REFRESH_RATE || 4;
    // Alert when about to timeout
    const timer = setInterval(() => {
      DebugLog("RefreshAlert");
      setSessionRefreshAlert();
    }, 3600 * refreshRate);
  };

  const compareDates = (dateTimeA) => {
    var now = moment(new Date());
    var duration = moment.duration(dateTimeA.diff(now));

    return duration.asMinutes();
  };

  const getTimeleft = () => {
    const isCookiePresent = Cookies.get("_session_exp") != null;

    if (isCookiePresent) {
      const obj = Cookies.get("_session_exp");
      return moment.unix(obj);
    } else {
      // const obj = getAuth0TokenObject();

      return moment.unix(sessionExpFiveMinAfter);
    }
  };

  const getVersion = () => {
    return Api.Status.get()
      .then((response) => {
        if (response.statusCode == 200) {
          setApiServerStatus(response.body);
          setOnline(true);
          setCount(0);
        }
      })
      .catch((e) => {
        ErrorLog(count);
        ErrorLog("Server down count: ", count);

        setCount(count + 1);
        setOnline(false);

        if (count == 2) {
          ErrorLog("Send Email alert - Server down");
          swal(
            `Server error - Trouble reaching server!`,
            "Please contact development and report error",
            "error"
          );
        }
      });
  };

  function getApiVersion() {
    Api.Status.getVersion()
      .then((response) => {
        setVersion(response.text);
      })
      .catch((er) => {
        Log("er: ", er);
      });

    Api.Status.getEnvironment()
      .then((response) => {
        setBackendEnvironment(response.text);
      })
      .catch((er) => {
        ErrorLog("Er: ", er);
      });
  }

  const setSessionRefreshAlert = () => {
    const timeLeft = compareDates(getTimeleft());
    setTimeLeft(Math.floor(timeLeft));

    if (timeLeft <= 0.5) {
      return (window.location = "/login");
    } else if (!alertShown && timeLeft > 0 && timeLeft < 2) {
      setAlertShown(true);
      showPopup();
    }
  };

  const showPopup = () => {
    //const { apiStack } = apiServerStatus;
    swal({
      title: `${timeLeft} minutes remaning`,
      text: "Would You like to refresh?",
      icon: "warning",
      buttons: ["Cancel", "Keep Session Active!"],
      dangerMode: false,
    }).then((value) => {
      DebugLog("Refreshed Session Clicked", value);
      if (value) {
        authClient.tokenManager.renew("idToken").then(function (newToken) {
          swal({
            title: `Session refreshed`,
            icon: "success",
            dangerMode: false,
          });
        });
      } else if (value) {
        Api.Auth.refreshToken();
      }
    });
  };

  const showVersion = () => {
    //const { apiStack } = apiServerStatus;
    var stackable = `
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Stack</th>
          <th scope="col">Version</th>
          <th scope="col">Environment</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Frontend</td>
          <td>${process.env.REACT_APP_APP_VERSION}</td>
          <td>${process.env.REACT_APP_ENVIRONMENT}</td>
        </tr>
        <tr>
          <td>Backend</td>
          <td>${version}</td>
          <td>${backendEnvironment}</td>
        </tr>
      </tbody>
    </table>
    `;

    /*
      <tr>
        <td>Backend</td>
        <td>${apiStack.version}</td>
      </tr>
    */

    Swal.fire({
      title: `App Versions`,
      html: stackable,
      buttons: ["OK"],
      icon: "info",
      dangerMode: false,
    });
  };

  return (
    <div className="footer fixed">
      <div className="pull-right mx-1">
        | <ServerStatusIcon online={online} count={count} />
      </div>
      <div className="pull-right cursor-pointer" onClick={() => showPopup()}>
        {timeLeft} Min of{" "}
        <strong>{process.env.REACT_APP_USER_TIMEOUT} Min</strong> Remaining.
      </div>
      <div style={{ cursor: "pointer" }} onClick={() => showVersion()}>
        <strong>
          {" "}
          &copy;{""}
          {new Date().getFullYear()}
        </strong>{" "}
        Sonic Healthcare USA
      </div>
    </div>
  );
};
