// Logging
export const Log = (...params) => {
    console.log(`[Info][${Date.now()}]: `, params);
  };
  
  export const DebugLog = ( ...params) => {
    console.debug(`[Debug][${Date.now()}]: `, params);
  };
  
  export const ErrorLog = (...params) => {
    console.error(`[Error][${Date.now()}]: `, params);
  };