import React, { useState, useEffect } from "react";
import RecentlyOrdered from "../components/dashboard_components/recently_ordered";
import { Ibox } from "../components/common/ibox";
import { CompleteSampleData } from "../test/refactored_data/pending_sample_data";
import Api from "../service/api";
import { Log } from "../helpers/logs";

export default function Orders(props) {
  const [orders, setOrders] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderBy] = useState("stratification.rank");
  const [order, setOrder] = useState("desc");
  const [filterText, setFilterText] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);

  const [textLoading, setTextLoading] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);
  const [time, setTime] = useState(2000);

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    getOrders();
  }, [rowsPerPage, page, order, orderBy]);

  useEffect(() => {
    if (textLoading == true) {
      if (timer != null) {
        setTimer(() => clearTimeout(timer));

        setTimer(() => setTimeout(() => getOrders(), time));
      } else {
        setTimer(() => clearTimeout(timer));

        setTimer(() => setTimeout(() => getOrders(), time));
      }
    }
  }, [filterText]);

  function filterTextWrapper(e) {
    setTextLoading(() => true);
    setFilterText(() => e);
  }

  function getOrders() {
    var body = {};

    body.pagination = true;
    body.sort_header = orderBy;
    body.sort_order = order;
    body.page_number = page + 1;
    body.page_size = rowsPerPage;
    body.filter_text = filterText;

    body.pending = false;

    setTextLoading(() => false);
    setLoading(() => true);

    Api.IConnectApi.getOrders(body)
      .then((response) => {
        setLoading(() => false);

        setOrders(response.result.data);
        setTotalRecords(response.result.total_records);
      })
      .catch((er) => {
        setLoading(() => false);
        Log("Er: ", er);
      });
  }

  const recentlyOrderedProps = {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    filterText,
    setFilterText: filterTextWrapper,
    totalRecords,
    loading: loading || textLoading,
  };

  return (
    <Ibox enableTitleBar title={`Recent Orders`} id={`recent_orders_view`}>
      <RecentlyOrdered
        data={orders}
        getOrders={getOrders}
        pending={false}
        {...recentlyOrderedProps}
      />
    </Ibox>
  );
}
