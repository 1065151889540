import React, { Component } from "react";
import Select from "react-select";
import PropTypes from "prop-types";

/*
  Generic drop down component that generates a dropdown element

  Required:
  
  an array of objects with value and label set.

  Ex object: 
  const options = [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' },
    ];

  On change function also required for functionality reasons. 
*/
export class DropdownComponent extends Component {
  render() {
    const {
      id,
      dropdownArray,
      handleChangeFunc,
      selectedOption,
      searchable,
      placeholder,
      isMulti,
      isDisabled,
      autoFocus
    } = this.props;
    return (
      <Select
        id={`dropdown_component_${id}`}
        value={selectedOption}
        onChange={handleChangeFunc}
        options={dropdownArray}
        isSearchable={searchable}
        placeholder={placeholder}
        isMulti={isMulti}
        isDisabled ={isDisabled}
        autoFocus={autoFocus}
      />
    );
  }
}

DropdownComponent.propTypes = {
  id: PropTypes.string.isRequired,
  propkey: PropTypes.string.isRequired,
  dropdownArray: PropTypes.array.isRequired,
  handleChangeFunc: PropTypes.func.isRequired,
  selectedOption: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  searchable: PropTypes.bool,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autoFocus: PropTypes.bool
};

DropdownComponent.defaultProps = {
  searchable: false,
  placeholder: "",
  isMulti: false,
  isDisabled: false,
  autoFocus: false
};