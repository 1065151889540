import React from "react";
import { Tabs, Tab, Form, Row, Col } from "react-bootstrap";
import { RegistryTable } from "./registry_table";
import PropTypes from "prop-types";

/*
    TabView Component

    Handles the rendering of the tab rednering of the registry tables.
    
    Author: William Brown
    Updated By: 
*/
export default function TabView(props) {
  const { registries, activeTab, setActiveTab, columns, fullTable, loading } =
    props;

  return (
    <Tabs
      defaultActiveKey="diabetes"
      id="uncontrolled-tab-example"
      className="mb-3"
      activeKey={activeTab}
      onSelect={(e) => {
        if (e != activeTab && !loading) {
          setActiveTab(() => e);
        }
      }}
      disabled={loading}
    >
      {registries.length > 0
        ? registries.map((registry, index) => {
            return (
              <Tab
                key={registry.code + index + "_tab_view_registries"}
                eventKey={registry.code}
                title={registry.name}
              >
                <Row>
                  <Col>
                    <RegistryTable
                      {...props}
                      registry_columns={columns}
                      fullTable={fullTable}
                    />
                  </Col>
                </Row>
              </Tab>
            );
          })
        : null}
    </Tabs>
  );
}

TabView.propTypes = {
  registries: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  patientData: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  fullTable: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  selectedPatient: PropTypes.object,
  setSelectedPatient: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  registry: PropTypes.object.isRequired,
};

TabView.defaultProps = {
  registries: [],
  activeTab: "",
  patientData: [],
  columns: [],
  fullTable: true,
};
