import React, { Component } from "react";
import { Ibox } from "../common";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

export class Wrapper extends Component {
  render() {
    const { children, animationType } = this.props;
    return (
      <div className={`wrapper wrapper-content animated ${animationType}`}>
        {children}
      </div>
    );
  }
}

// DO NOT DELETE OR MODIFY WITHOUT PERMISSION
Wrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
