import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import UserContext from "../../../context/user_context";

export function NavListItem(props) {
  function setTitle() {
    const { routeLink } = props;
    const title = routeLink.replace("/", "").capitalize();
    document.title = `${title} - ` + process.env.REACT_APP_APP_NAME;
  }

  const { children, icon, name, routeLink, permissions } = props;
  const { user } = useContext(UserContext);

  let activeStyle = {
    backgroundColor: "#00244d",
    color: "white",
  };

  /*

  if (permissions != undefined) {
    permissions.map((perm) => {
      const roles = user.roles;

      if (roles.find((x) => x.name == perm) == undefined) {
        return null;
      }
    });
  }

  */

  return (
    <li>
      <NavLink
        to={routeLink}
        onClick={setTitle.bind(this)}
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
      >
        {({ isActive }) => (
          <>
            {icon} <span className="nav-label">{name}</span>
            {children}
          </>
        )}
      </NavLink>
    </li>
  );
}
