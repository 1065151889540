import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import TextField from "@mui/material/TextField";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import api from "../../service/api";

import { useEffect } from "react";
import { ErrorLog } from "../../helpers/logs";
import $ from "jquery";

export default function EpisodeDocumentModal(props) {
  const { showDocumentModal, setShowDocumentModal, document, isClient } = props;

  const [file, setFile] = useState(null);

  useEffect(() => {
    getDocument();
  }, []);

  function getDocument() {
    $("#loader").show();
    api.Documents.getDocument(document.url)
      .then((response) => {
        $("#loader").hide();

        setFile(response.files[0]);
      })
      .catch((er) => {
        $("#loader").hide();
        ErrorLog("Er: ", er);
      });
  }

  return (
    <div>
      <Dialog
        open={showDocumentModal}
        lg={{
          "& .MuiDialog-paper": {
            maxWidth: "40%",
            height: 500,
            maxHeight: 500,
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Document</DialogTitle>
        <DialogContent style={{ height: `50rem` }}>
          <embed
            style={{ width: `100%`, height: `100%` }}
            src={`data:application/pdf;base64,${file}`}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant={`secondary`}
            onClick={() => {
              setShowDocumentModal(null);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
