import React, { useEffect, useContext, useState, useRef } from "react";
import $ from "jquery";
import { useNavigate, Navigate } from "react-router-dom";
import { IsOrder } from "../../helpers/helper_methods";

import { NavSearchBar } from "../common/nav";
import { smoothlyMenu } from "../layouts/helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlaskVial } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorLog } from "../../helpers/logs";
import api from "../../service/api";
import PreferencesModal from "./nav/preferences_modal";

import { redirectToLogin } from "../../service/_api_helper";

import Swal from "sweetalert2";

var moment = require("moment");

export const TopHeader = (props) => {
  const { currentUser, InitializeUser } = props;

  const { isAuthenticated, logout } = useAuth0();
  const { showMiniCart, toggleMiniCartFunc } = props;
  const [cart, setCart] = useState([]);
  const [reset, setReset] = useState(false);
  const [userPreferencesModal, setUserPreferencesModal] = useState(false);

  useEffect(() => {
    getCart();
  }, []);

  function getCart() {
    api.Cart.getCart()
      .then((response) => {
        setCart(response);
      })
      .catch((er) => {
        ErrorLog("Er: ", er);
      });
  }

  clearTimeout();

  let navigate = useNavigate();

  function handleMiniCartToggle(e) {
    getCart();
    props.toggleMiniCartFunc(e);
  }

  const toggleNavigation = (e) => {
    e.preventDefault();
    $("body").toggleClass("mini-navbar");
    smoothlyMenu();
  };

  const handleLogout = async () => {
    Swal.fire({
      title: "Logout from iConnect?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        redirectToLogin(logout);
      }
    });
  };

  function togglePreferencesModal() {
    setUserPreferencesModal(!userPreferencesModal);
  }

  return (
    <div className="row border-bottom">
      <PreferencesModal
        show={userPreferencesModal}
        hide={togglePreferencesModal}
        currentUser={currentUser}
        InitializeUser={InitializeUser}
      />
      <nav
        className="navbar navbar-static-top white-bg"
        role="navigation"
        style={{ marginBottom: 0 }}
      >
        <div className="navbar-header">
          <a
            className="navbar-minimalize minimalize-styl-2 btn btn-primary "
            onClick={toggleNavigation}
            href="#"
          >
            <i className="fa fa-bars" />{" "}
          </a>
          <NavSearchBar pageUrl="/search" />
        </div>
        <ul className="nav navbar-top-links navbar-right">
          <li
            id="orderCart"
            className="dropdown open"
            style={!IsOrder(currentUser) ? { display: "none" } : {}}
          >
            <a
              onClick={() => handleMiniCartToggle(!showMiniCart)}
              className="dropdown-toggle count-info"
              data-toggle="dropdown"
              href="#"
              aria-expanded="true"
            >
              <FontAwesomeIcon icon={faFlaskVial} />{" "}
              <span className="label label-primary">{cart.length}</span>
            </a>
            {showMiniCart == true ? (
              <TopHeaderOrderCart
                toggleMiniCartFunc={toggleMiniCartFunc}
                cart={cart}
                InitializeUser={props.InitializeUser}
              />
            ) : null}
          </li>
          <li>
            <a onClick={togglePreferencesModal}>
              <i className="fa fa-cog" />
            </a>
          </li>
          <li>
            <a href="#" onClick={handleLogout}>
              <i className="fa fa-sign-out" /> Log out
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export const TopHeaderOrderCart = (props) => {
  const { cart } = props;

  const handleClickToCart = () => {
    props.toggleMiniCartFunc(false);
    //navigate("/cart");
    window.location.replace("/cart");
    //navigate("/cart", { replace: true });
  };

  return (
    <ul className="dropdown-menu dropdown-messages animated slideInRight">
      {cart.length > 0 ? (
        cart.map((item, index) => {
          if (index < process.env.REACT_APP_MAX_ORDER_DISPLAY) {
            return <MiniCartItem item={item} key={`MiniCartItem-${index}`} />;
          }
        })
      ) : (
        <li>
          <div className="dropdown-messages-box">
            <div className="media-body">
              <span>No tests in cart</span>
            </div>
          </div>
        </li>
      )}
      {cart.length > 0 && (
        <li>
          <div className="text-center link-block">
            <a onClick={() => handleClickToCart()}>
              <small>
                Max {process.env.REACT_APP_MAX_ORDER_DISPLAY} displayed above:
              </small>
              <br />
              <FontAwesomeIcon icon={faFlaskVial} />{" "}
              <strong>Show all pending tests to order</strong>
            </a>
          </div>
        </li>
      )}
    </ul>
  );
};

export function MiniCartItem(props) {
  const { patient, client, orderable_test, test } = props.item;

  const age = moment(patient.dob).fromNow().split(" ")[0];

  return (
    <>
      <li>
        <div className="dropdown-messages-box">
          <div className="media-body">
            <strong>
              {patient.last_name}, {patient.first_name} {patient.middle_name}
            </strong>
            <span className="pull-right">
              Test:{" "}
              <strong>
                {test == undefined ? orderable_test.test.code : test.test.code}
              </strong>
            </span>
            <br />
            <small className="text-muted px-2 pull-right">Age: {age}</small>
            <small className="text-muted pull-right">DOB: {patient.dob}</small>
            {/*
                <small className="pull-right">
                  <Badge pill bg={criticalityColor} style={criticalityColor == "secondary" ? { color: "white" } : {}} title="criticality">
                    Criticality: {test.criticality}
                  </Badge>
                </small>
              */}
          </div>
        </div>
      </li>
      <li className="divider"></li>
    </>
  );
}
