import React, { Fragment, useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";

import TextField from "@mui/material/TextField";

import { makeid } from "../../helpers/helper_methods";
import { Col, Row } from "react-bootstrap";
import moment from "moment/moment";

import OrderDetailModal from "./modals/order_details_modal";

import CircularProgress from "@mui/material/CircularProgress";

export default function RecentlyOrdered(props) {
  const [order, setOrder] = useState({});
  const [open, setOpen] = useState(false);

  function openWrapper(or) {
    if (open) {
      setOpen(() => false);
      setOrder({});
    } else {
      setOpen(() => true);
      setOrder(() => or);
    }
  }

  return (
    <>
      {open ? (
        <OrderDetailModal
          {...props}
          open={open}
          onClose={openWrapper}
          order={order}
        />
      ) : null}
      <Row>
        <Col>
          <RecentlyOrderedTable
            {...props}
            openWrapper={openWrapper}
            order={order}
          />
        </Col>
      </Row>
    </>
  );
}

function TableHeaderFunction(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell aria-label={`Order Id`}>Order Number</TableCell>
        <TableCell aria-label="Patient Name Column">Order Date</TableCell>
        <TableCell aria-label="Patient DOB Column">Ordered By</TableCell>
        <TableCell aria-label="Order Tests">Number of Tests</TableCell>
        <TableCell aria-label="Approved/Pending">Approved/Pending</TableCell>
      </TableRow>
    </TableHead>
  );
}

function TableBodyFunction(props) {
  var { page, rowsPerPage, openWrapper, filterText, data, pending } = props;
  const [open, setOpen] = useState(false);

  return (
    <TableBody>
      {data.length > 0 ? (
        data.map((ro, index) => {
          var pending =
            ro.order_tests.filter(
              (e) => e.status.description == "Pending Authorization"
            ).length > 0;

          return (
            <Fragment key={makeid(10)}>
              <TableRow
                role="checkbox"
                onDoubleClick={() => openWrapper(ro)}
                hover
                style={{ cursor: "pointer" }}
                key={`recently_ordered_table_row_` + makeid(10)}
              >
                <TableCell aria-label={`Order Number: ${ro.order_number}`}>
                  {ro.order_number}
                </TableCell>
                <TableCell aria-label={`Ordered Date: ${ro.order_date}`}>
                  {moment(ro.order_date).format("MM-DD-yyyy h:mm a")}
                </TableCell>
                <TableCell
                  aria-label={`Authorized User: ${
                    ro.order_created_by.first_name +
                    " " +
                    ro.order_created_by.last_name
                  }`}
                >
                  {ro.order_created_by.first_name +
                    " " +
                    ro.order_created_by.last_name}
                </TableCell>
                <TableCell
                  aria-label={`Number of Tests: ${ro.order_tests.length}`}
                >
                  {ro.order_tests.length}
                </TableCell>
                <TableCell>{pending ? "Pending" : "Authorized"}</TableCell>
              </TableRow>
            </Fragment>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12}>No Records Found</TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

function RecentlyOrderedTable(props) {
  const {
    data,
    pending,
    openWrapper,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    filterText,
    setFilterText,
    totalRecords,
    loading,
  } = props;

  const [selected, setSelected] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.test.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  var methodProps = {
    page,
    rowsPerPage,
    selected,
    setSelected,
    handleClick,
    handleSelectAllClick,
    filterText,
    data,
    pending,
    openWrapper,
  };

  return (
    <>
      <TableContainer>
        <TextField
          id="outlined-required"
          label="Search..."
          className={`table-text-box`}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          variant="standard"
        />
        {loading ? (
          <>
            <CircularProgress />
          </>
        ) : null}
        <Table>
          <TableHeaderFunction {...methodProps} />
          <TableBodyFunction {...methodProps} />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showLastButton
        showFirstButton
      />
    </>
  );
}
