export const PendingSampleData = [
    {
        "client_location_id": 1,
        "client_location": {
            "code": "CPLSW_ACME",
            "name": "ACME MEDICAL GROUP",
            "address": {
                "address1": "123 PROVIDER WAY",
                "address2": "#200",
                "city": "ROUND ROCK",
                "state": "TX",
                "zip": "78665"
            },
            "patient_info_phone": null,
            "scheduling_phone": null,
            "scheduling_phone_ext": "567",
            "e-mail": "acme@gmail.com",
            "active": true,
            "client_location_registries": null,
            "id": 1,
            "created_on": "2023-01-24T20:24:49.345459",
            "updated_on": "2023-01-24T20:24:49.345466"
        },
        "order_by": null,
        "authorized_user_id": "auth0|6389269eaaea2c73b3bb586a",
        "authorized_user": null,
        "physician_id": 1,
        "physician": {
            "display_name": "Dr James Gordon",
            "npi": 930847369,
            "metadata": null,
            "active": true,
            "id": 1,
            "created_on": "2023-01-24T21:59:16.958371",
            "updated_on": "2023-01-24T21:59:16.958374"
        },
        "order_number": "1494afba-1d1a-4410-bcfc-faa5d5653bea",
        "order_date": "2023-01-25T15:15:03.979568",
        "order_tests": [
            {
                "registry": {
                    "code": "DPM",
                    "name": "Diabetes",
                    "frequency_of_test": "0",
                    "registry_columns": null,
                    "active": true,
                    "id": 1,
                    "created_on": "2023-01-24T20:21:36.098013",
                    "updated_on": "2023-01-24T20:21:36.098019"
                },
                "patient": {
                    "empi": "876433568",
                    "first_name": "Ethan",
                    "last_name": "Bennet",
                    "middle_name": "",
                    "dob": "1976-02-17",
                    "address": {
                        "address1": "8394 Main St",
                        "address2": null,
                        "city": "Denver",
                        "state": "CO",
                        "zip": "80023"
                    },
                    "phone": "720-873-9466",
                    "e-mail": "ebennet@gmail.com",
                    "sex": "M",
                    "primary_language": "English",
                    "active": true,
                    "display_name": "Bennet, Ethan",
                    "id": 4,
                    "created_on": "2023-01-24T23:06:43.517902",
                    "updated_on": "2023-01-25T12:18:24.068749"
                },
                "test_result": {
                    "episode": null,
                    "test_id": 3,
                    "test": {
                        "code": "MAlb",
                        "description": "Microalbumin/Creatinine Ratio",
                        "abbreviation": "MAlb",
                        "loinc": "14958-3",
                        "active": true,
                        "id": 3,
                        "created_on": "2023-01-01T00:00:00",
                        "updated_on": "2023-01-01T00:00:00"
                    },
                    "last_value": "4.8",
                    "last_date": "2018-04-17T00:00:00",
                    "criticality": "10",
                    "icd_code": null,
                    "ordered": null,
                    "id": 8,
                    "created_on": "2023-01-25T18:18:24.285307",
                    "updated_on": "2023-01-25T18:18:24.285324"
                },
                "icd_codes": null,
                "order_sent": null,
                "status": {
                    "description": "Pending Authorization",
                    "active": true,
                    "id": 1,
                    "created_on": "2023-01-01T00:00:00",
                    "updated_on": "2023-01-01T00:00:00"
                },
                "id": 2,
                "created_on": "2023-01-25T21:15:20.098237",
                "updated_on": "2023-01-25T21:15:20.098242"
            }
        ],
        "id": 2,
        "created_on": "2023-01-25T21:15:03.923409",
        "updated_on": "2023-01-25T21:15:03.923415"
    },
    {
        "client_location_id": 1,
        "client_location": {
            "code": "CPLSW_ACME",
            "name": "ACME MEDICAL GROUP",
            "address": {
                "address1": "123 PROVIDER WAY",
                "address2": "#200",
                "city": "ROUND ROCK",
                "state": "TX",
                "zip": "78665"
            },
            "patient_info_phone": null,
            "scheduling_phone": null,
            "scheduling_phone_ext": "567",
            "e-mail": "acme@gmail.com",
            "active": true,
            "client_location_registries": null,
            "id": 1,
            "created_on": "2023-01-24T20:24:49.345459",
            "updated_on": "2023-01-24T20:24:49.345466"
        },
        "order_by": null,
        "authorized_user_id": "auth0|6389269eaaea2c73b3bb586a",
        "authorized_user": null,
        "physician_id": 3,
        "physician": {
            "display_name": "Dr. Perry White",
            "npi": 654398965,
            "metadata": null,
            "active": true,
            "id": 3,
            "created_on": "2023-01-25T18:41:53.581987",
            "updated_on": "2023-01-25T18:41:53.58199"
        },
        "order_number": "c137cd6d-5cfd-4b1a-b188-48b829ec32df",
        "order_date": "2023-01-25T15:16:39.434921",
        "order_tests": [
            {
                "registry": {
                    "code": "CKD",
                    "name": "Chronic Kidney Desease",
                    "frequency_of_test": "15552000",
                    "registry_columns": null,
                    "active": true,
                    "id": 2,
                    "created_on": "2023-01-24T20:24:29.526869",
                    "updated_on": "2023-01-24T20:24:29.526873"
                },
                "patient": {
                    "empi": "84646290",
                    "first_name": "Denny",
                    "last_name": "Crane",
                    "middle_name": "",
                    "dob": "1995-07-04",
                    "address": {
                        "address1": "7362 16th St",
                        "address2": null,
                        "city": "Austin",
                        "state": "TX",
                        "zip": null
                    },
                    "phone": "623-847-4422",
                    "e-mail": "dcrane@gmail.com",
                    "sex": "M",
                    "primary_language": "English",
                    "active": true,
                    "display_name": "Crane, Denny",
                    "id": 5,
                    "created_on": "2023-01-25T18:41:53.531869",
                    "updated_on": "2023-01-25T13:15:36.376973"
                },
                "test_result": {
                    "episode": null,
                    "test_id": 3,
                    "test": {
                        "code": "MAlb",
                        "description": "Microalbumin/Creatinine Ratio",
                        "abbreviation": "MAlb",
                        "loinc": "14958-3",
                        "active": true,
                        "id": 3,
                        "created_on": "2023-01-01T00:00:00",
                        "updated_on": "2023-01-01T00:00:00"
                    },
                    "last_value": "1954",
                    "last_date": "2023-01-01T00:00:00",
                    "criticality": "10",
                    "icd_code": null,
                    "ordered": null,
                    "id": 15,
                    "created_on": "2023-01-25T19:15:36.646084",
                    "updated_on": "2023-01-25T19:15:36.646089"
                },
                "icd_codes": null,
                "order_sent": null,
                "status": {
                    "description": "Pending Authorization",
                    "active": true,
                    "id": 1,
                    "created_on": "2023-01-01T00:00:00",
                    "updated_on": "2023-01-01T00:00:00"
                },
                "id": 3,
                "created_on": "2023-01-25T21:16:43.278616",
                "updated_on": "2023-01-25T21:16:43.278621"
            }
        ],
        "id": 3,
        "created_on": "2023-01-25T21:16:39.434914",
        "updated_on": "2023-01-25T21:16:39.434919"
    }
]

export const CompleteSampleData = [
    {
        "client_location_id": 1,
        "client_location": {
            "code": "CPLSW_ACME",
            "name": "ACME MEDICAL GROUP",
            "address": {
                "address1": "123 PROVIDER WAY",
                "address2": "#200",
                "city": "ROUND ROCK",
                "state": "TX",
                "zip": "78665"
            },
            "patient_info_phone": null,
            "scheduling_phone": null,
            "scheduling_phone_ext": "567",
            "e-mail": "acme@gmail.com",
            "active": true,
            "client_location_registries": null,
            "id": 1,
            "created_on": "2023-01-24T20:24:49.345459",
            "updated_on": "2023-01-24T20:24:49.345466"
        },
        "order_by": null,
        "authorized_user_id": "auth0|6389269eaaea2c73b3bb586a",
        "authorized_user": null,
        "physician_id": 2,
        "physician": {
            "display_name": "Dr. Bruce Wayne",
            "npi": 6789765432,
            "metadata": null,
            "active": true,
            "id": 2,
            "created_on": "2023-01-24T23:03:45.427061",
            "updated_on": "2023-01-24T23:03:45.427064"
        },
        "order_number": "0e6621fe-fb71-449b-b5ef-d8cc1a442385",
        "order_date": "2023-01-25T15:05:16.063399",
        "order_tests": [
            {
                "registry": {
                    "code": "DPM",
                    "name": "Diabetes",
                    "frequency_of_test": "0",
                    "registry_columns": null,
                    "active": true,
                    "id": 1,
                    "created_on": "2023-01-24T20:21:36.098013",
                    "updated_on": "2023-01-24T20:21:36.098019"
                },
                "patient": {
                    "empi": "74635598",
                    "first_name": "Autumn",
                    "last_name": "Caldwell",
                    "middle_name": "",
                    "dob": "1994-07-05",
                    "address": {
                        "address1": "1557 Manor Rd",
                        "address2": null,
                        "city": "New York",
                        "state": "NY",
                        "zip": "849376"
                    },
                    "phone": "983-672-9376",
                    "e-mail": "acaldwell@gmail.com",
                    "sex": "F",
                    "primary_language": "English",
                    "active": true,
                    "display_name": "Caldwell, Autumn",
                    "id": 3,
                    "created_on": "2023-01-24T23:05:38.676597",
                    "updated_on": "2023-01-25T14:59:58.252114"
                },
                "test_result": {
                    "episode": null,
                    "test_id": 2,
                    "test": {
                        "code": "LDL",
                        "description": "LDL Cholesterol",
                        "abbreviation": "LDL",
                        "loinc": "2089-1",
                        "active": true,
                        "id": 2,
                        "created_on": "2023-01-01T00:00:00",
                        "updated_on": "2023-01-01T00:00:00"
                    },
                    "last_value": "106",
                    "last_date": "2020-06-08T00:00:00",
                    "criticality": "10",
                    "icd_code": null,
                    "ordered": "2023-01-25T15:05:51.421293",
                    "id": 6,
                    "created_on": "2023-01-25T18:18:14.119281",
                    "updated_on": "2023-01-25T15:05:51.421463"
                },
                "icd_codes": null,
                "order_sent": "2023-01-25T15:05:51.342901",
                "status": {
                    "description": "Authorized",
                    "active": true,
                    "id": 2,
                    "created_on": "2023-01-01T00:00:00",
                    "updated_on": "2023-01-01T00:00:00"
                },
                "id": 1,
                "created_on": "2023-01-25T21:05:42.498938",
                "updated_on": "2023-01-25T15:05:51.343005"
            }
        ],
        "id": 1,
        "created_on": "2023-01-25T21:05:16.03345",
        "updated_on": "2023-01-25T21:05:16.033455"
    },
    {
        "client_location_id": 1,
        "client_location": {
            "code": "CPLSW_ACME",
            "name": "ACME MEDICAL GROUP",
            "address": {
                "address1": "123 PROVIDER WAY",
                "address2": "#200",
                "city": "ROUND ROCK",
                "state": "TX",
                "zip": "78665"
            },
            "patient_info_phone": null,
            "scheduling_phone": null,
            "scheduling_phone_ext": "567",
            "e-mail": "acme@gmail.com",
            "active": true,
            "client_location_registries": null,
            "id": 1,
            "created_on": "2023-01-24T20:24:49.345459",
            "updated_on": "2023-01-24T20:24:49.345466"
        },
        "order_by": null,
        "authorized_user_id": "auth0|6389269eaaea2c73b3bb586a",
        "authorized_user": null,
        "physician_id": 1,
        "physician": {
            "display_name": "Dr James Gordon",
            "npi": 930847369,
            "metadata": null,
            "active": true,
            "id": 1,
            "created_on": "2023-01-24T21:59:16.958371",
            "updated_on": "2023-01-24T21:59:16.958374"
        },
        "order_number": "1494afba-1d1a-4410-bcfc-faa5d5653bea",
        "order_date": "2023-01-25T15:15:03.979568",
        "order_tests": [
            {
                "registry": {
                    "code": "DPM",
                    "name": "Diabetes",
                    "frequency_of_test": "0",
                    "registry_columns": null,
                    "active": true,
                    "id": 1,
                    "created_on": "2023-01-24T20:21:36.098013",
                    "updated_on": "2023-01-24T20:21:36.098019"
                },
                "patient": {
                    "empi": "876433568",
                    "first_name": "Ethan",
                    "last_name": "Bennet",
                    "middle_name": "",
                    "dob": "1976-02-17",
                    "address": {
                        "address1": "8394 Main St",
                        "address2": null,
                        "city": "Denver",
                        "state": "CO",
                        "zip": "80023"
                    },
                    "phone": "720-873-9466",
                    "e-mail": "ebennet@gmail.com",
                    "sex": "M",
                    "primary_language": "English",
                    "active": true,
                    "display_name": "Bennet, Ethan",
                    "id": 4,
                    "created_on": "2023-01-24T23:06:43.517902",
                    "updated_on": "2023-01-25T12:18:24.068749"
                },
                "test_result": {
                    "episode": null,
                    "test_id": 3,
                    "test": {
                        "code": "MAlb",
                        "description": "Microalbumin/Creatinine Ratio",
                        "abbreviation": "MAlb",
                        "loinc": "14958-3",
                        "active": true,
                        "id": 3,
                        "created_on": "2023-01-01T00:00:00",
                        "updated_on": "2023-01-01T00:00:00"
                    },
                    "last_value": "4.8",
                    "last_date": "2018-04-17T00:00:00",
                    "criticality": "10",
                    "icd_code": null,
                    "ordered": null,
                    "id": 8,
                    "created_on": "2023-01-25T18:18:24.285307",
                    "updated_on": "2023-01-25T18:18:24.285324"
                },
                "icd_codes": null,
                "order_sent": null,
                "status": {
                    "description": "Pending Authorization",
                    "active": true,
                    "id": 1,
                    "created_on": "2023-01-01T00:00:00",
                    "updated_on": "2023-01-01T00:00:00"
                },
                "id": 2,
                "created_on": "2023-01-25T21:15:20.098237",
                "updated_on": "2023-01-25T21:15:20.098242"
            }
        ],
        "id": 2,
        "created_on": "2023-01-25T21:15:03.923409",
        "updated_on": "2023-01-25T21:15:03.923415"
    },
    {
        "client_location_id": 1,
        "client_location": {
            "code": "CPLSW_ACME",
            "name": "ACME MEDICAL GROUP",
            "address": {
                "address1": "123 PROVIDER WAY",
                "address2": "#200",
                "city": "ROUND ROCK",
                "state": "TX",
                "zip": "78665"
            },
            "patient_info_phone": null,
            "scheduling_phone": null,
            "scheduling_phone_ext": "567",
            "e-mail": "acme@gmail.com",
            "active": true,
            "client_location_registries": null,
            "id": 1,
            "created_on": "2023-01-24T20:24:49.345459",
            "updated_on": "2023-01-24T20:24:49.345466"
        },
        "order_by": null,
        "authorized_user_id": "auth0|6389269eaaea2c73b3bb586a",
        "authorized_user": null,
        "physician_id": 3,
        "physician": {
            "display_name": "Dr. Perry White",
            "npi": 654398965,
            "metadata": null,
            "active": true,
            "id": 3,
            "created_on": "2023-01-25T18:41:53.581987",
            "updated_on": "2023-01-25T18:41:53.58199"
        },
        "order_number": "c137cd6d-5cfd-4b1a-b188-48b829ec32df",
        "order_date": "2023-01-25T15:16:39.434921",
        "order_tests": [
            {
                "registry": {
                    "code": "CKD",
                    "name": "Chronic Kidney Desease",
                    "frequency_of_test": "15552000",
                    "registry_columns": null,
                    "active": true,
                    "id": 2,
                    "created_on": "2023-01-24T20:24:29.526869",
                    "updated_on": "2023-01-24T20:24:29.526873"
                },
                "patient": {
                    "empi": "84646290",
                    "first_name": "Denny",
                    "last_name": "Crane",
                    "middle_name": "",
                    "dob": "1995-07-04",
                    "address": {
                        "address1": "7362 16th St",
                        "address2": null,
                        "city": "Austin",
                        "state": "TX",
                        "zip": null
                    },
                    "phone": "623-847-4422",
                    "e-mail": "dcrane@gmail.com",
                    "sex": "M",
                    "primary_language": "English",
                    "active": true,
                    "display_name": "Crane, Denny",
                    "id": 5,
                    "created_on": "2023-01-25T18:41:53.531869",
                    "updated_on": "2023-01-25T13:15:36.376973"
                },
                "test_result": {
                    "episode": null,
                    "test_id": 3,
                    "test": {
                        "code": "MAlb",
                        "description": "Microalbumin/Creatinine Ratio",
                        "abbreviation": "MAlb",
                        "loinc": "14958-3",
                        "active": true,
                        "id": 3,
                        "created_on": "2023-01-01T00:00:00",
                        "updated_on": "2023-01-01T00:00:00"
                    },
                    "last_value": "1954",
                    "last_date": "2023-01-01T00:00:00",
                    "criticality": "10",
                    "icd_code": null,
                    "ordered": null,
                    "id": 15,
                    "created_on": "2023-01-25T19:15:36.646084",
                    "updated_on": "2023-01-25T19:15:36.646089"
                },
                "icd_codes": null,
                "order_sent": null,
                "status": {
                    "description": "Pending Authorization",
                    "active": true,
                    "id": 1,
                    "created_on": "2023-01-01T00:00:00",
                    "updated_on": "2023-01-01T00:00:00"
                },
                "id": 3,
                "created_on": "2023-01-25T21:16:43.278616",
                "updated_on": "2023-01-25T21:16:43.278621"
            }
        ],
        "id": 3,
        "created_on": "2023-01-25T21:16:39.434914",
        "updated_on": "2023-01-25T21:16:39.434919"
    }
]