import { BannerErrorWidget, ModalWidgetError } from "../components/widgets";
import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";
import { render } from "react-dom";

import SimpleCrypto from "simple-crypto-js";
// import superagentPromise from "superagent-promise";
// import _superagent from "superagent";

import * as Ladda from "ladda";
import { history } from "../config";
import swal from "sweetalert";

export const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
export const omitSlug = (article) =>
  Object.assign({}, article, { slug: undefined });
export let token = null;

const encode = encodeURIComponent;
// const superagent = superagentPromise(require('superagent'), global.Promise);
const responseBody = (res) => res.body;

const superagent = require("superagent");

export const showErrorById = (error, selector) => {
  render(
    <BannerErrorWidget error={error} key={Date.now()} autoHide={true} />,
    document.getElementById(selector)
  );
};

export const redirectToDashboard = (logout) => {
  logout({ returnTo: window.location.origin });
};

export const redirectToLogin = (logout) => {
  localStorage.clear();

  var cookies = document.cookie;

  if (cookies != undefined && cookies != null && cookies.length > 0) {
    for (var i = 0; i < cookies.split(";").length; ++i) {
      var myCookie = cookies[i];
      var pos = myCookie.indexOf("=");
      var name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  if (logout != undefined) {
    logout({ returnTo: window.location.origin + "/logout" });
  } else {
    window.location = "/login";
  }
};

const getCurrentPath = () => {
  return history.location.pathname;
};

const showServerOfflineError = () => {
  swal("Server is offline", "Please contact support!", "error");
};

const currentPage = () => {
  return history.location.pathname;
};

const pageNotFound = (error) => {
  swal("404", error ? error : "Not Found", "warning");
};

const notLoginPage = () => {
  return currentPage() !== "/login" && currentPage() !== "/";
};

const unauthorized = (error) => {
  if (currentPage() !== "/login") {
    swal({
      title: "403",
      text: error ? error : "Unauthorized",
      icon: "error",
      buttons: ["Cancel", "Go back to login"],
      dangerMode: false,
    }).then((value) => {
      redirectToLogin();
    });
  }
};

// End Logging

export const catchError = (e) => {
  const body = e.response ? e.response.body : "";
  const statusCode = e.response ? e.response.statusCode : "";
  const buttonProgessLaddaElement = document.querySelector(".ladda-button");

  if (buttonProgessLaddaElement) {
    const buttonProgessLadda = Ladda.create(buttonProgessLaddaElement);
    buttonProgessLadda.stop();
  }

  if (statusCode === 400 || statusCode === 401 || statusCode === 403) {
    unauthorized();
  } else if (statusCode === 404) {
    pageNotFound();
  } else if (statusCode === 500) {
    swal(`Server error 500`, `${e}`, "error");
    //showServerOfflineError();
    // refactor - send message to admin
  } else {
    showServerOfflineError();
  }

  return e;
};

export const _getSessionKey = () => {
  return Cookies.get("_session");
};

const _encryptSession = (plainText) => {
  if (_getSessionKey()) {
    return new SimpleCrypto(_getSessionKey()).encrypt(plainText);
  }
  return null;
};

export const _decryptSession = (cipherText) => {
  if (_getSessionKey()) {
    return new SimpleCrypto(_getSessionKey()).decrypt(cipherText);
  }
  return null;
};

export const setSessionData = (response) => {
  var serverTime = new Date(Date.parse(response.expiresAt));
  var minutes = new Date(new Date().getTime() + 10 * 60 * 1000);

  Cookies.set("_session", response.refreshToken, {
    expires: serverTime,
  });
  Cookies.set("_token", _encryptSession(response.accessToken), {
    expires: serverTime,
  });
  Cookies.set("_session_exp", response.expiresAt, {
    expires: serverTime,
  });
};

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getAuth0TokenObject = () => {
  let Auth0Token = localStorage.getItem("Auth0-id-token-storage");
  if (Auth0Token) {
    if (isJsonString(Auth0Token)) {
      return JSON.parse(Auth0Token);
    }
  } else {
    return null;
  }
};

export const setAuth0TokenObject = (idObj) => {
  let Auth0Token = localStorage.setItem(
    "Auth0-id-token-storage",
    JSON.stringify(idObj)
  );
  if (Auth0Token) {
    if (isJsonString(Auth0Token)) {
      return JSON.parse(Auth0Token);
    }
  } else {
    return null;
  }
};

export const getAuth0Token = () => {
  const token = getAuth0TokenObject();
  if (token) {
    return token["accessToken"];
  } else {
    return null;
  }
};

export const tokenPlugin = (e) => {
  token = _decryptSession(Cookies.get("_token"));
  const Auth0AccessToken = getAuth0Token();

  if (token) {
    return `Bearer ${token}`;
  } else if (Auth0AccessToken) {
    return `Bearer ${Auth0AccessToken}`;
  }
  // else {
  //   return <Redirect to={"/login"} />;
  // }
  return "";
};

export const xCorrelationHeader = (e) => {
  var header = Cookies.get("https://imorpheus.com/correlation_id");

  if (header == undefined || header == null) {
    return "";
  } else {
    return header;
  }
};

var iconnectUrl = process.env.REACT_APP_API_URL_ICONNECT;

// ERASE LATER

var id_token = "";

if (getAuth0Token() != undefined) {
  id_token = getAuth0Token()._raw;
}

export const requests = {
  del: (url) =>
    superagent
      .del(`${process.env.REACT_APP_API_URL}${url}`)
      .set({
        Authorization: tokenPlugin(),
        "X-CORRELATION-ID": xCorrelationHeader(),
        id_token: id_token,
      })
      .then(responseBody)
      .catch((e) => {
        catchError(e);
      }),
  get: (url) =>
    superagent
      .get(`${process.env.REACT_APP_API_URL}${url}`)
      .set({
        Authorization: tokenPlugin(),
        "X-CORRELATION-ID": xCorrelationHeader(),
        id_token: id_token,
      })
      .then(responseBody)
      .catch((e) => {
        catchError(e);
      }),
  put: (url, body) =>
    superagent
      .put(`${process.env.REACT_APP_API_URL}${url}`)
      .send(body)
      .set({
        Authorization: tokenPlugin(),
        "X-CORRELATION-ID": xCorrelationHeader(),
        id_token: id_token,
      })
      .then(responseBody)
      .catch((e) => {
        catchError(e);
      }),
  patch: (url, body) =>
    superagent
      .patch(`${process.env.REACT_APP_API_URL}${url}`)
      .send(body)
      .set({
        Authorization: tokenPlugin(),
        "X-CORRELATION-ID": xCorrelationHeader(),
        id_token: id_token,
      })
      .then(responseBody)
      .catch((e) => {
        catchError(e);
      }),
  post: (url, body) =>
    superagent
      .post(`${process.env.REACT_APP_API_URL}${url}`)
      .send(body)
      .set({
        Authorization: tokenPlugin(),
        "X-CORRELATION-ID": xCorrelationHeader(),
        id_token: id_token,
      })
      .then(responseBody)
      .catch((e) => {
        catchError(e);
      }),
};

export const iconnectRequests = {
  del: (url) =>
    superagent
      .del(`${iconnectUrl}${url}`)
      .set({
        Authorization: tokenPlugin(),
        "X-CORRELATION-ID": xCorrelationHeader(),
        id_token: id_token,
      })
      .then(responseBody)
      .catch((e) => {
        catchError(e);
      }),
  get: (url) =>
    superagent
      .get(`${iconnectUrl}${url}`)
      .set({
        Authorization: tokenPlugin(),
        "X-CORRELATION-ID": xCorrelationHeader(),
        id_token: id_token,
      })
      .then(responseBody)
      .catch((e) => {
        catchError(e);
      }),
  getBody: (url, body) =>
    superagent
      .get(`${iconnectUrl}${url}`, body)
      .set({
        Authorization: tokenPlugin(),
        "X-CORRELATION-ID": xCorrelationHeader(),
        id_token: id_token,
      })
      .then(responseBody)
      .catch((e) => {
        catchError(e);
      }),
  put: (url, body) =>
    superagent
      .put(`${iconnectUrl}${url}`, body)
      .set({
        Authorization: tokenPlugin(),
        "X-CORRELATION-ID": xCorrelationHeader(),
        id_token: id_token,
      })
      .then(responseBody)
      .catch((e) => {
        catchError(e);
      }),
  patch: (url, body) =>
    superagent
      .patch(`${iconnectUrl}${url}`, body)
      .set({
        Authorization: tokenPlugin(),
        "X-CORRELATION-ID": xCorrelationHeader(),
        id_token: id_token,
      })
      .then(responseBody)
      .catch((e) => {
        catchError(e);

        return;
      }),
  post: (url, body) =>
    superagent
      .post(`${iconnectUrl}${url}`, body)
      .set({
        Authorization: tokenPlugin(),
        "X-CORRELATION-ID": xCorrelationHeader(),
        id_token: id_token,
      })
      .then(responseBody)
      .catch((e) => {
        catchError(e);
      }),
  jsonpost: (url, body) =>
    superagent
      .post(`${iconnectUrl}${url}`)
      .type("form")
      .send({ result: body })
      .set({
        Authorization: tokenPlugin(),
        "X-CORRELATION-ID": xCorrelationHeader(),
        id_token: id_token,
      })
      .set("accept", "json")
      .then(responseBody)
      .catch((e) => {
        catchError(e);
      }),
};

export * from "./_api_helper";
