import React, { useEffect, useState, useContext, Fragment } from "react";
// import { useAuth0 } from "@auth0/auth0-react";

import { NavListItem } from "./nav/nav_list_item";
import { NavListItemDropdown } from "./nav/nav_list_item_dropdown";

import { Link, Location, useRouteMatch } from "react-router-dom";

import UserContext from "../../context/user_context";
import { IsAdmin } from "../../helpers/helper_methods";

import SonicLogo from "../../img/sonic.png";
import FullLogo from "../../img/temp_logo_1.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartSimple,
  faThLarge,
  faUser,
  faCircleExclamation,
  faList,
  faClipboardCheck,
  faHospitalUser,
} from "@fortawesome/free-solid-svg-icons";

export function Navigation(props) {
  const [activeUrl, setActiveUrl] = useState(null);

  useEffect(() => {
    setActiveUrl(props.location.pathname);
  }, []);

  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  function secondLevelActive(routeName) {
    return props.location.pathname.indexOf(routeName) > -1
      ? "nav nav-second-level collapse in"
      : "nav nav-second-level collapse";
  }

  const currentUser = useContext(UserContext);
  // const { user } = useAuth0();

  var returnNull = true;

  returnNull = !IsAdmin(currentUser);

  var adminOnly =
    currentUser.client_locations != undefined &&
    currentUser.client_locations != null &&
    currentUser.client_locations.length == 0;

  return (
    <nav className="navbar-default navbar-static-side" role="navigation">
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <li className="nav-header">
            <div className="dropdown profile-element center">
              {" "}
              <span>
                <img
                  style={{ maxWidth: "70px" }}
                  alt="image"
                  className="img-circle"
                  src={SonicLogo}
                />
              </span>
              <ul className="dropdown-menu animated fadeInRight m-t-xs hide">
                <li>
                  <a href="/logout"> Logout</a>
                </li>
              </ul>
            </div>
            <div className="logo-element center">
              <img
                src={SonicLogo}
                alt={process.env.REACT_APP_APP_INITIALS}
                style={{ width: `2rem`, height: `2rem` }}
              />
            </div>
          </li>

          {!adminOnly ? (
            <>
              <NavListItem
                id={"nav_route_" + "dashboard"}
                key={"nav_route_" + "dashboard"}
                name={"Dashboard"}
                icon={<FontAwesomeIcon icon={faChartSimple} />}
                routeLink={"/dashboard"}
                isActive={window.location.pathname == "/dashboard"}
              />

              <NavListItem
                id={"nav_route_" + "orders"}
                key={"nav_route_" + "orders"}
                name={"Orders"}
                icon={<FontAwesomeIcon icon={faClipboardCheck} />}
                routeLink={"/orders"}
                isActive={window.location.pathname == "/orders"}
              />

              <NavListItem
                id={"nav_route_" + "pending"}
                key={"nav_route_" + "pending"}
                name={"Pending Authorization"}
                icon={<FontAwesomeIcon icon={faList} />}
                routeLink={"/pending"}
                isActive={window.location.pathname == "/pending"}
              />

              {/*
          <NavListItem
                id={"nav_route_" + "criticality"}
                key={"nav_route_" + "criticality"}
                name={"High Criticality"}
                icon={<FontAwesomeIcon icon={faCircleExclamation} />}
                routeLink={"/criticality"}
                isActive={window.location.pathname == "/criticality"}
              />

  */}

              <NavListItem
                id={"nav_route_" + "regisgry"}
                key={"nav_route_" + "regisgry"}
                name={"Lab Orders"}
                icon={<FontAwesomeIcon icon={faThLarge} />}
                routeLink={"/registries"}
                isActive={window.location.pathname == "/registries"}
              />

              <NavListItem
                id={"nav_route_" + "patients"}
                key={"nav_route_" + "patients"}
                name={"Registry Patients"}
                icon={<FontAwesomeIcon icon={faHospitalUser} />}
                routeLink={"/patients"}
                isActive={window.location.pathname == "/patients"}
              />
            </>
          ) : null}

          <>
            {!returnNull ? (
              <NavListItem
                id={"nav_route_" + "admin"}
                key={"nav_route_" + "admin"}
                name={"Admin"}
                icon={<FontAwesomeIcon icon={faUser} />}
                routeLink={"/admin"}
                isActive={window.location.pathname == "/admin"}
                permissions={[`Admin`]}
              />
            ) : null}
          </>
        </ul>
      </div>
    </nav>
  );
}
