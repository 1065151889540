import React, { useState, useEffect } from "react";

import {
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Button,
  TextField,
  FormLabel,
} from "@mui/material";
import { grey, pink } from "@mui/material/colors";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function getVal(val, displayParam) {
  var splitParam = displayParam.split(".");
  var nextVal = Object.assign({}, val);

  splitParam.map((sp) => {
    nextVal = nextVal[sp];
  });

  return nextVal;
}

function arrayFilter(item, filterValue) {
  var returnValue = false;

  for (const property in item) {
    if (typeof item[property] == "string") {
      if (item[property].toLowerCase().includes(filterValue.toLowerCase())) {
        returnValue = true;
      }
    } else if (typeof item[property] == "number") {
      if (item[property] == filterValue) {
        returnValue = true;
      }
    } else if (
      typeof item[property] == "object" ||
      typeof item[property] == "array"
    ) {
      if (arrayFilter(item[property], filterValue)) {
        returnValue = true;
      }
    }
  }
  return returnValue;
}

export default function TransferList(props) {
  const { dataList, displayParam, rightList, isEdit, checkedProp } = props;

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(Object.assign([], dataList));
  const [right, setRight] = React.useState(Object.assign([], checkedProp));

  const [leftFilterText, setLeftFilterText] = React.useState("");
  const [rightFilterText, setRightFilterText] = React.useState("");

  useEffect(() => {
    setLeft(Object.assign([], dataList));
    setRight(Object.assign([], checkedProp));
  }, [dataList]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    rightList(right.concat(leftChecked));

    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));

    setRight(not(right, rightChecked));
    rightList(not(right, rightChecked));

    setChecked(not(checked, rightChecked));
  };

  function customList(title, ogItems, filterText, setFilterText) {
    var items = Object.assign([], ogItems);

    items = items.filter((e) => {
      return arrayFilter(e, filterText);
    });

    return (
      <Card>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Grid>
              <Grid item lg={12} style={{ marginBottom: `1rem` }}>
                <TextField
                  label="Filter"
                  variant="standard"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                />
              </Grid>
              <Grid item lg={12}>
                <Checkbox
                  onClick={handleToggleAll(items)}
                  style={{ paddingBottom: `2rem` }}
                  checked={
                    numberOfChecked(items) === items.length &&
                    items.length !== 0
                  }
                  indeterminate={
                    numberOfChecked(items) !== items.length &&
                    numberOfChecked(items) !== 0
                  }
                  disabled={items.length === 0}
                  inputProps={{
                    "aria-label": "all items selected",
                  }}
                  sx={{
                    "&.Mui-checked": {
                      color: pink[`A400`],
                    },
                    "&.MuiCheckbox-indeterminate": {
                      color: grey[700],
                    },
                  }}
                />
                <FormLabel>
                  <span>
                    <strong>{title}</strong>
                    <br />
                    {`${numberOfChecked(items)}/${items.length} selected`}
                    <br />
                    {ogItems.length == items.legnth ? (
                      <span>
                        {`${numberOfChecked(items)}/${items.length} selected`}
                      </span>
                    ) : null}
                  </span>
                </FormLabel>
              </Grid>
            </Grid>
          }
        />
        <Divider />
        <List
          sx={{
            height: 230,
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
          style={{
            minHeight: `15rem`,
            maxHeight: `15rem`,
            overflowY: "scroll",
          }}
        >
          {items
            .sort((a, b) => {
              if (getVal(a, displayParam) < getVal(b, displayParam)) {
                return -1;
              } else if (getVal(a, displayParam) > getVal(b, displayParam)) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((value) => {
              const labelId = `transfer-list-all-item-${value}-label`;
              return (
                <ListItem
                  key={value.id}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                      sx={{
                        "&.Mui-checked": {
                          color: pink[`A400`],
                        },
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={getVal(value, displayParam)}
                  />
                </ListItem>
              );
            })}
        </List>
      </Card>
    );
  }
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        {customList("Choices", left, leftFilterText, setLeftFilterText)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        {customList("Chosen", right, rightFilterText, setRightFilterText)}
      </Grid>
    </Grid>
  );
}
