import React from "react";
import { IboxTools } from "./ibox_tools";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

export class Ibox extends React.Component {
  render() {
    const {
      children,
      configOptionsList,
      id,
      iboxTools,
      iboxContentStyle,
      subTitle,
      title,
      enableTitleBar,
      footer,
      overflowScrollY,
      titleButton,
    } = this.props;
    return (
      <div className="ibox" id={id}>
        {enableTitleBar && (
          <div className="ibox-title">
            <h5>
              {title} <small>{subTitle}</small>
            </h5>
            {titleButton && (
              <Button
                style={{ float: `right`, marginTop: `-.6em` }}
                onClick={titleButton.func}
                variant="contained"
                color={titleButton.color}
              >
                {titleButton.title}
              </Button>
            )}
            {iboxTools && (
              <IboxTools
                showCollapseOption={true}
                showCloseOption={true}
                showConfigOption={true}
                configOptionsList={configOptionsList}
              />
            )}
          </div>
        )}
        <div
          className={`ibox-content ${
            overflowScrollY ? "overflow-scroll-y" : ""
          }`}
          style={iboxContentStyle}
        >
          {children}
        </div>
        {footer && <div className="ibox-footer">{footer}</div>}
      </div>
    );
  }
}

Ibox.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  iboxTools: PropTypes.bool,
  configOptionsList: PropTypes.array,
  enableTitleBar: PropTypes.bool,
  overflowScrollY: PropTypes.bool,
  titleButton: PropTypes.object,
};

Ibox.defaultProps = {
  configOptionsList: [],
  enableTitleBar: false,
  iboxTools: false,
  overflowScrollY: false,
};
