import React, { useState, useContext } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";

import pink from "@mui/material/colors/pink";

import { makeid } from "../../helpers/helper_methods";

import { Col, Row } from "react-bootstrap";
import api from "../../service/api";
import moment from "moment";
import { Log } from "../../helpers/logs";
import Swal from "sweetalert2";

import CircularProgress from "@mui/material/CircularProgress";

import { IsAuthorize } from "../../helpers/helper_methods";

import UserContext from "../../context/user_context";

export default function PendingOrdersTable(props) {
  const { orders, handleClose, getOrders, isModal } = props;
  const [ordersToAuth, setOrdersToAuth] = useState([]);

  const currentUser = useContext(UserContext);

  function approveOrders() {
    Log("Approving Orders", ordersToAuth);

    Swal.fire({
      title: `Confirm test authorization`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      input: IsAuthorize(currentUser) ? "checkbox" : "",
      inputPlaceholder:
        "I acknowledge and agree that each lab test ordered will be documented in the applicable patientmedical record",
      inputAttributes: { cursor: "pointer" },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value) {
          api.IConnectApi.approveOrders(ordersToAuth).then((response) => {
            Log("Orders Authorized");

            if (handleClose != undefined) {
              handleClose();
            }

            Swal.fire({
              title: `Orders Authorized`,
              icon: "success",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            }).then((result) => {
              setOrdersToAuth([]);
              getOrders();
            });
          });
        } else {
          Swal.fire({
            title: `Failed to check acknowledgment`,
            icon: "warning",
            timer: "50000",
          });
        }
      }
    });
  }

  function getOrderTests() {
    if (Array.isArray(orders)) {
      var returnArray = [];

      orders.map((or) => {
        returnArray = returnArray.concat(or.order_tests);
      });

      return returnArray;
    } else {
      return orders.order_tests;
    }
  }

  var isAuthUser = false;

  if (currentUser && currentUser.roles != undefined) {
    currentUser.roles.map((role) => {
      if (role.name == "Authorize") {
        isAuthUser = true;
      }
    });
  }

  var rOTProps = {
    ordersToAuth,
    setOrdersToAuth,
    recentlyOrdered: isModal != undefined ? getOrderTests() : orders,
    isAuthUser,
  };

  return (
    <>
      <Row>
        <Col>
          <RecentlyOrderedTable {...props} {...rOTProps} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="contained"
            color={`primary`}
            disabled={ordersToAuth.length == 0}
            onClick={approveOrders}
            className={`mui-btn`}
            style={!isAuthUser ? { display: "none" } : {}}
          >
            Approve Orders
          </Button>
        </Col>
      </Row>
    </>
  );
}

function TableHeaderFunction(props) {
  var {
    recentlyOrdered,
    selected,
    recentlyOrdered,
    handleSelectAllClick,
    selected,
    isAuthUser,
  } = props;

  var disableCheck = true;

  recentlyOrdered.map((ro) => {
    if (ro.status.description == "Pending Authorization") {
      disableCheck = false;
    }
  });

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={!isAuthUser ? { display: "none" } : {}}
        >
          <FormControlLabel
            aria-label="Check All"
            control={
              <Checkbox
                indeterminate={
                  selected.length > 0 &&
                  selected.length < recentlyOrdered.length
                }
                checked={
                  recentlyOrdered.length > 0 &&
                  selected.length === recentlyOrdered.length
                }
                disabled={disableCheck}
                onClick={handleSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
                sx={{
                  "&.Mui-checked": {
                    color: pink[`A400`],
                  },
                  "&.MuiCheckbox-indeterminate": {
                    color: pink[`A400`],
                  },
                }}
              />
            }
          />
        </TableCell>
        <TableCell aria-label={`Order Id`}>Order Id</TableCell>
        <TableCell aria-label="Patient Name Column">Patient Name</TableCell>
        <TableCell aria-label="Patient DOB Column">Patient DOB</TableCell>
        <TableCell
          style={{ display: "none" }}
          aria-label="Orderable Code Column"
        >
          Orderable Code
        </TableCell>
        <TableCell aria-label="Orderable Code Column">Orderable Code</TableCell>
        <TableCell aria-label="Regsitry Value Column">Registry</TableCell>
        <TableCell aria-label="Test Status">Status</TableCell>
      </TableRow>
    </TableHead>
  );
}

function TableBodyFunction(props) {
  var {
    selected,
    handleClick,
    recentlyOrdered,
    isAuthUser,
    isLocal,
    localPage,
    localRowsPerPage,
  } = props;

  const currentUser = useContext(UserContext);

  var filteredOrders = Object.assign([], recentlyOrdered);

  if (isLocal) {
    filteredOrders = filteredOrders.slice(
      localPage * localRowsPerPage,
      localPage * localRowsPerPage + localRowsPerPage
    );
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <TableBody>
      {filteredOrders.length > 0 ? (
        filteredOrders.map((ro, index) => {
          const isItemSelected = isSelected(ro.id);

          const isAuthorized = ro.status.description != `Pending Authorization`;

          return (
            <TableRow
              role="checkbox"
              onClick={(event) =>
                IsAuthorize(currentUser) ? handleClick(event, ro.id) : null
              }
              hover={false}
              style={false ? { cursor: "pointer" } : {}}
              key={`recently_ordered_table_row_` + makeid(10)}
            >
              <TableCell
                key={makeid(15)}
                padding={`checkbox`}
                style={!isAuthUser ? { display: "none" } : {}}
              >
                <FormControlLabel
                  aria-label="Check All"
                  control={
                    <Checkbox
                      checked={isItemSelected}
                      disabled={isAuthorized}
                      inputProps={{
                        "aria-labelledby": "Select Row",
                      }}
                      sx={{
                        "&.Mui-checked": {
                          color: pink[`A400`],
                        },
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell aria-label={`Patient Name: ${ro.id}`}>
                {ro.id}
              </TableCell>
              <TableCell
                aria-label={`Patient Name: ${ro.patient.display_name}`}
              >
                {ro.patient.display_name}
              </TableCell>
              <TableCell aria-label={`Patient DOB: ${ro.patient.dob}`}>
                {moment(ro.patient.dob).format("MM-DD-yyyy")}
              </TableCell>
              {/*
                                        <TableCell aria-label={`Orderable Code: ${ro.test.test.code}`}>
                                            *orderable code*
                                        </TableCell>
                                    */}
              <TableCell
                aria-label={`Test Code: ${ro.orderable_test.test.code}`}
              >
                {ro.orderable_test.test.code}
              </TableCell>
              <TableCell aria-label={`Last Value: ${ro.registry.code}`}>
                {ro.registry.code}
              </TableCell>
              {
                <TableCell aria-label={`Status: ${ro.status.description}`}>
                  {ro.status.description}
                </TableCell>
              }
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12}>No Records Found</TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

function RecentlyOrderedTable(props) {
  const {
    recentlyOrdered,
    data,
    pending,
    ordersToAuth,
    setOrdersToAuth,
    totalRecords,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    filterText,
    setFilterText,
    loading,
    isAuthUser,
    isModal,
  } = props;

  var isLocal = rowsPerPage == undefined;

  const [localPage, setlocalPage] = useState(0);
  const [localRowsPerPage, setlocalRowsPerPage] = useState(5);
  const [localTotalRecords, setlocalTotalRecords] = useState(
    recentlyOrdered.length
  );
  const [selected, setSelected] = useState([]);

  const [filteredRecords, setFilteredRecrods] = useState(recentlyOrdered);

  const handleChangePage = (event, newPage) => {
    if (isLocal) {
      setlocalPage(newPage);

      return;
    }

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (isLocal) {
      setlocalRowsPerPage(parseInt(event.target.value, 10));
      setlocalPage(0);

      return;
    }

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, name) => {
    const selectedIndex = ordersToAuth.indexOf(name);
    let newSelected = Object.assign([], ordersToAuth);

    if (event.target.checked) {
      newSelected.push(name);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setOrdersToAuth(() => newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = recentlyOrdered.map((ro) => {
        if (ro.status.description == `Pending Authorization`) {
          return ro.id;
        }
      });

      setOrdersToAuth(newSelected);
      return;
    }
    setOrdersToAuth([]);
  };

  var methodProps = {
    page,
    rowsPerPage,
    recentlyOrdered,
    selected: ordersToAuth,
    setSelected,
    handleClick,
    handleSelectAllClick,
    filterText,
    data,
    pending,
    ordersToAuth,
    setOrdersToAuth,
    isAuthUser,
    isModal,
    isLocal,
    localPage,
    localRowsPerPage,
  };

  return (
    <>
      <TableContainer>
        <TextField
          id="outlined-required"
          label="Search..."
          className={`table-text-box`}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          variant="standard"
          style={isModal != undefined ? { display: "none" } : {}}
        />
        {loading ? (
          <>
            <CircularProgress />
          </>
        ) : null}
        <Table>
          <TableHeaderFunction {...methodProps} />
          <TableBodyFunction {...methodProps} />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={isLocal ? localTotalRecords : totalRecords}
        rowsPerPage={isLocal ? localRowsPerPage : rowsPerPage}
        page={isLocal ? localPage : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showLastButton
        showFirstButton
      />
    </>
  );
}
