import api from "../service/api";
import { ErrorLog } from "./logs";

export const makeid = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export function arrayFilter(item, filterValue) {
  var returnValue = false;
  for (const property in item) {
    if (
      typeof item[property] == "function" ||
      typeof item[property] == "symbol"
    ) {
      return;
    }

    if (typeof item[property] == "string") {
      if (item[property].toLowerCase().includes(filterValue.toLowerCase())) {
        returnValue = true;
      }
    } else if (typeof item[property] == "number") {
      if (item[property] == filterValue) {
        returnValue = true;
      }
    } else if (
      typeof item[property] == "object" ||
      typeof item[property] == "array"
    ) {
      if (arrayFilter(item[property], filterValue)) {
        returnValue = true;
      }
    }
  }
  return returnValue;
}

export function handleMakeCartObject(crt) {
  var returnValue = [];

  crt.map((c) => {
    if (c.id == undefined) {
      returnValue.push({
        patient_id: c.patient.id,
        orderable_test_id: c.test.id,
        client_location_id: c.location.id,
        registry_id: c.registry.id,
        icd_codes: c.icd_codes,
      });
    } else {
      returnValue.push({
        patient_id: c.patient_id,
        orderable_test_id: c.orderable_test_id,
        client_location_id: c.client_location_id,
        registry_id: c.registry_id,
        icd_codes: c.icd_codes,
      });
    }
  });

  return returnValue;
}

export function IsAdmin(currentUser) {
  var returnValue = false;

  if (currentUser && currentUser.roles != undefined) {
    currentUser.roles.map((role) => {
      if (role.name == "Admin") {
        returnValue = true;
      }
    });
  }

  return returnValue;
}

export function IsAuthorize(currentUser) {
  var returnValue = false;

  if (currentUser && currentUser.roles != undefined) {
    currentUser.roles.map((role) => {
      if (role.name == "Authorize") {
        returnValue = true;
      }
    });
  }

  return returnValue;
}

export function IsOrder(currentUser) {
  var returnValue = false;

  if (currentUser && currentUser.roles != undefined) {
    currentUser.roles.map((role) => {
      if (role.name == "Order") {
        returnValue = true;
      }
    });
  }

  return returnValue;
}
