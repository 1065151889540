import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "react-bootstrap";

import ButtonCell from "../common/button_cell";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";

import TableSortLabel from "@mui/material/TableSortLabel";

import TextField from "@mui/material/TextField";

import CircularProgress from "@mui/material/CircularProgress";

import { Checkbox, Tooltip } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  makeid,
  IsAdmin,
  IsOrder,
  IsAuthorize,
} from "../../helpers/helper_methods";
import moment from "moment/moment";

import pink from "@mui/material/colors/pink";

import { arrayFilter } from "../../helpers/helper_methods";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

import UserContext from "../../context/user_context";

/*
    RegistryTable Component

    Renders the registry table based on patientData input and registry_columns

    Author: William Brown
    Updated By: 
*/

function RegistryTableHeader(props) {
  const {
    fullTable,
    registry_columns,
    handleSelectAllClick,
    order,
    orderBy,
    onRequestSort,
    patientView,
    patientData,
    cart,
  } = props;

  const currentUser = useContext(UserContext);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  function sortDataFilter(column) {
    if (column.registry_test == null) {
      return column.data_field;
    } else {
      return "";
    }
  }

  var checked = true;

  patientData.map((pd) => {
    pd.episode.orderable_tests.map((ot) => {
      if (
        cart.findIndex((x) => x.orderable_test_id == ot.id) == -1 &&
        ot.orderable
      ) {
        checked = false;
      }
    });
  });

  var demographicsLength = registry_columns.filter(
    (x) => x.registry_column_type.name == "demographic"
  ).length;

  if (!patientView) {
    demographicsLength++;
  }

  var observationLength = registry_columns.filter(
    (x) => x.registry_test != null && !x.registry_test.orderable
  ).length;

  var orderablesLength = registry_columns.filter(
    (x) => x.registry_test != null && x.registry_test.orderable
  ).length;

  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan={demographicsLength}>Demographics</TableCell>
        {fullTable ? (
          <>
            <TableCell colSpan={observationLength}>Results</TableCell>
            {!patientView ? (
              <TableCell colSpan={orderablesLength}>Orderables</TableCell>
            ) : null}
          </>
        ) : null}
      </TableRow>
      <TableRow>
        <TableCell
          padding={`checkbox`}
          style={
            patientView == true || !IsOrder(currentUser)
              ? { display: "none" }
              : {}
          }
        >
          <FormControlLabel
            aria-label="Check All"
            control={
              <Checkbox
                checked={checked}
                onChange={handleSelectAllClick}
                sx={{
                  "&.Mui-checked": {
                    color: pink[`A400`],
                  },
                }}
              />
            }
          />
        </TableCell>
        {registry_columns
          .sort((a, b) => {
            return a.order - b.order;
          })
          .map((column) => {
            if (fullTable || (!fullTable && column.registry_test == null)) {
              var className = "";

              if (
                column.registry_test != null &&
                column.registry_test.editable
              ) {
                className = "wide-column";
              } else {
                className = "normal-column";
              }

              if (
                column.registry_test != null &&
                column.registry_test.orderable &&
                patientView != undefined &&
                patientView
              ) {
                return null;
              }

              return (
                <TableCell
                  className={className}
                  key={makeid(15)}
                  sortDirection={orderBy == column.data_field ? order : false}
                >
                  {column.registry_test == null ? (
                    <TableSortLabel
                      active={orderBy === column.data_field}
                      direction={orderBy === column.data_field ? order : "asc"}
                      onClick={createSortHandler(sortDataFilter(column))}
                    >
                      {column.registry_test != null ? (
                        <>
                          <Checkbox
                            indeterminate={false}
                            sx={{
                              "&.Mui-checked": {
                                color: pink[`A400`],
                              },
                            }}
                          />
                          {column.name}
                        </>
                      ) : (
                        column.name
                      )}
                    </TableSortLabel>
                  ) : (
                    <>{column.name}</>
                  )}
                </TableCell>
              );
            }
          })}
      </TableRow>
    </TableHead>
  );
}

function RegistryTableBody(props) {
  const {
    patientData,
    isSelected,
    isRowSelected,
    fullTable,
    setSelectedPatient,
    handleRowCheck,
    registry,
    registry_columns,
    cart,
    handleSetCart,
    location,
    patientView,
    setShowModal,
    highCriticality,
    physician,
    setSelected,
    setDocument,
  } = props;

  const currentUser = useContext(UserContext);

  // Methods to populate the tables cells with dynamic column structure

  function ButtonCellWrapper(rc, rowData, patient) {
    var resultObj = rowData;
    var displayLabel = null;

    if (rowData.test_results.length > 0) {
      rowData.test_results.map((tr) => {
        if (tr.test.code == rc.registry_test.test.code) {
          resultObj = tr;

          if (rc.registry_test.orderable) {
            displayLabel = (
              <>
                <Row className="justify-content-md-center">
                  <Col>
                    <span>{tr.test.code}</span>
                  </Col>
                </Row>
              </>
            );
          } else if (!(tr.last_value == "" || tr.last_date == null)) {
            displayLabel = (
              <>
                <Row className="justify-content-md-center">
                  <Col>
                    <span>{tr.last_value}</span>
                  </Col>
                </Row>
                {tr.last_date != null ? (
                  <Row>
                    <Col>
                      <small>{moment(tr.last_date).format("L")}</small>
                    </Col>
                  </Row>
                ) : null}
              </>
            );
          }
        }
      });
    }

    var selected = false;

    rowData.orderable_tests.map((ot) => {
      cart.map((ci) => {
        if (
          ci.orderable_test_id == ot.id &&
          ot.test.code == rc.registry_test.test.code
        ) {
          selected = true;
        }
      });
    });

    var orderable = false;
    var orderableTest = null;

    rowData.orderable_tests.map((ot) => {
      if (
        ot.test.code == rc.registry_test.test.code &&
        rc.registry_test.orderable
      ) {
        orderable = ot.currently_orderable;
        orderableTest = ot;
        displayLabel = ot.test.code;
      }
    });
    return (
      <ButtonCell
        me={rc.registry_test.orderable ? rc.registry_test : resultObj}
        row={rowData}
        rc={rc}
        label={displayLabel}
        handleSetCart={handleSetCart}
        patient={patient}
        selected={selected}
        orderable={rc.registry_test.orderable && orderable}
        location={location}
        orderableTest={orderableTest}
        registry={registry}
        registryComparitor={{
          highCriticality: highCriticality != undefined,
          location: location.id,
          physician: physician.value,
        }}
        setSelected={setSelected}
        patientView={patientView}
      />
    );
  }

  function populateCell(rc, rowData, pdIndex, index) {
    var isName = rc.name.toLowerCase() == "name";

    if (
      rc.registry_test != null &&
      rc.registry_test.orderable &&
      patientView != undefined &&
      patientView
    ) {
      return null;
    } else if (rc.active) {
      var splitDisplayName = rc.data_field.split(".");

      if (splitDisplayName[0] == "status") {
        return <TableCell key={makeid(10)}>Initial</TableCell>;
      } else if (rc.registry_test != null && fullTable) {
        return (
          <TableCell key={makeid(15)}>
            {ButtonCellWrapper(rc, rowData.episode, rowData.patient)}
          </TableCell>
        );
      } else if (rc.registry_test == null) {
        if (splitDisplayName.length > 1) {
          let next = rowData;
          let document = rowData.episode.document;

          return splitDisplayName.map((dn, index) => {
            if (!(index == splitDisplayName.length - 1)) {
              next = next[dn];
            } else {
              if (isName && patientView != null && patientView) {
                return (
                  <Tooltip
                    key={makeid(15)}
                    title={`Double click for patient details`}
                  >
                    <TableCell
                      style={
                        isName && patientView != null && patientView
                          ? { cursor: `pointer` }
                          : {}
                      }
                      onDoubleClick={() => {
                        if (isName && patientView != null && patientView) {
                          setShowModal(rowData);
                        }
                      }}
                      key={makeid(15)}
                    >
                      {next[dn] == null || next[dn] == " " ? "N/A" : next[dn]}
                      <a
                        style={
                          document != null && document.url != "" && isName
                            ? {}
                            : { display: "none" }
                        }
                        onClick={() => setDocument(document)}
                        className={`clickableIcon`}
                      >
                        <FontAwesomeIcon
                          className={`fa-lg`}
                          icon={faRightFromBracket}
                        />
                      </a>
                    </TableCell>
                  </Tooltip>
                );
              } else {
                return (
                  <TableCell
                    style={
                      isName && patientView != null && patientView
                        ? { cursor: `pointer` }
                        : {}
                    }
                    onDoubleClick={() => {
                      if (isName && patientView != null && patientView) {
                        setShowModal(true);
                      }
                    }}
                    key={makeid(15)}
                  >
                    {next[dn] == null || next[dn] == " "
                      ? "N/A"
                      : `${next[dn]} `}

                    <a
                      style={
                        document != null && document.url != "" && isName
                          ? {}
                          : { display: "none" }
                      }
                      onClick={() => setDocument(document)}
                      className={`clickableIcon`}
                    >
                      <FontAwesomeIcon
                        className={`fa-lg`}
                        icon={faRightFromBracket}
                      />
                    </a>
                  </TableCell>
                );
              }
            }
          });
        } else {
          if (isName) {
            return (
              <Tooltip title={`Double click for patient details`}>
                <TableCell
                  onDoubleClick={() => {
                    if (isName) {
                      setShowModal(true);
                    }
                  }}
                  key={makeid(15)}
                >
                  {rowData[splitDisplayName]}
                </TableCell>
              </Tooltip>
            );
          } else {
            return (
              <TableCell
                onDoubleClick={() => {
                  if (isName) {
                    setShowModal(true);
                  }
                }}
                key={makeid(15)}
              >
                {rowData[splitDisplayName]}
              </TableCell>
            );
          }
        }
      }
    }
  }

  return (
    <TableBody>
      {patientData.length > 0 ? (
        patientData.map((rowData, pdIndex) => {
          const isItemSelected = isSelected(rowData);

          const rowSelected = isRowSelected(rowData);
          return (
            <TableRow
              style={!fullTable ? { cursor: `pointer` } : {}}
              hover={!fullTable}
              key={makeid(15)}
              selected={rowSelected}
              onClick={(e) => {
                if (!fullTable) {
                  setSelectedPatient(rowData);
                }
              }}
            >
              <TableCell
                key={makeid(15)}
                padding={`checkbox`}
                style={
                  patientView == true || !IsOrder(currentUser)
                    ? { display: "none" }
                    : {}
                }
                sx={{
                  "&.Mui-checked": {
                    color: pink[`A400`],
                  },
                }}
              >
                <FormControlLabel
                  aria-label={`Check Row ${pdIndex + 1}`}
                  control={
                    <Checkbox
                      checked={isItemSelected}
                      onChange={(e) =>
                        handleRowCheck(e, rowData.episode.id, rowData)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: pink[`A400`],
                        },
                      }}
                    />
                  }
                />
              </TableCell>
              {registry_columns
                .sort((a, b) => {
                  return a.order - b.order;
                })
                .map((rc, index) => {
                  return populateCell(rc, rowData, pdIndex, index);
                })}
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12}>No Records Found</TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

export function RegistryTable(props) {
  const {
    registry_columns,
    fullTable,
    patientData,
    selectedPatient,
    setSelectedPatient,
    loading,
    registry,
    registries,
    location,
    patientView,
    showModal,
    setShowModal,
    selectedEpisode,
    setSelectedEpisode,
    page,
    setPage,
    order,
    setOrder,
    rowsPerPage,
    setRowsPerPage,
    orderBy,
    setOrderBy,
    totalRecords,
    filterText,
    setFilterText,
    highCriticality,
    cart,
    setCart,
    handleSetCart,
    handleSetRow,
    physician,
    setDocument,
  } = props;

  const [reset, setReset] = useState(false);

  const [selected, setSelected] = useState(
    localStorage.getItem("selected") != undefined
      ? JSON.parse(localStorage.getItem("selected"))
      : []
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //
  //#region Checkbox logic section
  //
  const [checked, setChecked] = React.useState([]);

  // Defaults the checked variable on load based on localstorage
  useEffect(() => {
    var tempChecked = [];

    patientData.map((patient) => {
      tempChecked.push(false);
    });

    setChecked(tempChecked);
  }, []);

  //#endregion

  function handleSelectAllClick(event) {
    if (!event.target.checked) {
      var newCart = [];

      cart.map((ci) => {
        if (
          patientData.findIndex(
            (x) => x.patient.id == ci.patient.id && x.registry.id == registry.id
          ) == -1
        ) {
          newCart.push(ci);
        }
      });

      setCart(newCart);
      localStorage.removeItem(`${registry.id}`);
    } else {
      var abstractCart = Object.assign([], cart);

      patientData.map((rowData, index) => {
        rowData.episode.orderable_tests.map((ot) => {
          if (
            ot.currently_orderable &&
            cart.findIndex((x) => x.testCode == ot.id) == -1
          ) {
            abstractCart.push({
              registry: registry,
              testCode: ot.id,
              patientId: rowData.patient.id,
              patient: rowData.patient,
              test: ot,
              physician: physician,
              location: location,
              icd_codes: [],
            });
          }
        });
      });
      setCart(abstractCart);
    }

    setReset(() => !reset);
  }

  const isSelected = (row) => {
    var allSelected = true;

    row.episode.orderable_tests.map((ot) => {
      if (
        cart.findIndex((x) => x.orderable_test_id == ot.id) == -1 &&
        ot.currently_orderable
      ) {
        allSelected = false;
      }
    });

    return allSelected;
  };

  const isRowSelected = (row) => {
    if (
      selectedPatient.episode != undefined &&
      selectedPatient.episode.id == row.episode.id &&
      !fullTable
    ) {
      return true;
    } else {
      return false;
    }
  };

  function handleRowCheck(event, name, rowData) {
    var rowToHandle = [];

    if (!event.target.checked) {
      localStorage.removeItem(registry.id);
    }

    rowData.episode.orderable_tests.map((ot) => {
      if (ot.currently_orderable) {
        rowToHandle.push({
          physician: rowData.physician,
          patient: rowData.patient,
          orderableTest: ot,
          toAdd: event.target.checked,
        });
      }
    });

    handleSetRow(rowToHandle);
  }

  var childrenProps = {
    patientData,
    selected,
    isSelected,
    isRowSelected,
    setSelected,
    fullTable,
    setSelectedPatient,
    rowsPerPage,
    page: page,
    handleRowCheck,
    registry,
    registries,
    registry_columns,
    cart,
    handleSetCart,
    handleSelectAllClick,
    order,
    orderBy,
    onRequestSort: handleRequestSort,
    filterText,
    setFilterText,
    arrayFilter,
    location,
    patientView,
    showModal,
    setShowModal,
    selectedEpisode,
    setSelectedEpisode,
    physician,
    highCriticality,
    setDocument,
  };

  return (
    <>
      <TableContainer>
        <TextField
          id="outlined-required"
          label="Search..."
          className={`table-text-box`}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          variant="standard"
        />
        {loading ? (
          <>
            <CircularProgress />
          </>
        ) : null}
        <Table aria-label={`Registry Table`}>
          <RegistryTableHeader {...childrenProps} />
          <RegistryTableBody {...childrenProps} />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
      />
    </>
  );
}

RegistryTable.propTypes = {
  registries: PropTypes.array.isRequired,
  patientData: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  fullTable: PropTypes.bool.isRequired,
  registry_columns: PropTypes.array.isRequired,
  selectedPatient: PropTypes.object,
  setSelectedPatient: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  registry: PropTypes.object.isRequired,
};

RegistryTable.defaultProps = {
  registries: [],
  activeTab: "",
  patientData: [],
  columns: [],
  fullTable: true,
  loading: false,
};
