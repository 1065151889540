import Cookies from "js-cookie";
import {
  redirectToLogin,
  requests,
  setSessionData,
  iconnectRequests,
} from "./_api_helper";
import { Log, DebugLog, ErrorLog } from "../helpers/logs";

const superagent = require("superagent");

var icdURL = process.env.REACT_APP_ICD_URL;

const Auth = {
  current: () => requests.get("/users/current_user"),
  login: (username, password) =>
    requests
      .post("/users/authenticate", {
        Username: username,
        Password: password,
      })
      .then((response) => {
        if (response && response.accessToken) {
          setSessionData(response);
          FrontendLogs.createLog(
            "200",
            "/users/authenticate",
            "Logged in",
            "API Auth Login"
          );
        }
        return response;
      }),
  refreshToken: () =>
    requests
      .post("/users/refresh", {
        refreshToken: Cookies.get("_session"),
      })
      .then((response) => {
        if (response && response.accessToken) {
          setSessionData(response);
          FrontendLogs.createLog(
            "200",
            "/users/refresh",
            "Refreshed Token ",
            "API Auth Refresh"
          );
        }
        return response;
      }),
  register: (username, email, password) =>
    requests
      .post("/users", { user: { username, email, password } })
      .then((response) => {
        if (response) {
          FrontendLogs.createLog(
            "200",
            "/users",
            `Created User ${response.id} `,
            "API Auth Create User"
          );
        }
        return response;
      }),
  save: (user) =>
    requests.put("/user", { user }).then((response) => {
      if (response) {
        FrontendLogs.createLog(
          "200",
          "/users",
          `Updated User ${user.id} `,
          "API Auth Update User"
        );
      }
      return response;
    }),
};

const FrontendLogs = {
  get: (id) => requests.get(`/frontend-logs/${id}`),
  getAll: () => requests.get("/frontend-logs"),
  getAllForUser: (user_id) => requests.get(`/frontend-logs/${user_id}/user`),
  createLog: (statusCode, endpoint, errorMessage, component) =>
    requests.post("/frontend-logs", {
      StatusCode: statusCode,
      Endpoint: endpoint,
      ErrorMessage: errorMessage,
      Component: component,
    }),
  loginLog: () => iconnectRequests.post(`/v1/user/login`),
};

const Roles = {
  getAll: () => requests.get("/role"),
  updateRoles: (id, roles) =>
    iconnectRequests.patch(`/v1/role/user/${id}`, roles),
};

const UserRoles = {
  add: (user_id, role_id, role_name) =>
    requests
      .post("/user-roles", {
        UserId: user_id,
        RoleId: role_id,
      })
      .then((response) => {
        if (response) {
          FrontendLogs.createLog(
            "200",
            "/user-roles",
            `Added Role ${role_name} to User ${user_id} `,
            "API UserRoles Add"
          );
        }
        return response;
      }),
  destroy: (user_id, user_role_id, role_name) =>
    requests.del(`/user-roles/${user_role_id}`).then((response) => {
      if (response) {
        FrontendLogs.createLog(
          "200",
          "/user-roles",
          `Deleted Role ${role_name} to User ${user_id} `,
          "API UserRoles Delete"
        );
      }
      return response;
    }),
};

const Status = {
  get: () => superagent.get(`${process.env.REACT_APP_API_BASE_URL}/health`),
  getVersion: () =>
    superagent.get(`${process.env.REACT_APP_API_BASE_URL}/version`),
  getEnvironment: () =>
    superagent.get(`${process.env.REACT_APP_API_BASE_URL}/environment`),
};

const ICDLookup = {
  checkCodes: (codes) =>
    iconnectRequests.post(`/v1/icd-10/validate`, {
      codes: codes.replace(/\s/g, "").toUpperCase().split(","),
    }),
};

const UserSessions = {
  getCart: () => {
    Log("cart empty");
  },

  getAll: (user_id) => requests.get(`/user-sessions/${user_id}`),
  destroy: (user_session_id) =>
    requests.del(`/user-sessions/${user_session_id}`).then((response) => {
      FrontendLogs.createLog(
        "200",
        "/user-sessions",
        `Deleted user session - ${user_session_id}`,
        "API UserSessions destroy"
      );
      return response;
    }),
  logout: () => requests.del(`/user-sessions/logout`),
};

const Users = {
  getAll: () => requests.get("/user/all"),
  createUser: (user) => requests.post("/user", user),
  getUser: (id) => iconnectRequests.get(`/v1/user/${id}`),
  editUser: (user) => iconnectRequests.patch(`/v1/user/${user.id}`, user),
  toggleUser: (userId, flag) =>
    iconnectRequests.patch(`/v1/user/${userId}/active/${flag}`),
};

const UserLocations = {
  setUserLocations: (id, locations) =>
    iconnectRequests.patch(`/v1/user/${id}/client-locations`, locations),
};

const UserPrefernces = {
  updateUserPreferences: (userPref) =>
    iconnectRequests.post(`/v1/user/user-preference`, userPref),
};

const Organizations = {
  getAll: () => iconnectRequests.get(`/v1/organization`),
};

const Cart = {
  setCart: (cart) => iconnectRequests.post(`/v1/user-cart`, cart),
  addAllOrderableTests: (registryId, cLId, critical) =>
    iconnectRequests.post(
      `/v1/user-cart/registry/${registryId}/all?client_location_id=${cLId}&critical=${critical}`
    ),
  getCart: () =>
    iconnectRequests.get(`/v1/user`).then((response) => {
      return response.user.user_cart;
    }),
};

const Documents = {
  getDocument: (documentId) => iconnectRequests.get(`/v1/shair/${documentId}`),
};

const IConnectApi = {
  //#region New API Calls - Simple..

  // Order Endpoints
  createOrder: (order) => iconnectRequests.post(`/v1/order`, order),
  getOrders: (body) => iconnectRequests.getBody(`/v1/order`, { ...body }),
  getTests: (body) => iconnectRequests.getBody(`/v1/order/tests`, { ...body }),

  approveOrders: (orders) =>
    iconnectRequests.post(`/v1/order/authorize`, orders),

  // Location Endpoints
  getPhysicians: (clientLocationId) =>
    iconnectRequests.get(`/v1/Physician?clientLocationId=${clientLocationId}`),
  getLocations: () => iconnectRequests.get(`/v1/client-locations`),

  // Episode Endpoints
  getRegestryPatients: (registryId, body) =>
    iconnectRequests.getBody(`/v1/patient/registry/${registryId}`, { ...body }),
  updateTestResultValue: (trId, value) =>
    iconnectRequests.post(`/v1/test-results/${trId}`, value),

  // User Admin Endpoints
  getUsers: () => iconnectRequests.get(`/v1/user`),

  // Episode Modal Options
  optOutEpisode: (id, body) =>
    iconnectRequests.put(`/v1/patient/${id}/opt-out`, body),
  togglePatientDeceased: (id, isDeceased) =>
    iconnectRequests.put(`/v1/patient/${id}/deceased?deceased=${isDeceased}`),

  //#endregion
  //---access token start---//
  //this is used to test redirect to auth0 login page after logout without checking access token
  StoreToken: (response) => {
    setSessionData(response);
  },
  //---access token end---//

  //#region User Region
  GetCurrentUser: (username) => iconnectRequests.get(`/v1/user`),

  CurrentUserClients: () => iconnectRequests.get(`/v1/User/CurrentUserClients`),

  //#endregion

  GetAllRegistries: () => iconnectRequests.get(`/v1/Registry`),

  //---Client start---//
  GetAllClients: () => iconnectRequests.get(`/v1/Client`),
  GetClient: (id) => iconnectRequests.get(`/v1/Client/${id}`),

  CreateClient: (obj) =>
    iconnectRequests.post("/v1/Client", obj).then((response) => {
      if (response) {
        return response;
      }
    }),
  UpdateClient: (obj) =>
    iconnectRequests.post(`/v1/Client/Update`, obj).then((response) => {
      if (response) {
        return response;
      }
    }),
  DeleteClient: (id) =>
    iconnectRequests.post(`/v1/Client/Delete`, id).then((response) => {
      if (response) {
        return response;
      }
    }),
  //---Client end---//
};

export default {
  Auth,
  FrontendLogs,
  redirectToLogin,
  IConnectApi,
  Documents,
  Roles,
  Status,
  Users,
  UserRoles,
  UserSessions,
  Log,
  ICDLookup,
  Cart,
  Organizations,
  UserLocations,
  UserPrefernces,
};
