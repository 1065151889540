import React, { useEffect, useState, useContext } from "react";

import Api from "../service/api";

import RegistryViews from "../components/registry_components/registry_views";

import UserContext from "../context/user_context";
import { ErrorLog, Log } from "../helpers/logs";

/*
    Registry Component


    Parent view for all of the registry component logic

    Author: William Brown
    Updated By: 
*/
export default function Patients(props) {
  // Variables used to denote wheter the table is being used for high criticality or not
  const { highCriticality } = props;

  const [activeRow, setActiveRow] = useState(-1);
  const [activeTab, setActiveTab] = useState("init");
  const [registries, setRegistries] = useState([]);
  const [registry, setRegistry] = useState({});
  const [columns, setColumns] = useState([]);
  const [patientData, setPatientData] = useState([]);

  // Pagination Variables
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState("stratification.rank");
  const [order, setOrder] = useState("desc");
  const [filterText, setFilterText] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);

  const [textLoading, setTextLoading] = useState(null);
  const [timer, setTimer] = useState(null);
  const [time, setTime] = useState(2000);

  //#region Registry Drop down components section

  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState({});

  const [physicians, setPhysicians] = useState([]);
  const [physician, setPhysician] = useState({
    label: "Any Provider",
    value: null,
  });

  const [selectedEpisode, setSelectedEpisode] = useState({});

  //#endregion

  const [clients, setClients] = useState({});
  const [loading, setLoading] = useState(false);

  var defCartValue = [];

  if (localStorage.getItem("_cart") != undefined) {
    defCartValue = localStorage.getItem("_cart");
  }
  const [cart, setCart] = useState(defCartValue);

  var currentUser = useContext(UserContext);
  const { user_preference } = currentUser;

  useEffect(() => {
    getPatientsByRegistry(registry.id);
  }, [page, order]);

  useEffect(() => {
    getPatientsByRegistry(registry.id, true);
  }, [rowsPerPage]);

  useEffect(() => {
    getRegistries();

    getLocations();
  }, []);

  useEffect(() => {
    if (currentUser != null && currentUser.id != undefined) {
      getClientsByUser();
    }
  }, [currentUser]);

  useEffect(() => {
    if (activeTab == "init") {
      return;
    }

    setActiveRow(-1);

    setColumns(
      () =>
        registries[registries.findIndex((x) => x.code == activeTab)]
          .registry_columns
    );
    setRegistry(
      () => registries[registries.findIndex((x) => x.code == activeTab)]
    );

    getPatientsByRegistry(
      registries[registries.findIndex((x) => x.code == activeTab)].id
    );

    //getColumns(activeTab)
  }, [activeTab, location]);

  useEffect(() => {
    getPhysicians();
  }, [location]);

  useEffect(() => {
    if (registry.id != undefined) {
      getPatientsByRegistry(registry.id, true);
    }
  }, [physician]);

  useEffect(() => {
    if (textLoading == true) {
      if (timer != null) {
        setTimer(() => clearTimeout(timer));

        setTimer(() =>
          setTimeout(() => getPatientsByRegistry(registry.id, true), time)
        );
      } else {
        setTimer(() => clearTimeout(timer));

        setTimer(() =>
          setTimeout(() => getPatientsByRegistry(registry.id, true), time)
        );
      }
    }
  }, [filterText]);

  function filterTextWrapper(e) {
    setTextLoading(() => true);
    setFilterText(() => e);
  }

  function getClientsByUser() {
    Api.IConnectApi.CurrentUserClients()
      .then((response) => {
        var cli = response.clients;

        cli.map((cl) => {
          cl.label = cl.code;
          cl.value = cl.code;

          return;
        });

        setClients(cli);
      })
      .catch((er) => {
        ErrorLog(`Er: `, er);
      });
  }

  function getRegistries() {
    Api.IConnectApi.GetAllRegistries()
      .then((response) => {
        var reg = response.registries;
        reg.map((re) => {
          re.label = re.name;
          re.value = re.code;
        });

        setRegistries(reg);

        if (user_preference.preferred_registry_id != null) {
          var tempReg = reg.find(
            (x) => x.id == user_preference.preferred_registry_id
          );

          setActiveTab(tempReg.code);
        } else {
          setActiveTab(reg[0].code);
        }
      })
      .catch((er) => {
        ErrorLog("Er: ", er);
      });

    return;
  }

  function getPatientsByRegistry(code, resetPage) {
    if (resetPage != undefined) {
      var tempPage = Object.assign(page);

      setPage(0);

      if (tempPage != 0) {
        return;
      }
    }

    var body = {};

    if (highCriticality != undefined && highCriticality) {
      body.critical = true;
    }

    if (location.id == undefined) return null;

    body.client_location_id = location.id;

    if (physician.value != null) {
      body.physician_id = physician.id;
    }

    body.include_order_tests = false;

    body.pagination = true;
    body.sort_header = orderBy;
    body.sort_order = order;
    body.page_number = page + 1;
    body.page_size = rowsPerPage;
    body.filter_text = filterText;

    setLoading(() => true);
    setTextLoading(() => false);
    setTimer(() => clearTimeout(timer));

    Api.IConnectApi.getRegestryPatients(code, body)
      .then((response) => {
        setPatientData(() => response.data);

        if (!body.pagination) {
          setTotalRecords(() => response.data.length);
        }
        {
          setTotalRecords(() => response.total_records);
        }
        setLoading(() => false);
      })
      .catch((er) => {
        setLoading(() => false);
        Log("Er: ", er);
      });

    return;
  }

  function getLocations() {
    Api.IConnectApi.getLocations()
      .then((response) => {
        var locations_response = response.client_locations;

        locations_response.map((lr) => {
          lr.label = lr.name;
          lr.value = lr.id;
        });

        setLocations(locations_response);

        var locationIndex =
          user_preference.preferred_client_id != null
            ? user_preference.preferred_client_id
            : 0;

        if (locationIndex == 0) {
          setLocation(locations_response[locationIndex]);
        } else {
          setLocation(
            locations_response[
              locations_response.findIndex((x) => x.id == locationIndex)
            ]
          );
        }

        getPhysicians(locations_response[0]);
      })
      .catch((er) => {
        Log("Er: ", er);
      });
  }

  function getPhysicians(loc) {
    var dataToSend = location;

    if (loc != undefined) {
      dataToSend = loc;
    }

    if (dataToSend.id == undefined) {
      return null;
    }

    Api.IConnectApi.getPhysicians(dataToSend.id)
      .then((response) => {
        var physicians_response = response.physicians;

        physicians_response.map((pr) => {
          pr.label = pr.display_name;
          pr.value = pr.id;
        });

        physicians_response.unshift({ label: "Any Provider", value: null });

        setPhysicians(physicians_response);
        setPhysician({ label: "Any Provider", value: null });
      })
      .catch((er) => {
        Log("Er: ", er);
      });
  }

  // test Data for the buttons

  const registryProps = {
    activeRow: activeRow,
    registries: registries,
    patientData: patientData,
    columns: columns,
    activeTab: activeTab,
    setActiveTab: setActiveTab,
    clients: clients,
    setClients: setClients,
    cart: cart,
    setCart: setCart,
    loading: textLoading || loading,
    registry: registry,
    locations: locations,
    location,
    setLocation,
    physicians,
    physician,
    setPhysician,
    selectedEpisode,
    setSelectedEpisode,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    filterText,
    setFilterText: filterTextWrapper,
    totalRecords,
    setTotalRecords,
    getPatientsByRegistry,
  };

  return (
    <>
      <RegistryViews {...registryProps} {...props} patientView />
    </>
  );
}
