import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Navigate } from "react-router-dom";

import Api from "../../service/api";

import * as Ladda from "ladda";
import { ValidatorForm } from "react-form-validator-core";
import { InputValidator } from "../common/input_validator";
import { history } from "../../config";
import Cookies from "js-cookie";
import { render } from "react-dom";
import { BannerErrorWidget } from "../widgets/banner_error_widget";
import { ServerStatusIcon } from "../";

import { AccessToken, OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import oktaConfig from "../../oktaConfig"; // refactor

import $ from "jquery";
import swal from "sweetalert";

import Logo from "../../img/iconnect_logo.png";

import { useAuth0 } from "@auth0/auth0-react";

const superagent = require("superagent");

const authClient = new OktaAuth(oktaConfig.oidc);

var moment = require("moment");

export default function Login(props) {
  const [state, setState] = useState({
    apiServerStatus: {},
    online: false,
    statusCount: 2,
    redirectToReferrer: false,
    _username: "",
    _password: "",
  });

  // okta
  const [oktaAuth, setOktaAuth] = useState(authClient);

  var restoreOriginalUri = async (_oktaAuth, originalUri) => {
    props.history.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  var buttonProgessLadda;

  useEffect(() => {
    document.title = "Login - " + process.env.REACT_APP_APP_NAME;
    localStorage.clear();
    Object.keys(Cookies.get()).forEach(function (cookieName) {
      var neededAttributes = {
        // Here you pass the same attributes that were used when the cookie was created
        // and are required when removing the cookie
      };
      Cookies.remove(cookieName, neededAttributes);
    });

    $("body").addClass("white-bg");

    buttonProgessLadda = Ladda.create(document.querySelector(".ladda-button"));

    getVersion();
  }, []);

  //componentWillUnmount() {
  //  $("body").removeClass("white-bg");
  //}

  function getVersion() {
    /*
    Api.Status.get().then(response => {
      if (response.statusCode == 200) {
        //const _this = this;
        setState(prevState => {
          return { ...prevState, apiServerStatus: response.body.apiStack, online: true, statusCount: 0 }
        });
        // _this.setState({ apiServerStatus: response.body.apiStack, online: true, statusCount: 0 });
      }
    }).catch(e => {
      setState(prevState => {
        return { ...prevState, online: false }
      });
      swal(`Server error - Trouble reaching server!`, "Please contact development and report error", 'error')
    })
    */
  }

  function handleSubmit(e) {
    login();
  }

  function addClassesToElement(selector, classList) {
    const element = getElement(selector);
    element.classList.add(arrayToStringList(classList));
  }

  function arrayToStringList(array) {
    return array.map((d) => `"${d}"`).join(",");
  }

  function getElement(selector) {
    return document.querySelector(`.${selector}`);
  }

  function handleOnTextInputChange(e) {
    setState((prevState) => {
      return { ...prevState, [e.target.name]: false };
    });

    // this.setState({ [e.target.name]: e.target.value });
  }

  function slideOutElementBySelector(selector) {
    const element = getElement(selector);
    element.classList.add("animated", "bounceOutLeft");
  }

  function showErrorById(error, selector) {
    render(
      <BannerErrorWidget error={error} key={Date.now()} autoHide={true} />,
      document.getElementById(selector)
    );
  }

  function shakeElementBySelector(selector) {
    const element = getElement(selector);
    element.classList.add("animate__animated", "animate__shakeX");
    setTimeout(function () {
      element.classList.remove("animate__animated", "animate__shakeX");
    }, 3000);
  }

  function login() {
    const { _username, _password } = state;

    resetState(); // just a precaution
    buttonProgessLadda.start();

    Api.Auth.login(_username, _password)
      .then((response) => {
        if (response.accessToken) {
          //const _this = this;
          setTimeout(function () {
            setState((prevState) => {
              return { ...prevState, redirectToReferrer: true };
            });

            //_this.setState({ redirectToReferrer: true });
          }, 800);
          buttonProgessLadda.stop();
          slideOutElementBySelector("loginscreen");
        }
      })
      .catch((e) => {
        shakeElementBySelector("shake-this-element");
      });
  }

  function resetState() {
    setState((prevState) => {
      return { ...prevState, _username: false, _password };
    });

    // this.setState({ _username: "", _password: "" });
  }

  let {
    redirectToReferrer,
    _username,
    _password,
    apiServerStatus,
    online,
    statusCount,
  } = state;
  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect(oktaConfig.oidc);
  };

  // if (redirectToReferrer) window.location = "/dashboard";

  // Auth0 vars

  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <div className="main">
      <div className="animated fadeInDown ">
        <div id="loginscreen" className="shake-this-element loginscreen">
          <div className="middle-box-large">
            <Row>
              <Col lg="12">
                <img width={"700rem"} src={Logo} />
              </Col>
            </Row>
          </div>
          <div>
            <div className="middle-box text-center box">
              <div id="error-banner-dynamic" />
              <h3 style={{ textTransform: "" }}>
                Welcome to {process.env.REACT_APP_APP_NAME}
              </h3>
              {apiServerStatus &&
              (apiServerStatus._oktaEnabled ||
                process.env.REACT_APP_USE_AUTH0 == "true") ? (
                <button
                  className="ladda-button btn btn-primary block full-width m-b mt-5"
                  data-style="expand-left"
                  onClick={
                    process.env.REACT_APP_USE_AUTH0 == "true"
                      ? loginWithRedirect
                      : triggerLogin
                  }
                >
                  {process.env.REACT_APP_USE_AUTH0 == "true" ? (
                    <div className="ladda-label">
                      Click to Login using Auth0
                    </div>
                  ) : (
                    <div className="ladda-label">Click to Login using Okta</div>
                  )}
                </button>
              ) : (
                <ValidatorForm onSubmit={handleSubmit}>
                  <div className="pt-2" align="left">
                    <label>Username:</label>
                    <InputValidator
                      type="text"
                      placeholder=""
                      onChange={handleOnTextInputChange}
                      name="_username"
                      value={_username}
                      validators={["required"]}
                      errorMessages={["Username is required"]}
                    />
                  </div>
                  <div className="pt-1" align="left">
                    <label>Password:</label>
                    <InputValidator
                      type="password"
                      placeholder=""
                      onChange={handleOnTextInputChange}
                      name="_password"
                      value={_password}
                      validators={["required"]}
                      errorMessages={["Password is required"]}
                    />
                  </div>
                  <div>
                    <button
                      className="ladda-button btn btn-primary block full-width m-b mt-4"
                      data-style="expand-left"
                      type="submit"
                    >
                      <div className="ladda-label">Login</div>
                    </button>
                  </div>
                </ValidatorForm>
              )}
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="container">
          <Row>
            <Col>
              <strong>
                {" "}
                &copy;{""}
                {new Date().getFullYear()}
              </strong>{" "}
              Sonic Healthcare USA
            </Col>
            <Col style={{ textAlign: "center" }}>
              <span>Software: {process.env.REACT_APP_APP_VERSION}</span>
            </Col>
            <Col style={{ textAlign: "right", textTransform: "capitalize" }}>
              <span>{process.env.REACT_APP_ENVIRONMENT}</span>
            </Col>
          </Row>
        </div>
      </footer>
    </div>
  );
}

function LoginButton() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    !isAuthenticated && <button onClick={loginWithRedirect}>Log in</button>
  );
}
